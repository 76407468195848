import { apiService } from "./api.service";

export class RequestBuilder {

    response;
    device = "";
    requests = [];

    constructor(_device, _response) {
        this.device = _device;
        this.response = typeof _response !== 'undefined' ? _response : true;
    }

    changeLEDColour = (r, g, b) => {
        this.addRequest("deviceColour", "W", { colour: [r, g, b] });
    }

    /**
     * Adds an object to the request array 
     * @param {string} _property 
     * @param {string} _operation 
     * @param {object} _payload 
     */
    addRequest = (_property, _operation, _payload) => {
        let opCode = 0;

        switch (_operation) {
            case "W":
                opCode = 1;
                break;
            case "R":
            default:
                opCode = 0;
                break;
        }

        let request = { property: _property, operation: opCode, payload: _payload };

        this.requests.push(request);
    }

    sendSingleSection = (properties) => {
        return new Promise((resolve, reject) => {
            let sectionRequests = [];
            let allRequests = [...this.requests];

            allRequests.forEach((req, i) => {
                if (properties.some(prop => req.property === prop)) {
                    sectionRequests.push(allRequests[i]);
                    delete this.requests[i];
                }
            });

            let cleanRequests = this.requests.filter(el => el !== null);
            this.requests = cleanRequests;


            let completeRequest = {
                response: this.response,
                serial: this.device,
                request: [
                    ...sectionRequests
                ]
            }

            this._submitRequest(completeRequest)
                .then(result => {
                    resolve(result);
                })
                .catch(error => {
                    reject(error);
                })
        })
    }

    send = () => {
        return new Promise((resolve, reject) => {
            let completeRequest = {
                response: this.response,
                serial: this.device,
                request: [
                    ...this.requests
                ]
            }

            this._submitRequest(completeRequest)
                .then(result => {
                    resolve(result);
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    this.requests = [];
                })
        })
    }


    _submitRequest = (requests) => {
        console.log("Requests to be sent", requests);
        return new Promise((resolve, reject) => {
            apiService.postData('/device/sendrequest', requests)
                .then(result => {
                    resolve(result);
                })
                .catch(error => {
                    console.error("Sending command error", error);
                    reject(error)
                })
        })
    }
}