import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack'

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

import { WidgetWrapper } from '../../../WidgetWrapper';

export const PeblLocalKey = ({ pebl }) => {

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => { }, [pebl]);

    const copyKey = async () => {
        try {
            await navigator.clipboard.writeText(pebl.device.key);
            enqueueSnackbar("Local key successfully copied to clipboard", { variant: 'success' })
        } catch (error) {
            enqueueSnackbar("Failed to copy local key to clipboard", { variant: 'error' })
        }
    }

    const icons = [
        <IconButton onClick={copyKey}>
            <FileCopyOutlinedIcon />
        </IconButton>
    ];

    return (
        <WidgetWrapper title="Key" displaySave={false} icons={icons}>
            <Typography variant='h5'>{pebl.device.key}</Typography>
        </WidgetWrapper>
    );

}