import React from 'react';
import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
   
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    text: {
        marginRight: theme.spacing(2),
    },
    active: {
        borderColor: theme.palette.primary.main,
        backgroundColor: 'rgba(255, 140, 35, 0.2)',
    }

}))

export const TimespanSelect = ({ timespan, onClick }) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <ButtonGroup disableElevation variant="outlined" color="primary">
                <Button onClick={() => onClick(7)} className={clsx({[classes.active]: timespan === 7 })}>7 Days</Button>
                <Button onClick={() => onClick(14)} className={clsx({[classes.active]: timespan === 14 })}>14 Days</Button>
                <Button onClick={() => onClick(28)} className={clsx({[classes.active]: timespan === 28 })}>28 Days</Button>
            </ButtonGroup>
        </div>
    );

}