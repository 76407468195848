import React, { useState, useEffect } from 'react';
import { CirclePicker } from 'react-color';
import { useSnackbar } from 'notistack';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

import { WidgetWrapper } from '../../../WidgetWrapper';
import { RequestBuilder } from '../../../../../services/request.service';
import useDeviceErrorHandler from '../../../useDeviceErrorHandler';
import { useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	'colourWrapper': {
		display: 'flex',
	},
	'colour': {
		width: '50%',
		height: theme.spacing(4),
		marginBottom: theme.spacing(2) + 'px !important',
		transition: 'background-color 0.25s ease-in-out',
	},
	'colourPicker': {
		marginLeft: 'auto !important',
		marginRight: 'auto !important',
		marginBottom: theme.spacing(2) + 'px !important',
	},
	'slider': {
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		// marginTop: '2px',
		width: '90%',
	},
	'switch': {
		marginLeft: theme.spacing(3),
	},
	'@global': {
		'.circle-picker > span > div > span > div': {
			border: '1px solid ' + theme.palette.greyThree.main,
		},
	},
}));

export const PeblAppearance = ({ pebl }) => {
	const theme = useTheme();
	const classes = useStyles();
	const errorHandler = useDeviceErrorHandler();
	const { enqueueSnackbar } = useSnackbar();
	const [saveEnabled, setSaveEnabled] = useState(false);
	const [blink, setBlink] = useState(pebl.device.blink.state);
	const [colour, setColour] = useState({
		rgb: {
			r: pebl.device.colour[0],
			g: pebl.device.colour[1],
			b: pebl.device.colour[2],
		},
	});
	const rb = new RequestBuilder(pebl.id);

	useEffect(() => {
		setBlink(pebl.device.blink.state);
	}, [pebl]);

	const enableSave = () => {
		if (saveEnabled) {
			return;
		}

		setSaveEnabled(true);
	};

	const handleSectionSave = () => {
		setSaveEnabled(false);
		if (
			pebl.device.colour[0] !== colour.rgb.r ||
			pebl.device.colour[1] !== colour.rgb.g ||
			pebl.device.colour[2] !== colour.rgb.b
		) {
			rb.addRequest('deviceColour', 'W', {
				colour: [colour.rgb.r, colour.rgb.g, colour.rgb.b],
			});
		}
		if (blink !== pebl.device.blink.state) {
			rb.addRequest('deviceBlink', 'W', { state: blink });
		}
		rb.send()
			.then(() => {
				enqueueSnackbar(`Device appearance saved successfully`, {
					variant: 'success',
				});
			})
			.catch((res) => errorHandler(res));
	};

	const handleColourChange = (c, e) => {
		enableSave();
		setColour(c);
	};

	const handleBlinkChange = (e, v) => {
		enableSave();
		setBlink(v);
	};

	const blinkMarks = [
		{ value: 0, label: 'off' },
		{ value: 1, label: 'slow' },
		{ value: 2, label: 'medium' },
		{ value: 3, label: 'fast' },
		{ value: 4, label: 'faster' },
	];

	let deviceColour = pebl.device.colour;

	return (
		<WidgetWrapper
			title='Apperance'
			saveEnabled={saveEnabled}
			onSave={handleSectionSave}>
			<Typography>colour</Typography>
			<div className={classes.colourWrapper}>
				<div
					className={classes.colour}
					style={{
						backgroundColor: `rgb(${colour.rgb.r}, ${colour.rgb.g}, ${colour.rgb.b})`,
						borderRadius: '8px 0px 0px 8px',
						borderLeft: '1px solid ' + theme.palette.greyThree.main,
						borderTop: '1px solid ' + theme.palette.greyThree.main,
						borderBottom: '1px solid ' + theme.palette.greyThree.main,
					}}
				/>
				<div
					className={classes.colour}
					style={{
						backgroundColor: `rgb(${deviceColour[0]}, ${deviceColour[1]}, ${deviceColour[2]})`,
						borderRadius: '0px 8px 8px 0px',
						borderRight: '1px solid ' + theme.palette.greyThree.main,
						borderTop: '1px solid ' + theme.palette.greyThree.main,
						borderBottom: '1px solid ' + theme.palette.greyThree.main,
					}}
				/>
			</div>
			<Typography>change colour</Typography>
			<CirclePicker
				width='85%'
				circleSpacing={14}
				circleSize={32}
				onChangeComplete={handleColourChange}
				className={classes.colourPicker}
				colors={[
					'#FF0000',
					'#FF7F00',
					'#FFFF00',
					'#7FFF7F',
					'#00FF00',
					'#00FF7F',
					'#00FFFF',
					'#007FFF',
					'#0000FF',
					'#7F00FF',
					'#FF00FF',
					'#FF007F',
					'#FFFFFF',
					'#C6C6C6',
					'#919191',
					'#5E5E5E',
					'#303030',
					'#000000',
				]}
			/>
			<Typography>blink</Typography>
			<Slider
				step={1}
				marks={blinkMarks}
				min={0}
				max={4}
				value={blink}
				onChange={handleBlinkChange}
				className={classes.slider}
			/>
		</WidgetWrapper>
	);
};
