import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';

import { RequestBuilder } from '../../../../services/request.service';
import { WidgetGrid } from '../../WidgetGrid';
import { WifiCredentials } from '../../shared/widgets/wifi/WifiCredentials';
import { WifiSignal } from '../../shared/widgets/wifi/WifiSignal';
import { IPAddresses } from '../../shared/widgets/IPAddresses';
import { MACAddresses } from '../../shared/widgets/MACAddresses';

const gridLayout = {
    xl: [
        { i: '1', x: 0, y: 0, w: 3, h: 14 },
        { i: '2', x: 3, y: 0, w: 3, h: 7 },
        { i: '3', x: 6, y: 0, w: 3, h: 7 },
        { i: '4', x: 9, y: 0, w: 3, h: 7 },
    ],
    lg:  [
        { i: '1', x: 0, y: 0, w: 3, h: 14 },
        { i: '2', x: 6, y: 0, w: 3, h: 7 },
        { i: '3', x: 3, y: 0, w: 3, h: 7 },
        { i: '4', x: 3, y: 7, w: 3, h: 7 },
    ],
    md:  [
        { i: '1', x: 0, y: 0, w: 3, h: 14 },
        { i: '2', x: 0, y: 14, w: 3, h: 7 },
        { i: '3', x: 3, y: 0, w: 3, h: 7 },
        { i: '4', x: 3, y: 7, w: 3, h: 7 },
    ],
    sm:  [
        { i: '1', x: 0, y: 0, w: 2, h: 10 },
    ],
    xs:  [
        { i: '1', x: 0, y: 0, w: 3, h: 2 },
    ],
}

export const VyneGatewayNetwork = ({ vyne }) => {
    
    const { enqueueSnackbar } = useSnackbar();
    
    const rb = new RequestBuilder(vyne.id);

    useEffect(() => {}, [vyne])

    const handleWifiSave = (wifiCredentials) => {
        if(wifiCredentials.primaryPass  !== '') {
            rb.addRequest("wifiCredentials", "W", {
                slot: "primary",
                ssid: wifiCredentials.primarySSID,
                password: wifiCredentials.primaryPass
            })
        }
        
        if(wifiCredentials.secondaryPass !== '') {
            rb.addRequest("wifiCredentials", "W", {
                slot: "secondary",
                ssid: wifiCredentials.secondarySSID,
                password: wifiCredentials.secondaryPass
            })
        } 

        rb.sendSingleSection(["wifiCredentials"])
            .then(() => {
                enqueueSnackbar("Wifi credentials successfully changed", { variant: 'success' });
            })
            .catch(errors => {
                if (errors && Array.isArray(errors)) {
                    errors.forEach(error => {
                        enqueueSnackbar(`Error ${error.result.code} - ${error.result.message}`, { variant: 'error' });
                    })
                } else {
                    enqueueSnackbar(errors, { variant: 'error' });
                }
            });
    }

    return (
        <WidgetGrid layouts={gridLayout}>
            <div key='1'>
                <WifiCredentials 
                    wifiCredentials={vyne.wifi.credentials} 
                    eventHandlers={{
                        sectionSave: handleWifiSave
                    }}
                />
            </div>
            <div key='2'>
                <WifiSignal signalLevel={vyne.wifi.status.signal} device={vyne} />
            </div>
            <div key='3'>
                <IPAddresses networkIp={vyne.network.ip} />
            </div>
            <div key='4'>
                <MACAddresses networkMac={vyne.network.mac} />
            </div>
        </WidgetGrid>
    );

}