import React, { useState } from 'react';
import {
	FingotiButton,
	FingotiModalActions,
	FingotiModalContent,
} from '@fingoti/components';
import { useSnackbar } from 'notistack';

import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';

import Validation from '../../../services/validation.service';
import { useForceUpdate } from '../../utils/ForceUpdate';
import { apiService } from '../../../services/api.service';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '60%',
		},
	},
	headerCell: {
		border: 'none',
		textAlign: 'right',
		borderRight: '1px solid ' + theme.palette.greyFour.main,
		width: '10%',
		color: theme.palette.greyTwo.main,
		[theme.breakpoints.down('md')]: {
			paddingLeft: 0,
			paddingRight: theme.spacing(2),
		},
	},
	noBottomBorder: {
		border: 'none',
		textAlign: 'center',
	},
	formControl: {
		width: '100%',
	},
	halfWidth: {
		width: '48%',
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'space-evenly',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
	},
	button: {
		width: '42%',
	},
}));

export const ConfigEdit = ({ setOpen, onSuccess, config }) => {
	const classes = useStyles();
	const forceUpdate = useForceUpdate();
	const { enqueueSnackbar } = useSnackbar();
	const [configName, setConfigName] = useState(config.presetName);
	const [disabled, setDisabled] = useState(config.disabled);
	const [priSSID, setPriSSID] = useState(config.ssid);
	const [priPass, setPriPass] = useState('');
	const [errors, setErrors] = useState([]);

	const submitConfig = () => {
		let body = {
			presetName: configName,
			disabled: disabled,
		};

		if (priPass !== '') {
			body.ssid = priSSID;
			body.password = priPass;
		}

		apiService
			.patchData(`/organisation/presets/${config.id}`, body)
			.then((result) => {
				enqueueSnackbar(result.message, { variant: 'success' });
				onSuccess();
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
			});
	};

	const handleSetErrors = (errors) => {
		setErrors(errors);
		forceUpdate();
	};

	const validate = new Validation(errors, handleSetErrors);

	return (
		<>
			<FingotiModalContent>
				<div className={classes.root}>
					<div className={classes.form}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<FormControl variant='outlined' className={classes.formControl}>
									<TextField
										fullWidth
										required
										id='presetName'
										name='presetName'
										variant='outlined'
										label='preset name'
										value={configName}
										onChange={(e) => setConfigName(e.target.value)}
										onBlur={(e) => validate.checkNotEmpty(e)}
										error={Boolean(errors['presetName'])}
										helperText={
											Boolean(errors['presetName'])
												? errors['presetName'].msg
												: ''
										}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<div className={classes.formControl}>
									<FormControl required variant='outlined'>
										<FormControlLabel
											control={
												<Switch
													checked={!disabled}
													onChange={() => setDisabled(!disabled)}
													name='disabled'
													color='primary'
												/>
											}
											label='enabled'
										/>
									</FormControl>
								</div>
							</Grid>
							<Grid item xs={12} md={6}>
								<FormControl variant='outlined' className={classes.formControl}>
									<TextField
										required
										id='ssid'
										name='ssid'
										variant='outlined'
										label='ssid'
										value={priSSID}
										onChange={(e) => setPriSSID(e.target.value)}
										onBlur={(e) => validate.checkNotEmpty(e)}
										error={Boolean(errors['ssid'])}
										helperText={
											Boolean(errors['ssid']) ? errors['ssid'].msg : ''
										}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={6}>
								<FormControl variant='outlined' className={classes.formControl}>
									<TextField
										type='password'
										id='password'
										name='password'
										variant='outlined'
										label='password'
										value={priPass}
										onChange={(e) => setPriPass(e.target.value)}
										onBlur={(e) =>
											priSSID !== config.ssid && validate.checkNotEmpty(e)
										}
										error={Boolean(errors['password'])}
										helperText={
											Boolean(errors['password']) ? errors['password'].msg : ''
										}
									/>
								</FormControl>
							</Grid>
						</Grid>
					</div>
				</div>
			</FingotiModalContent>
			<FingotiModalActions>
				<div className={classes.buttonGroup}>
					<FingotiButton
						light
						type='submit'
						color='primary'
						disabled={Boolean(
							Object.keys(errors).length > 0 ||
								Boolean(priSSID !== config.ssid && priPass.length < 8)
						)}
						className={classes.button}
						onClick={submitConfig}>
						update preset
					</FingotiButton>
					<FingotiButton
						light
						className={classes.button}
						onClick={() => setOpen(false)}>
						Cancel
					</FingotiButton>
				</div>
			</FingotiModalActions>
		</>
	);
};
