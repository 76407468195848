import React, { useState, useEffect } from 'react';
import {
	FingotiHeader,
	FingotiLoading,
	FingotiButton,
	FingotiModal,
} from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { DeviceIcon } from '../../../icons/FingotIcons';
import { DevicesView } from '../DevicesView';
import { DeviceList } from '../DeviceList';
import { DeviceDetails } from '../DeviceDetails';
import { ClaimGateway } from './ClaimGateway';

import { useDeviceState } from '../../../context/DeviceContext';
import { sortDevices } from '../_deviceFunctions';

const useStyles = makeStyles((theme) => ({
	noneSelected: {
		paddingLeft: theme.spacing(4),
		height: '100%',
		display: 'flex',
	},
	noneSelectedContent: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	noneSelectedIcon: {
		fontSize: '3rem',
		color: theme.palette.greyOne.main,
	},
	invalidNodeWrapper: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
	},
	invalidNode: {
		borderRadius: theme.spacing(1) / 2,
		padding: theme.spacing(4),
		margin: theme.spacing(2),
		color: theme.palette.error.main,
		textAlign: 'center',
	},
	invalidNodeIcon: {
		fontSize: '8rem',
		paddingBottom: theme.spacing(2),
		paddingTop: theme.spacing(2),
	},
}));

export const Gateways = () => {
	const classes = useStyles(); //Called here and passed to avoid breaking hook rules
	const { peblGateways, vyneGateways, loaded } = useDeviceState();
	const [thisLoading, setThisLoading] = useState(true);
	const [selectedGateway, setSelectedGateway] = useState(undefined);
	const [devices, setDevices] = useState([]);
	const [addOpen, setAddOpen] = useState(false);
	const [sortDetails, setSortDetails] = useState(['online', 'asc']);

	useEffect(() => {
		let persistedSort = localStorage.getItem('device-sort');
		if (loaded) {
			let sd = persistedSort !== null ? JSON.parse(persistedSort) : sortDetails;
			let sortedDevices = sortDevices(
				[...peblGateways, ...vyneGateways],
				sd[0],
				sd[1]
			);
			setDevices(sortedDevices);
			setThisLoading(false);

			if (selectedGateway) {
				setSelectedGateway(
					sortedDevices.find((sd) => sd.id === selectedGateway.id)
				);
			}
		}
	}, [peblGateways, vyneGateways, loaded]);

	const applySort = () => {
		localStorage.setItem('device-sort', JSON.stringify(sortDetails));
		let sortedDevices = sortDevices(
			[...peblGateways, ...vyneGateways],
			sortDetails[0],
			sortDetails[1]
		);
		setDevices(sortedDevices);
	};

	const breadcrumbs = [
		{ text: 'Devices', link: '/devices' },
		{ text: 'Gateways', link: '' },
	];

	const actionButtons = () => {
		if (devices.length > 0) {
			return (
				<React.Fragment>
					<FingotiButton light color='primary' onClick={setAddOpen}>
						Claim device
					</FingotiButton>
				</React.Fragment>
			);
		} else {
			return <></>;
		}
	};

	const handleCardClick = (gateway) => {
		setSelectedGateway(gateway);
	};

	const unsetSelectedGateway = () => {
		setSelectedGateway({});
	};

	return thisLoading ? (
		<FingotiLoading />
	) : (
		<>
			<FingotiModal title='Claim Device' open={addOpen} setOpen={setAddOpen}>
				<ClaimGateway setOpen={setAddOpen} />
			</FingotiModal>
			<FingotiHeader
				breadcrumbs={breadcrumbs}
				sectionIcon={DeviceIcon}
				actionButtons={actionButtons()}
			/>
			<DevicesView
				deviceDetails={DeviceDetails(selectedGateway, classes)}
				deviceList={DeviceList(
					devices,
					handleCardClick,
					unsetSelectedGateway,
					selectedGateway,
					applySort,
					setSortDetails
				)}
				openClaimModal={setAddOpen}
			/>
		</>
	);
};
