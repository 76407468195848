import React, { useState } from 'react';
import clsx from 'clsx';
import { FingotiModal } from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import { WidgetWrapper } from '../../../WidgetWrapper';
import { PeblMQTTCredentialChange } from './PeblMQTTCredentialsChange';
import { RequestBuilder } from '../../../../../services/request.service';
import { useSnackbar } from 'notistack';
import useDeviceErrorHandler from '../../../useDeviceErrorHandler';

const useStyles = makeStyles((theme) => ({
	unset: {
		color: theme.palette.greyThree.main,
		fontStyle: 'italic',
	},
	ssid: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
}));

export const PeblMQTTCredentials = ({ pebl }) => {
	const classes = useStyles();
	const errorHandler = useDeviceErrorHandler();
	const { enqueueSnackbar } = useSnackbar();
	const [editOpen, setEditOpen] = useState(false);
	const rb = new RequestBuilder(pebl.id);

	const handleEditOpen = () => {
		setEditOpen(true);
	};

	const handleClearCredentials = () => {
		rb.addRequest('mqttSetup', 'W', {
			username: '',
			password: '',
		});
		rb.send()
			.then(() => {
				enqueueSnackbar('MQTT credentials cleared successfully', {
					variant: 'success',
				});
			})
			.catch((res) => errorHandler(res));
	};

	return (
		<>
			<FingotiModal
				title='Change Credentials'
				setOpen={setEditOpen}
				open={editOpen}>
				<PeblMQTTCredentialChange setOpen={setEditOpen} device={pebl} />
			</FingotiModal>
			<WidgetWrapper
				title='Credentials'
				displaySave={false}
				icons={[
					<IconButton
						onClick={handleClearCredentials}
						disabled={
							pebl.mqtt.setup.username !== null &&
							pebl.mqtt.setup.username.length === 0
						}>
						<DeleteOutlinedIcon />
					</IconButton>,
				]}>
				<Grid container spacing={2} alignItems='center'>
					<Grid item xs={10}>
						<Typography
							variant='h4'
							className={clsx(classes.ssid, {
								[classes.unset]: !pebl.mqtt.setup.username,
							})}>
							{pebl.mqtt.setup.username != null && pebl.mqtt.setup.username
								? pebl.mqtt.setup.username
								: 'empty'}
						</Typography>
					</Grid>
					<Grid item xs={2}>
						<IconButton onClick={() => handleEditOpen('primary')}>
							<EditOutlinedIcon />
						</IconButton>
					</Grid>
				</Grid>
			</WidgetWrapper>
		</>
	);
};

// export const PeblMQTTCredentials = ({ pebl }) => {

//     const { enqueueSnackbar } = useSnackbar();
//     const [saveEnabled, setSaveEnabled] = useState(false);
//     const [username, setUsername] = useState(pebl.mqtt.setup.username);
//     const [password, setPassword] = useState('');
//     const rb = new RequestBuilder(pebl.id);

//     const enableSave = () => {
//         if (saveEnabled) {
//             return;
//         }

//         setSaveEnabled(true);
//     }

//     const handleSave = () => {
//         setSaveEnabled(false);
//         rb.addRequest("mqttSetup", "W", { username: username, password: password });
//         rb.send()
//         .then(() => {
//             enqueueSnackbar('MQTT Setup saved successfully', { variant: 'success' });
//         })
//         .catch(errors => {
//             if (errors && Array.isArray(errors)) {
//                 errors.forEach(error => {
//                     enqueueSnackbar(`Error ${error.result.code} - ${error.result.message}`, { variant: 'error' });
//                 })
//             } else {
//                 enqueueSnackbar(errors, { variant: 'error' });
//             }
//         });
//     }

//     return (
//         <>
//             <WidgetWrapper title="Credentials" displaySave={true} saveEnabled={saveEnabled} onSave={handleSave}>
//                 <Grid container spacing={3}>
//                     <Grid item xs={12}>
//                         <TextField
//                             fullWidth
//                             variant='outlined'
//                             label='username'
//                             value={username}
//                             onChange={(e) => {
//                                 setUsername(e.target.value);
//                                 enableSave();
//                             }}
//                             placeholder='mqttuser'
//                         />
//                     </Grid>
//                     <Grid item xs={12}>
//                         <TextField
//                             fullWidth
//                             variant='outlined'
//                             label='password'
//                             type='password'
//                             value={password}
//                             onChange={(e) => {
//                                 setPassword(e.target.value);
//                                 enableSave();
//                             }}
//                             placeholder='password123'
//                         />
//                     </Grid>
//                 </Grid>
//             </WidgetWrapper>
//         </>
//     );

// }
