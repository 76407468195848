import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { WidgetWrapper } from '../../../WidgetWrapper';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({

    lux: {
        color: theme.palette.primary.main
    },

}))


export const VyneLux = ({ data }) => {

    const classes = useStyles();

    return (
        <WidgetWrapper title="Luminosity" displaySave={false}>
            <Typography variant="h3" className={classes.lux}>
                {data[0].value + data[0].unit}
            </Typography>
        </WidgetWrapper>
    );

}