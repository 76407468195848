import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles(theme => ({

    icon: {
        fontSize: '12rem !important',
        color: theme.palette.greyTwo.main
    }

}))

export const IncompleteGateway = ({ serial }) => {

    const classes = useStyles();

    return (
        <Box display='flex' alignItems='center' justifyContent='center' height='60vh'>
            <Box display='flex' flexDirection='column' alignItems='center'>
                <Box my={2}>
                    <InfoOutlinedIcon className={classes.icon} />
                </Box>
                <Box my={1}>
                    <Typography>
                        We haven't seen {serial} come online yet.
                    </Typography>
                </Box>
                <Box>
                    <Typography>
                        Please connect it to the Fingoti Cloud to start controlling and monitoring your device.
                    </Typography>
                </Box>
            </Box>
        </Box>
    )

}