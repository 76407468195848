import { authService, refreshLock } from './auth.service';
import { handleResponse } from './response.service';
import Queue from './queue.service';
import { apiUrl, accountUrl } from './config';

async function getData(path) {

    console.log("getting data from: " + path);

    let requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...authService.authHeader() },
    };

    if (path.includes('token=')) {
        requestOptions.headers = { 'Content-Type': 'application/json' }
    }

    let request = new Request(apiUrl + path, requestOptions);

    return new Promise((resolve, reject) => {
        callAPI(request)
            .then(data => resolve(data))
            .catch(error => reject(error));
    })
}

async function patchData(path, bodyData) {

    console.log("patch data to: " + path);

    let requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', ...authService.authHeader() },
        body: JSON.stringify(bodyData)
    };

    if (path.includes('token=')) {
        requestOptions.headers = { 'Content-Type': 'application/json' }
    }

    let request = new Request(apiUrl + path, requestOptions);

    return new Promise((resolve, reject) => {
        callAPI(request)
            .then(data => resolve(data))
            .catch(error => reject(error));
    })
}

async function postData(path, bodyData) {

    console.log("posting data to: " + path);

    let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authService.authHeader() },
        body: JSON.stringify(bodyData)
    };

    if (path.includes('token=')) {
        requestOptions.headers = { 'Content-Type': 'application/json' }
    }

    let request = new Request(apiUrl + path, requestOptions);

    return new Promise((resolve, reject) => {
        callAPI(request)
            .then(data => resolve(data))
            .catch(error => reject(error));
    })
}

async function postFormData(path, formData) {

    console.log("posting form data to: " + path);

    let requestOptions = {
        method: 'POST',
        headers: { ...authService.authHeader() },
        body: formData
    };

    let request = new Request(apiUrl + path, requestOptions);

    return new Promise((resolve, reject) => {
        callAPI(request)
            .then(data => resolve(data))
            .catch(error => reject(error));
    })
}

async function deleteData(path) {

    console.log("posting data to: " + path);

    let requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', ...authService.authHeader() },
    };

    if (path.includes('token=')) {
        requestOptions.headers = { 'Content-Type': 'application/json' }
    }

    let request = new Request(apiUrl + path, requestOptions);

    return new Promise((resolve, reject) => {
        callAPI(request)
            .then(data => resolve(data))
            .catch(error => reject(error));
    })
}

const callAPI = (request) => {
    
    //Set the auth header at the time of call as the token may have changed (fix for queue)
    if(request.headers.has('Authorization')) {
        request.headers.set('Authorization', authService.authHeader().Authorization)
    }
    
    let requestCopy = request.clone(); 
    
    return new Promise((resolve, reject) => {
        fetch(request)
            .then(handleResponse)
            .then(data => {
                return resolve(data)
            })
            .catch(error => {
                if (error.retry) {
                    //Q request for later 
                    Queue.enqueue(() => 
                        //Q a copy of the inital request without token refresh to avoid refreshing twice
                        callAPI(requestCopy)
                            //This was causing errors to be handled in wrong place, silly me 
                            // .then(data => {
                            //     return resolve(data);
                            // })
                            // .catch(error => {
                            //     return reject(error.error);
                            // })
                        )
                    //Try and get a new app token, if its not already trying
                    if(!refreshLock) {
                        authService.getAppToken()
                            .then(() => {
                                Queue.dequeue();
                            })
                            .catch(error => {
                                //If we got a 401 from auth api, ask user to login
                                if(error.requireAuth) {
                                    window.location.assign(`${accountUrl}?ReturnUrl=${window.location.href}`);
                                }
                                return reject(error.error);
                            }) 
                    }
                } else {
                    //Some other error has occured pass it back for displaying etc...
                    return reject(error.error);
                }
            })
    })

}


export const apiService = {
    getData,
    patchData,
    postData,
    postFormData,
    deleteData,
    callAPI
}