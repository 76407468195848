import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';

import { WidgetWrapper } from '../../WidgetWrapper';
import { RequestBuilder } from '../../../../services/request.service';
import useDeviceErrorHandler from '../../useDeviceErrorHandler';

const useStyles = makeStyles((theme) => ({
	jsonReq: {
		fontFamily: "'Courier New', monospace",
	},
}));

export const ScheduleRequest = ({ device, slot }) => {

	const requestJsonString = () => {
		return JSON.stringify(
			{
				request: device.schedule.setup[slot].request,
			},
			null,
			2
		)
	}

	const errorHandler = useDeviceErrorHandler();
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [request, setRequest] = useState(requestJsonString());
	const [saveEnabled, setSaveEnabled] = useState(false);
	const [oldSlot, setOldSlot] = useState(slot);
	const rb = new RequestBuilder(device.id);

	useEffect(() => {
		if(!saveEnabled || slot !== oldSlot) {
			setRequest(requestJsonString());
			setOldSlot(slot);
		}
	}, [device, slot]);

	const enableSave = () => {
		if (saveEnabled) {
			return;
		}

		setSaveEnabled(true);
	};

	const handleRequestSave = () => {
		let requestJson;
		try {
			requestJson = JSON.parse(request);
		} catch (e) {
			enqueueSnackbar('JSON not valid', { variant: 'error' });
			return;
		}

		rb.addRequest('scheduleRequest', 'W', { slot: slot, request: requestJson.request });

		rb.send()
			.then(() => {
				enqueueSnackbar('Request successfully saved', { variant: 'success' });
				setRequest(JSON.stringify(requestJson, null, 2));
				setSaveEnabled(false);
			})
			.catch((res) => errorHandler(res));
	};

	return (
		<WidgetWrapper
			title='Request'
			saveEnabled={saveEnabled}
			onSave={handleRequestSave}>
			<TextField
				id='requestjson'
				label='request json'
				multiline
				rowsMax={14}
				rows={14}
				value={request}
				onChange={(e) => {
					setRequest(e.target.value);
					enableSave();
				}}
				variant='outlined'
				InputProps={{
					classes: {
						input: classes.jsonReq,
					},
				}}
			/>
		</WidgetWrapper>
	);
};
