import { useSnackbar } from 'notistack';

import { apiService } from '../../../services/api.service';

export const useWebhookFunctions = () => {

    const { enqueueSnackbar } = useSnackbar();

    const _multiRetry = (webhooks) => {
        let retries = [];

        webhooks.forEach(webhook => {
            let wh = { webhookId: webhook.id, calls: [] }
            let isRetry = false;

            webhook.calls.forEach(call => {
                if (call.status === 2 && call.count < 10) {
                    isRetry = true;
                    wh.calls.unshift(call.id);
                }
            })

            if (isRetry) {
                retries.unshift(wh);
            }
        })

        apiService.postData('/webhook/retry', { retries: retries })
            .then(result => {
                enqueueSnackbar(result.message, { variant: 'success' });
            })
            .catch(error => {
                enqueueSnackbar(error, { variant: 'error' });
            })
    }

    const _singleRetry = (callId, webhookId) => {

        let retries = {
            retries: [
                {
                    webhookId: webhookId,
                    calls: [
                        callId
                    ]
                }
            ]
        }

        apiService.postData('/webhook/retry', retries)
            .then(result => {
                enqueueSnackbar(result.message, { variant: 'success' });
            })
            .catch(error => {
                enqueueSnackbar(error, { variant: 'error' });
            })
    };

    const _statusEnum = (s) => {
        let status = "Queued";

        if (s === 1) {
            status = "Pending"
        } else if (s === 2) {
            status = "Failed"
        } else if (s === 3) {
            status = "Success"
        }

        return status;
    }

    return {
        singleRetry: _singleRetry,
        multiRetry: _multiRetry,
        statusEnum: _statusEnum
    }
}
