import React, { useState, useCallback } from 'react';
import { PieChart, Pie, Sector } from 'recharts';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { InsufficientData } from './InsufficientData';
import { WidgetWrapper } from '../device/WidgetWrapper';

const useStyles = makeStyles((theme) => ({
	pie: {
		display: 'flex',
		justifyContent: 'center',
	},
	tooltip: {
		backgroundColor: '#fff',
		padding: theme.spacing(1),
	},
}));

const renderActiveShape = (props) => {
	const RADIAN = Math.PI / 180;
	const {
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		startAngle,
		endAngle,
		fill,
		percent,
		value,
	} = props;
	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius + 10) * cos;
	const sy = cy + (outerRadius + 10) * sin;
	const mx = cx + (outerRadius + 30) * cos;
	const my = cy + (outerRadius + 30) * sin;
	const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	const ey = my;
	const textAnchor = cos >= 0 ? 'start' : 'end';

	return (
		<g>
			<text x={cx} y={cy} dy={8} fontSize="1.35rem" textAnchor='middle' fill={fill}>
				{props.module}
			</text>
			<Sector
				cx={cx}
				cy={cy}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				startAngle={startAngle}
				endAngle={endAngle}
				fill={fill}
			/>
			<Sector
				cx={cx}
				cy={cy}
				startAngle={startAngle}
				endAngle={endAngle}
				innerRadius={outerRadius + 6}
				outerRadius={outerRadius + 10}
				fill={"#C0C0C0"}
			/>
			<path
				d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
				stroke={"#C0C0C0"}
				fill='none'
			/>
			<circle cx={ex} cy={ey} r={2} fill={"#C0C0C0"} stroke='none' />
			<text
				x={ex + (cos >= 0 ? 1 : -1) * 12}
				y={ey}
				textAnchor={textAnchor}
				fill='#333'>{value}</text>
			<text
				x={ex + (cos >= 0 ? 1 : -1) * 12}
				y={ey}
				dy={18}
				textAnchor={textAnchor}
				fill='#999'>
				{`${(percent * 100).toFixed(0)}%`}
			</text>
		</g>
	);
};

export const UsageByModuleChart = ({ insufficientData, data }) => {
	const classes = useStyles();
	const [activeIndex, setActiveIndex] = useState(data.length - 1);
	const onPieEnter = useCallback(
		(_, index) => {
			setActiveIndex(index);
		},
		[setActiveIndex]
	);


	const RADIAN = Math.PI / 180;

	return (
		<WidgetWrapper displaySave={false} title='Usage by Module'>
			{insufficientData ? (
				<InsufficientData />
			) : (
				<div className={classes.pie}>
					<PieChart width={500} height={360}>
						<Pie
							activeIndex={activeIndex}
							activeShape={renderActiveShape}
							data={data}
                            nameKey='module'
							dataKey='value'
							cx='50%'
							cy='50%'
							innerRadius={85}
							outerRadius={125}
							paddingAngle={5}
							fill='#FF8C23'
                            onMouseEnter={onPieEnter}
						/>
					</PieChart>
				</div>
			)}
		</WidgetWrapper>
	);
};
