const pirValue = (valueString) => {
	if (valueString.includes('DISABLED')) {
		return [
			{
				unit: '',
				value: '--',
			},
		];
	}

	return [
		{
			unit: '',
			value: valueString,
		},
	];
};

const luxValue = (valueString) => {
	if (valueString.includes('DISABLED')) {
		return [
			{
				unit: ' lux',
				value: '--',
			},
		];
	}

	return [
		{
			unit: ' lux',
			value: valueString,
		},
	];
};

const tempHumidValue = (valueString) => {
	if (valueString.includes('DISABLED')) {
		return [
			{
				unit: '°C',
				value: '--',
			},
			{
				unit: '°F',
				value: '--',
			},
			{
				unit: '%',
				value: '--',
			},
		];
	}

	let valueParts = valueString.split('|');

	return [
		{
			unit: '°C',
			value: valueParts[0],
		},
		{
			unit: '°F',
			value: valueParts[1],
		},
		{
			unit: '%',
			value: valueParts[2],
		},
	];
};

export const gatewayType = {
	322612: {
		type: 'pebl',
	},
	130936: {
		type: 'pebl',
	},
	386363: {
		type: 'pebl',
	},
	460744: {
		type: 'vyne',
		targetCurrent: 130,
		targetVoltage: 12,
	},
};

export const nodeType = {
	'000000': {
		type: 'foreign node',
	},
	'293517': {
		type: 'lux',
		targetCurrent: 30,
		targetVoltage: 12,
		getData: luxValue,
	},
	'884167': {
		type: 't&h',
		targetCurrent: 30,
		targetVoltage: 12,
		getData: tempHumidValue,
	},
	'196850': {
		type: 'pir',
		targetCurrent: 30,
		targetVoltage: 12,
		getData: pirValue,
	},
	'920833': {
		type: 'psu',
		targetCurrent: 30,
		targetVoltage: 12,
		getData: pirValue,
	},
	'698869': {
		type: 'poe',
		targetCurrent: 30,
		targetVoltage: 12,
		getData: pirValue,
	},
	'528407': {
		type: 'ups',
		targetCurrent: 30,
		targetVoltage: 12,
		getData: pirValue,
	},
};

export const deviceType = {
	'000000': 'foreign node',
	'293517': 'lux',
	'322612': 'pebl',
	'386363': 'pebl',
	'130936': 'pebl',
	'460744': 'vyne',
};

export const wifiStatusEnum = (status) => {
	switch (status) {
		case 1:
			return 'Secondary';
		case 0:
		default:
			return 'Primary';
	}
};

export const getWifiSSID = (status, ssids) => {
	return ssids[status];
};

const firstBy = (function () {
	function e(f) {
		f.thenBy = t;
		return f;
	}
	function t(y, x) {
		x = this;
		return e(function (a, b) {
			return x(a, b) || y(a, b);
		});
	}
	return e;
})();

export const sortDevices = (devices, sortBy, sortOrder) => {
	if (sortBy === 'online') {
		let sorted = [];
		let onlineDevices = devices.filter((d) =>
			d.device ? d.device.cloud.connected : d.online
		);
		let claimingDevice = devices.filter((d) =>
			d.claim ? !d.claim.complete : false
		);
		let offlineDevices = devices.filter((d) =>
			d.device ? !d.device.cloud.connected && d.claim.complete : !d.online
		);

		let sortedOnline = sortByName(onlineDevices, sortOrder);
		let sortedClaiming = sortByName(claimingDevice, sortOrder);
		let sortedOffline = sortByName(offlineDevices, sortOrder);

		sorted.push(...sortedOnline);
		sorted.push(...sortedClaiming);
		sorted.push(...sortedOffline);

		if (sortOrder == 'des') {
			return sorted.reverse();
		} else {
			return sorted;
		}
	} else if (sortBy === 'deviceName') {
		return sortByName(devices, sortOrder);
	}

	let sortedDevices = devices.sort((a, b) => {
		let aPath, bPath;

		if (sortBy === 'online') {
			// if (a.gatewayId !== undefined) {
			//     aPath = a.online;
			// } else {
			//     aPath = a.device.cloud.connected;
			// }
			// if (b.gatewayId !== undefined) {
			//     bPath = b.online;
			// } else {
			//     bPath = b.device.cloud.connected;
			// }
		} else if (sortBy === 'deviceName') {
			if (a.gatewayId !== undefined) {
				aPath = a.name;
			} else {
				aPath = a.device.name;
			}

			if (b.gatewayId !== undefined) {
				bPath = b.name;
			} else {
				bPath = b.device.name;
			}

			if (aPath === undefined || bPath === undefined) {
				return 0;
			} else if (aPath > bPath) {
				return sortOrder === 'asc' ? 1 : -1;
			} else if (aPath < bPath) {
				return sortOrder === 'asc' ? -1 : 1;
			} else {
				return 0;
			}
		}

		// if (aPath === undefined || bPath === undefined) {
		//     return 0;
		// } else if (aPath > bPath) {
		//     return sortOrder === "asc" ? 1 : -1;
		// } else if (aPath < bPath) {
		//     return sortOrder === "asc" ? -1 : 1;
		// } else {
		//     return 0;
		// }
	});

	return sortedDevices;
};

const sortByName = (devices, sortOrder) => {
	return devices.sort((a, b) => {
		let aPath, bPath;

		if (a.gatewayId !== undefined) {
			aPath = a.name;
		} else {
			aPath = a.device.name;
		}

		if (b.gatewayId !== undefined) {
			bPath = b.name;
		} else {
			bPath = b.device.name;
		}

		if (aPath === undefined || bPath === undefined) {
			return 0;
		} else if (aPath > bPath) {
			return sortOrder === 'asc' ? 1 : -1;
		} else if (aPath < bPath) {
			return sortOrder === 'asc' ? -1 : 1;
		} else {
			return 0;
		}
	});
};

// const sortOnline = (devices, sortOrder) => {
//     return devices.sort(
//         firstBy((a, b) => {
//             let aPath, bPath;

//             if (a.gatewayId !== undefined) {
//                 aPath = a.online;
//             } else {
//                 aPath = a.device.cloud.connected;
//             }

//             if (b.gatewayId !== undefined) {
//                 bPath = b.online;
//             } else {
//                 bPath = b.device.cloud.connected;
//             }

//             if (aPath === undefined || bPath === undefined) {
//                 return 0;
//             } else if (aPath > bPath) {
//                 return sortOrder === "asc" ? 1 : -1;
//             } else {
//                return sortOrder === "asc" ? -1 : 1;
//             }
//         }).thenBy((a,b) => {
//             if (a.claim.complete === undefined || b.claim.complete === undefined) {
//                 return 0;
//             } else if (a.claim.complete > b.claim.complete) {
//                 return sortOrder === "asc" ? 1 : -1;
//             } else {
//                return sortOrder === "asc" ? -1 : 1;
//             }
//         })
//     )
// }
