import React from 'react';

import { WidgetWrapper } from '../../../WidgetWrapper';
import { Typography } from '@material-ui/core';


export const VyneHumidity = ({ data }) => {

    return (
        <WidgetWrapper title="Humidity" displaySave={false}>
            <Typography variant="h3">
                {data[2].value + data[2].unit}
            </Typography>
        </WidgetWrapper>
    );

}