import React, { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FingotiButton, FingotiHeader, FingotiMarkdown, FingotiTable, URLGenerator } from '@fingoti/components';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReplayOutlinedIcon from '@material-ui/icons/ReplayOutlined';
import { WebhookIcon } from '../../../icons/FingotIcons';

import { datetimeService } from '../../../services/datetime.service';
import { apiService } from '../../../services/api.service';
import { useProfileState } from '../../../context/ProfileContext';
import { useWebhookFunctions } from './_webhookFunctions';
import { useRoleCheck } from '../../utils/RoleCheck';


export const WebhookLogs = () => {

    const history = useHistory();
    const { singleRetry, statusEnum } = useWebhookFunctions();
    const { loading } = useProfileState();
    const tableRef = useRef();

    useEffect(() => {
        if(tableRef.current) {
            tableRef.current.onQueryChange();
        }
    }, [loading])

    const breadcrumbs = [
        { text: 'Webhooks', link: '/webhooks' },
        { text: 'Logs', link: '' }
    ]

    // const actionButtons = () => {
    //     return (
    //         <React.Fragment>
    //             <FingotiButton light color="primary" disabled={roleCheck("webhook", 2) || failed.length <= 0} onClick={() => multiRetry()}>
    //                 retry all
    //             </FingotiButton>
    //         </React.Fragment>
    //     );
    // }

    const getLogs = (query) => {
        return apiService.getData(URLGenerator(query, `/webhook/logs`))
            .then(data => {
                return {
                    data: data.calls,
                    page: data.pageNumber - 1,
                    totalCount: data.count,
                }
            })
            .catch(e => {
                console.error("get logs error", e);
            })
    }

    const handleCallInfoClick = (call) => {
        history.push(`/webhooks/logs`, { call: call, webhookNumber: call.webhookNumber, webhookId: call.id });
    }

    return (
        <>
            <FingotiHeader breadcrumbs={breadcrumbs} sectionIcon={WebhookIcon} />
            <FingotiMarkdown 
                path="/documentation/portal/webhook/logs.md"
            />
            <FingotiTable
                data={getLogs}
                tableRef={tableRef}
                columns={[
                    { title: "id", field: "id", hidden: true },
                    { title: "count", field: "count", hidden: true },
                    { title: "webhook id", field: "webhookNumber" },
                    {
                        title: "status",
                        field: "status",
                        render: rd => statusEnum(rd.status),
                        lookup: { 0: "Queued", 1: "Pending", 2: "Failed", 3: "Success" }
                    },
                    { 
                        title: "called at", 
                        field: "timestamp", 
                        render: rd => datetimeService.formatDateTime(new Date(rd.timestamp)),
                        filtering: false 
                    },
                    { title: "url", field: "requestUrl", filtering: false },
                    { title: "attmepts", render: rd => rd.attempts.length }
                ]}
                actions={[
                    rowData => (
                        rowData.status === 2 && rowData.count < 10 ?
                            {
                                icon: ReplayOutlinedIcon,
                                tooltip: "retry call",
                                onClick: () => singleRetry(rowData.id, rowData.webhookId),
                            }
                            :
                            null
                    ),
                    rowData => ({
                        icon: InfoOutlinedIcon,
                        tooltip: "call info",
                        onClick: () => handleCallInfoClick(rowData)
                    })
                ]}
            />
        </>
    )
}