import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

import { ReactComponent as Add } from '../icons/Add.svg';
import { ReactComponent as Cloud } from '../icons/Cloud.svg';
import { ReactComponent as CO } from '../icons/CO.svg';
import { ReactComponent as Device } from '../icons/Device.svg';
import { ReactComponent as Gateway } from '../icons/Gateway.svg';
import { ReactComponent as IDCard } from '../icons/IdCard.svg';
import { ReactComponent as LeftArrow } from '../icons/LeftArrow.svg';
import { ReactComponent as LightBulb } from '../icons/LightBulb.svg';
import { ReactComponent as Loupe } from '../icons/Loupe.svg';
import { ReactComponent as PieChart } from '../icons/PieChart.svg';
import { ReactComponent as Question } from '../icons/Question.svg';
import { ReactComponent as SettingsCog } from '../icons/SettingsCog.svg';
import { ReactComponent as Thermometer } from '../icons/Thermometer.svg';
import { ReactComponent as Webhook } from '../icons/Webhook.svg';

export const AddIcon = (props) => {

    return (
        <SvgIcon {...props}>
            <Add />
        </SvgIcon>
    );

}

export const CloudIcon = (props) => {

    return (
        <SvgIcon {...props}>
            <Cloud />
        </SvgIcon>
    );

}

export const COIcon = (props) => {

    return (
        <SvgIcon {...props}>
            <CO />
        </SvgIcon>
    );

}

export const DeviceIcon = (props) => {

    return (
        <SvgIcon {...props}>
            <Device />
        </SvgIcon>
    );

}

export const GatewayIcon = (props) => {

    return (
        <SvgIcon {...props}>
            <Gateway />
        </SvgIcon>
    );

}

export const IDCardIcon = (props) => {

    return (
        <SvgIcon {...props}>
            <IDCard />
        </SvgIcon>
    );

}

export const LeftArrowIcon = (props) => {

    return (
        <SvgIcon {...props}>
            <LeftArrow />
        </SvgIcon>
    );

}

export const LightBulbIcon = (props) => {

    return (
        <SvgIcon {...props}>
            <LightBulb />
        </SvgIcon>
    );

}

export const LoupeIcon = (props) => {

    return (
        <SvgIcon {...props}>
            <Loupe />
        </SvgIcon>
    );

}

export const PieChartIcon = (props) => {

    return (
        <SvgIcon {...props}>
            <PieChart />
        </SvgIcon>
    );

}

export const QuestionIcon = (props) => {

    return (
        <SvgIcon {...props}>
            <Question />
        </SvgIcon>
    );

}

export const SettingsCogIcon = (props) => {

    return (
        <SvgIcon {...props}>
            <SettingsCog />
        </SvgIcon>
    );

}

export const ThermometerIcon = (props) => {

    return (
        <SvgIcon {...props}>
            <Thermometer />
        </SvgIcon>
    );

}

export const WebhookIcon = (props) => {

    return (
        <SvgIcon {...props}>
            <Webhook />
        </SvgIcon>
    );

}