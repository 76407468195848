import React, { useEffect, useReducer } from 'react';
import { useSnackbar } from 'notistack';

import { WidgetGrid } from '../../WidgetGrid';
import { GPIO } from '../../shared/widgets/GPIO';
import { GPIODirection } from '../../shared/widgets/GPIODirection';
import { GPIOState } from '../../shared/widgets/GPIOState';

const gridLayout = {
    xl: [
        { i: '1', x: 0, y: 0, w: 2, h: 4 },
        { i: '2', x: 2, y: 0, w: 3, h: 8 },
        { i: '3', x: 5, y: 0, w: 3, h: 8 },
    ],
    lg: [
        { i: '1', x: 0, y: 0, w: 2, h: 4 },
        { i: '2', x: 2, y: 0, w: 3, h: 8 },
        { i: '3', x: 5, y: 0, w: 3, h: 8 },
    ],
    md: [
        { i: '1', x: 0, y: 0, w: 3, h: 4 },
        { i: '2', x: 3, y: 0, w: 3, h: 8 },
        { i: '3', x: 0, y: 4, w: 3, h: 8 },
    ],
    sm: [
        { i: '1', x: 0, y: 0, w: 4, h: 4 },
        { i: '2', x: 0, y: 4, w: 4, h: 8 },
        { i: '3', x: 0, y: 12, w: 4, h: 8 },
    ],
}

//All this pins stuff is a bit confusing...
//
// Direction:
//  0 - false - out
//  1 - true - in
//
// State:
//  0 - false - off
//  1 - true - on

const gpioReducer = (state, action) => {
    switch (action.action) {
        case 'CHNG_DIR':
            if (action.payload.value && state[action.payload.gpio].state) {
                state[action.payload.gpio].state = false;
            }
            state[action.payload.gpio].direction = action.payload.value;
            return [...state];
        case 'CHNG_STATE':
            state[action.payload.gpio].state = action.payload.value;
            return [...state];
        case 'INIT':
            let oldFormat = [];
            for(let i = 0; i < action.payload.direction.length; i++) {
                let gpio = {
                    direction: action.payload.direction[i],
                    state: action.payload.state[i]
                }

                oldFormat.push(gpio);
            }

            return oldFormat;
        default:
            throw new Error("Invalid action passed to gpioReducer");
    }
}

export const VyneGatewayGPIO = ({ request, vyne }) => {

    const [gpio, setGPIO] = useReducer(gpioReducer, []);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        let gpioCopy = JSON.parse(JSON.stringify(vyne.gpio));
        setGPIO({ action: 'INIT', payload: gpioCopy })
    }, [vyne.gpio])


    const handleGPIODirSave = (gpio) => {
        let allGPIODir = {
            direction: [
                gpio[0].direction ? 1 : 0,
                gpio[1].direction ? 1 : 0,
                gpio[2].direction ? 1 : 0,
                gpio[3].direction ? 1 : 0
            ]
        }

        request.addRequest("gpioDirection", "W", allGPIODir);
        request.addRequest("gpioState", "R");
        request.sendSingleSection(["gpioDirection", "gpioState"])
            .then(() => {
                enqueueSnackbar("GPIO direction successfully changed", { variant: 'success' });
            })
            .catch(errors => {
                if (errors && Array.isArray(errors)) {
                    errors.forEach(error => {
                        enqueueSnackbar(`Error ${error.result.code} - ${error.result.message}`, { variant: 'error' });
                    })
                } else {
                    enqueueSnackbar(errors, { variant: 'error' });
                }
            });
    }

    const handleGPIOStateSave = (gpio) => {
        let allGPIOState = {
            state: [
                gpio[0].state ? gpio[0].direction ? 0 : 1 : 0,
                gpio[1].state ? gpio[1].direction ? 0 : 1 : 0,
                gpio[2].state ? gpio[2].direction ? 0 : 1 : 0,
                gpio[3].state ? gpio[3].direction ? 0 : 1 : 0
            ]
        }

        request.addRequest("gpioState", "W", allGPIOState);
        request.sendSingleSection(["gpioState"])
            .then(() => {
                enqueueSnackbar("GPIO state successfully changed", { variant: 'success' });
            })
            .catch(errors => {
                if (errors && Array.isArray(errors)) {
                    errors.forEach(error => {
                        enqueueSnackbar(`Error ${error.result.code} - ${error.result.message}`, { variant: 'error' });
                    })
                } else {
                    enqueueSnackbar(errors, { variant: 'error' });
                }
            });
    }

    return (
        <WidgetGrid layouts={gridLayout}>
            <div key='1'>
                <GPIO gpio={vyne.gpio} />
            </div>
            <div key='2'>
                <GPIODirection
                    gpio={gpio}
                    setGPIO={setGPIO}
                    eventHandlers={{
                        sectionSave: handleGPIODirSave
                    }}
                />
            </div>
            <div key='3'>
                <GPIOState
                    gpio={gpio}
                    setGPIO={setGPIO}
                    currentGPIO={vyne.gpio}
                    eventHandlers={{
                        sectionSave: handleGPIOStateSave
                    }}
                />
            </div>
        </WidgetGrid>
    );

}