import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { apiService } from '../services/api.service';
import { useDeviceDispatch } from './DeviceContext';
import { useProfileState } from './ProfileContext';
import { buildSignalRHub, deviceHub } from '../services/signalr.service';

let lastUpdated = '';

export const DeviceData = () => {
	const { loading } = useProfileState();
	const dispatch = useDeviceDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const getData = () => {
		console.log('Starting device context fill...');
		dispatch({ type: 'LOADING' });
		getDevices()
			.then((result) => {
				lastUpdated = result.lastUpdated;
				dispatch({
					type: 'INIT_LOAD',
					payload: {
						peblGateways: result.pebl.gateway,
						vyneGateways: result.vyne.gateway,
						vyneNodes: result.vyne.node,
						count: result.count,
						claimPendingCount: result.claimPendingCount,
						ownedDeviceCount: result.ownedDeviceCount,
						onlineGateways: result.onlineGateways,
					},
				});
				startSignalR();
			})
			.catch((error) => {
				console.error(error);
				enqueueSnackbar('Failed to load devices', { variant: 'error' });
			});
	};

	const updateDevices = () => {
		getDevices(lastUpdated)
			.then((result) => {
				lastUpdated = result.lastUpdated;
				dispatch({
					type: 'UPDATE_DEVICES',
					payload: {
						peblGateways: result.pebl.gateway,
						vyneGateways: result.vyne.gateway,
						vyneNodes: result.vyne.node,
						count: result.count,
						claimPendingCount: result.claimPendingCount,
						ownedDeviceCount: result.ownedDeviceCount,
						onlineGateways: result.onlineGateways,
					},
				});
			})
			.catch((error) => {
				enqueueSnackbar('Failed to update devices', { variant: 'error' });
			});
	};

	const getDevices = (lastUpdated) => {
		let path = '/device';

		if (lastUpdated) {
			path = `/device?lastUpdated=${lastUpdated}`;
		}

		return new Promise((resolve, reject) => {
			apiService
				.getData(path)
				.then((result) => {
					resolve(result);
				})
				.catch((error) => {
					dispatch({ type: 'LOADED' });
					enqueueSnackbar(error, { variant: 'error' });
					reject(false);
				});
		});
	};

	const startSignalR = () => {
		buildSignalRHub();
		if (deviceHub.state === 'Disconnected') {
			deviceHub
				.start()
				.then(() => {
					console.log('SignalR started');
					deviceHub.invoke('AddToOrganisation').then((res) => {
						console.log('SignalR added to org', res);
					});
					deviceHub.on('DeviceUpdate', () => {
						// let activeOrg = await authService.findAOCookie();
						// if (msg.organisationId === activeOrg)
						updateDevices();
					});
					deviceHub.on('DeviceRemoved', (msg) => {
						// let activeOrg = await authService.findAOCookie();
						// if (msg.organisationId === activeOrg)
						dispatch({ type: 'REMOVE_DEVICES', payload: msg.devices });
					});
				})
				.catch((error) => {
					console.error('SingalR failed', error);
					enqueueSnackbar(
						'Live updates disconnected, please refresh the page',
						{ variant: 'error', persist: true }
					);
				});
			deviceHub.onclose(() => {
				var switching = localStorage.getItem('switchingOrganisation');
				if (!switching) {
					console.log('SignalR disconnected');
					enqueueSnackbar(
						'Live updates disconnected, please refresh the page',
						{ variant: 'error', persist: true }
					);
				} else {
					console.log('SignalR closed but swtiching');
					localStorage.removeItem('switchingOrganisation');
				}
			});
		}
	};

	return {
		getData: getData,
	};
};
