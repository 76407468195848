import React from 'react';
import clsx from 'clsx';
import {
	FingotiButton,
	FingotiModalActions,
	FingotiModalContent,
} from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { DeviceGPIO } from '../../device/shared/DeviceGPIO';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '70%',
		},
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'space-evenly',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
	},
	button: {
		width: '42%',
	},
	noBottomBorder: {
		border: 'none',
	},
	headerCell: {
		border: 'none',
		textAlign: 'right',
		borderRight: '1px solid ' + theme.palette.greyFour.main,
		width: '24%',
		color: theme.palette.greyTwo.main,
	},
	verticalTop: {
		verticalAlign: 'top',
	},
	statusIcon: {
		verticalAlign: 'middle',
	},
	pins: {
		'display': 'flex',
		'alignItems': 'center',
		'& :not(:first-child)': {
			marginLeft: theme.spacing(1) / 2,
		},
	},
}));

export const ConfigInfo = ({ setOpen, config }) => {
	const classes = useStyles();
	const theme = useTheme();

	return (
		<>
			<FingotiModalContent>
				<div className={classes.root}>
					<TableContainer>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell className={classes.headerCell}>
										preset number
									</TableCell>
									<TableCell className={classes.noBottomBorder}>
										{config.presetNumber}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell className={classes.headerCell}>
										preset name
									</TableCell>
									<TableCell className={classes.noBottomBorder}>
										{config.presetName}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell className={classes.headerCell}>enabled</TableCell>
									<TableCell className={classes.noBottomBorder}>
										{config.disabled ? (
											<BlockOutlinedIcon
												style={{ fill: theme.palette.error.main }}
												className={classes.statusIcon}
											/>
										) : (
											<CheckCircleOutlineIcon
												style={{ fill: theme.palette.success.main }}
												className={classes.statusIcon}
											/>
										)}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell className={classes.headerCell}>
										primary ssid
									</TableCell>
									<TableCell className={classes.noBottomBorder}>
										{config.ssid}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			</FingotiModalContent>

			<FingotiModalActions>
				<div className={classes.buttonGroup}>
					<FingotiButton
						light
						className={classes.button}
						onClick={() => setOpen(false)}>
						Close
					</FingotiButton>
				</div>
			</FingotiModalActions>
		</>
	);
};
