import { FingotiHeader, FingotiMarkdown } from '@fingoti/components';

import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';

export const ReportOverview = ({}) => {
	const breadcrumbs = [
		{ text: 'Reports', link: '/reports' },
		{ text: 'Overview', link: '' },
	];

	return (
		<>
			<FingotiHeader
				breadcrumbs={breadcrumbs}
				sectionIcon={AssignmentOutlinedIcon}
			/>
			<FingotiMarkdown path='/documentation/portal/report/overview.md' />
		</>
	);
};
