import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
	FingotiModalContent,
	FingotiModalActions,
	FingotiButton,
} from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import YoutubeSearchedForOutlinedIcon from '@material-ui/icons/YoutubeSearchedForOutlined';

import Validation from '../../../../../services/validation.service';
import { useForceUpdate } from '../../../../utils/ForceUpdate';
import { RequestBuilder } from '../../../../../services/request.service';
import { WifiCredentialChangeDetect } from './WifiCredentialsChangeDetect';
import useDeviceErrorHandler from '../../../useDeviceErrorHandler';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
	},
	content: {
		display: 'flex',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '75%',
		},
		alignItems: 'center',
		flexDirection: 'column',
	},
	getNetworks: {
		width: '100%',
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'space-evenly',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
	},
	button: {
		width: '42%',
	},
}));

let detectTimeout;

export const WifiCredentialChange = ({ setOpen, device, slot }) => {
	const classes = useStyles();
	const errorHandler = useDeviceErrorHandler();
	const forceUpdate = useForceUpdate();
	const { enqueueSnackbar } = useSnackbar();
	const [errors, setErrors] = useState({});
	const [ssid, setSSID] = useState(device.wifi.credentials[slot] || '');
	const [password, setPassword] = useState('');
	const [submitting, setSubmitting] = useState(false);
	const [detecting, setDetecting] = useState(false);
	const [detect, setDetect] = useState([]);
	const [showPassword, setShowPassword] = useState(false);
	const rb = new RequestBuilder(device.id);

	useEffect(() => {
		return () => clearTimeout(detectTimeout);
	}, []);

	useEffect(() => {
		if (device.wifi.detect.length > 0) {
			if (
				!(
					detect.length === device.wifi.detect.length &&
					detect.every(
						(value, index) => value.signal === device.wifi.detect[index].signal
					)
				)
			) {
				clearTimeout(detectTimeout);
				setDetect(device.wifi.detect);
				setDetecting(false);
			}
		}
	}, [device]);

	const handleSetErrors = (errors) => {
		setErrors(errors);
		forceUpdate();
	};

	const validate = new Validation(errors, handleSetErrors);

	const handleDetectTimeout = () => {
		setDetecting(false);
		enqueueSnackbar(`Failed to get networks`, { variant: 'error' });
	};

	const handleDetectNetworks = () => {
		setDetecting(true);
		rb.addRequest('wifiDetect', 'R');
		rb.send()
			.then(() => {
				enqueueSnackbar(`Searching for wifi networks`, { variant: 'success' });
				detectTimeout = setTimeout(handleDetectTimeout, 10000);
			})
			.catch((res) => errorHandler(res));
	};

	const handleWifiSave = () => {
		setSubmitting(true);
		rb.addRequest('wifiCredentials', 'W', {
			slot: slot,
			ssid: ssid,
			password: password,
		});
		rb.send()
			.then(() => {
				enqueueSnackbar('Wifi credentials saved successfully', {
					variant: 'success',
				});
				setSubmitting(false);
				setOpen(false);
			})
			.catch((res) => errorHandler(res));
	};

	return (
		<>
			<FingotiModalContent>
				<div className={classes.root}>
					<div className={classes.content}>
						<Grid container spacing={8} alignItems='center'>
							<Grid item xs={12} md={6}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<FormControl fullWidth variant='outlined'>
											<TextField
												fullWidth
												required
												name='ssid'
												variant='outlined'
												label='ssid'
												value={ssid}
												onChange={(e) => {
													validate.checkNotEmpty(e);
													setSSID(e.target.value);
												}}
												onBlur={(e) => validate.checkNotEmpty(e)}
												error={Boolean(errors['ssid'])}
												helperText={
													Boolean(errors['ssid']) ? errors['ssid'].msg : ''
												}
												inputProps={{ maxLength: 32 }}
												InputProps={{
													endAdornment:
														device.wifi.detect.length > 0 ? (
															<IconButton
																aria-label='search'
																onClick={
																	detecting ? () => {} : handleDetectNetworks
																}
																edge='end'>
																{detecting ? (
																	<CircularProgress size={20} />
																) : (
																	<YoutubeSearchedForOutlinedIcon />
																)}
															</IconButton>
														) : null,
												}}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<FormControl fullWidth variant='outlined'>
											<TextField
												fullWidth
												required
												name='password'
												variant='outlined'
												label='Password'
												type={showPassword ? 'text' : 'password'}
												value={password}
												onChange={(e) => {
													validate.checkNotEmpty(e);
													setPassword(e.target.value);
												}}
												onBlur={(e) => validate.checkNotEmpty(e)}
												error={Boolean(errors['password'])}
												helperText={
													Boolean(errors['password'])
														? errors['password'].msg
														: ''
												}
												InputProps={{
													endAdornment: (
														<IconButton
															aria-label='toggle password visibility'
															onClick={() => setShowPassword(!showPassword)}
															edge='end'>
															{showPassword ? (
																<VisibilityOff />
															) : (
																<Visibility />
															)}
														</IconButton>
													),
												}}
											/>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} md={6}>
								<Grid item xs={12}>
									<WifiCredentialChangeDetect
										detect={device.wifi.detect}
										detecting={detecting}
										handleDetectNetworks={handleDetectNetworks}
										selectedSSID={ssid}
										setSSID={(ssid) => {
											delete errors['ssid'];
											handleSetErrors(errors);
											setSSID(ssid);
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
					</div>
				</div>
			</FingotiModalContent>
			<FingotiModalActions>
				<div className={classes.buttonGroup}>
					<FingotiButton
						light
						color='primary'
						className={classes.button}
						disabled={Boolean(
							Object.keys(errors).length > 0 || password.length < 8
						)}
						onClick={handleWifiSave}
						loading={submitting}>
						SAVE
					</FingotiButton>
					<FingotiButton
						light
						className={classes.button}
						onClick={() => setOpen(false)}>
						CANCEL
					</FingotiButton>
				</div>
			</FingotiModalActions>
		</>
	);
};
