import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import { FingotiButton } from '@fingoti/components';
import _ from 'lodash';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';

import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { WidgetWrapper } from '../../../WidgetWrapper';

import { deviceHub } from '../../../../../services/signalr.service';
import { RequestBuilder } from '../../../../../services/request.service';
import useDeviceErrorHandler from '../../../useDeviceErrorHandler';

const useStyles = makeStyles((theme) => ({
	'consoleLine': {
		fontFamily: "'Courier New', monospace",
	},
	'consoleLineData': {
		overflowWrap: 'anywhere',
	},
	'sentMsg': {
		color: theme.palette.primary.main,
	},
	'recvMsg': {
		color: theme.palette.secondary.main,
	},
	'indicatorSpacing': {
		marginRight: theme.spacing(1),
	},
	'fullHeightBtn': {
		height: '100%',
	},
	'userInput': {
		width: '82%',
	},
	'checkboxLabel': {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(3),
	},
	'checkbox': {
		padding: 0,
	},
	'@keyframes typing': {
		from: {
			width: 0,
		},
		to: {
			width: '100%',
		},
	},
}));

const PeblUARTConsoleLine = ({ lineData }) => {
	const classes = useStyles();

	return (
		<Box
			display='flex'
			className={clsx([
				lineData.sent ? classes.sentMsg : classes.recvMsg,
				classes.consoleLine,
			])}>
			<span className={classes.indicatorSpacing}>
				{lineData.sent ? '<' : '>'}
			</span>
			<div className={classes.consoleLineData}>{lineData.msg}</div>
		</Box>
	);
};

// lineData = [{send: true, data: [100,103,28]}]

export const PeblUARTConsole = ({ pebl }) => {
	const classes = useStyles();
	const theme = useTheme();
	const errorHandler = useDeviceErrorHandler();
	const { enqueueSnackbar } = useSnackbar();
	const [consoleLines, setConsoleLines] = useState([]);
	const [userInput, setUserInput] = useState('');
	const [crEnabled, setCREnabled] = useState(true);
	const [lfEnabled, setLFEnabled] = useState(true);
	const [userInputError, setUserInputError] = useState(undefined);
	const rb = new RequestBuilder(pebl.id);
	const consoleRows = 21;

	useEffect(() => {
		let persistedData = window.localStorage.getItem(`${pebl.id}-UART-DATA`);
		if (persistedData) {
			setConsoleLines(JSON.parse(persistedData));
		}

		deviceHub.invoke('AddToDevice', { id: pebl.id }).then((res) => {
			console.log('res from add to device', res);
		});

		return () => {
			deviceHub.invoke('RemoveFromDevice', { id: pebl.id }).then((res) => {
				console.log('res from remove from device', res);
			});
		};
	}, []);

	useEffect(() => {
		deviceHub.on('UartData', (evt) => addToConsole(false, evt.data));

		return () => {
			deviceHub.off('UartData');
			localStorage.setItem(
				`${pebl.id}-UART-DATA`,
				JSON.stringify(consoleLines)
			);
		};
	}, [consoleLines]);

	const addToConsole = (sent, data) => {
		let toAdd = { sent: sent, msg: String.fromCharCode.apply(null, data) };

		if (consoleLines.length > consoleRows) {
			setConsoleLines((cl) => {
				cl.shift();
				return [...cl, toAdd];
			});
		} else {
			setConsoleLines((cl) => [...cl, toAdd]);
		}
	};

	const handleSend = () => {
		setUserInputError(undefined);
		//Convert to int[]
		let ints = [];
		let chars = Array.from(userInput);
		chars.forEach((c) => ints.push(c.charCodeAt(0)));

		//Add CR && || LF if enabled
		crEnabled && ints.push(13);
		lfEnabled && ints.push(10);

		if(ints.length > 128) {
			setUserInputError('UART Data cannot exceed 128 characters');
			return;
		}

		//Send to device...
		rb.addRequest('uartData', 'W', { data: ints });
		rb.send()
			.then(() => {
				enqueueSnackbar(`UART data sent`, { variant: 'success' });
			})
			.catch((res) => errorHandler(res));

		//Add to console
		addToConsole(true, ints);
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			handleSend();
		}
	};

	const clearConsole = () => {
		setConsoleLines([]);
		localStorage.removeItem(`UART-DATA`);
	};

	const handleSetBusMode = () => {
		rb.addRequest('deviceBus', 'W', { protocol: 0 });
		rb.send()
			.then(() => {
				enqueueSnackbar(`Bus mode set to UART`, {
					variant: 'success',
				});
			})
			.catch((res) => errorHandler(res));
	};

	const enableConsole = pebl.uart.mode === 1 && pebl.device.bus.protocol === 0;

	return (
		<WidgetWrapper
			title='Console'
			displaySave={false}
			contentStyles={{ overflowX: 'hidden' }}
			icons={[
				<IconButton onClick={clearConsole} disabled={consoleLines.length === 0}>
					<DeleteOutlinedIcon />
				</IconButton>,
			]}>
			{pebl.device.bus.protocol === 0 ? (
				pebl.uart.mode === 1 ? (
					<Box
						padding={1}
						paddingY={2}
						flexGrow='1'
						display='flex'
						flexDirection='column'
						justifyContent='flex-end'
						maxHeight='100%'
						overflow='hidden'
						position='absoloute'>
						{consoleLines.length > 0
							? consoleLines.map((line, i) => (
									<PeblUARTConsoleLine lineData={line} key={i} />
							  ))
							: 'UART Data will display here'}
					</Box>
				) : (
					<Box
						display='flex'
						flexDirection='column'
						alignItems='center'
						justifyContent='center'
						width='100%'
						height='100%'>
						<InfoOutlinedIcon
							style={{ fontSize: 48, marginBottom: theme.spacing(2) }}
						/>
						<Typography varient='subtitle1'>
							UART Console is disabled while device is in interpreter mode
						</Typography>
					</Box>
				)
			) : (
				<Box
					display='flex'
					flexDirection='column'
					alignItems='center'
					justifyContent='center'
					width='100%'
					height='100%'>
					<InfoOutlinedIcon
						style={{ fontSize: 48, marginBottom: theme.spacing(2) }}
					/>
					<Typography varient='subtitle1'>
						Bus mode is set to i2c,{' '}
						<Link
							style={{
								color: theme.palette.secondary.main,
								textDecoration: 'none',
							}}
							onClick={handleSetBusMode}>
							click here
						</Link>{' '}
						to change that
					</Typography>
				</Box>
			)}
			<Box
				display='flex'
				alignItems='center'
				justifyContent='space-between'
				marginBottom={2}>
				<TextField
					variant='outlined'
					value={userInput}
					onChange={(e) => setUserInput(e.target.value)}
					onKeyDown={handleKeyDown}
					placeholder='Hello World!'
					error={userInputError}
					helperText={userInputError}
					className={classes.userInput}
					disabled={!enableConsole}
					inputProps={{
						style: { fontFamily: "'Courier New', monospace" },
					}}
				/>
				<Box
					height='100%'
					display='flex'
					flexDirection='column'
					justifyContent='space-around'>
					<FormControlLabel
						className={classes.checkboxLabel}
						control={
							<Checkbox
								className={classes.checkbox}
								color='secondary'
								checked={crEnabled}
								onChange={() => setCREnabled(!crEnabled)}
								disabled={!enableConsole}
							/>
						}
						label='CR'
					/>
					<FormControlLabel
						className={classes.checkboxLabel}
						control={
							<Checkbox
								className={classes.checkbox}
								color='secondary'
								checked={lfEnabled}
								onChange={() => setLFEnabled(!lfEnabled)}
								disabled={!enableConsole}
							/>
						}
						label='LF'
					/>
				</Box>
				<FingotiButton
					light
					color='primary'
					onClick={handleSend}
					disabled={Boolean(userInput === '')}>
					Send
				</FingotiButton>
			</Box>
		</WidgetWrapper>
	);
};
