export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if ([401].indexOf(response.status) !== -1) {
                // refresh the app token
                return Promise.reject({ retry: true });
            } else if ([403].indexOf(response.status) !== -1) {
                // Notify user of no permssion 403
                return Promise.reject({ retry: false, error: "You are not authorised to perform this action" });
            } else {
                const error = data.message ? data.message : "An error occurred";
                return Promise.reject({ retry: false, error: error });
            }
        } else if (!data.success) {
            let error = data.message ? data.message : "An error occurred";

            //If repsonse has come from device command, pull out the data and reject with it
            if(data.response && Array.isArray(data.response)) {
                error = data.response;
            }

            return Promise.reject({ retry: false, error: error });
        } else {
            return Promise.resolve(data);
        }
    })
    .catch(error => {
        return Promise.reject(error);
    });
}

export const handleAppTokenResponse = (response) => {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                //Redirect user to account to login
                return Promise.reject({ requireAuth: true, error: "Failed to get app token"});
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject({ requireAuth: false, error: error});
        } else if(!data.success) {
            return Promise.reject({ requireAuth: true, error: "failed to get app token"});
        } else {
            return data;
        }
    })
}

export const handleLoginResponse = (response) => {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        } else {
            return data;
        }
    })
}