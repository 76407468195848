import React from "react";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

export const InsufficientData = () => {
  return (
    <Box
      height={300}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <InfoOutlinedIcon style={{ fontSize: 48 }} />
      <Typography varient="subtitle1">
        Not enough data to draw graphs
      </Typography>
    </Box>
  );
};
