import React from 'react';
import { MQTTUARTSession } from '../../shared/widgets/MQTTUARTSession';

import { WidgetGrid } from '../../WidgetGrid';
import { PeblUARTConsole } from './widgets/PeblUARTConsole';
import { PeblUARTMode } from './widgets/PeblUARTMode';
import { PeblUARTSetup } from './widgets/PeblUARTSetup';
import { PeblUARTTrigger } from './widgets/PeblUARTTrigger';

const gridLayout = {
	xl: [
		{ i: '1', x: 0, y: 0, w: 3, h: 4 },
		{ i: '2', x: 3, y: 0, w: 6, h: 14 },
		{ i: '3', x: 0, y: 8, w: 3, h: 6 },
		{ i: '4', x: 0, y: 4, w: 3, h: 4 },
		{ i: '5', x: 0, y: 14, w: 3, h: 6 },
	],
	lg: [
		{ i: '1', x: 0, y: 0, w: 3, h: 4 },
		{ i: '2', x: 3, y: 0, w: 6, h: 14 },
		{ i: '3', x: 0, y: 4, w: 3, h: 6 },
		{ i: '4', x: 0, y: 4, w: 3, h: 4 },
		{ i: '5', x: 0, y: 14, w: 3, h: 6 },
	],
	md: [
		{ i: '1', x: 0, y: 0, w: 3, h: 4 },
		{ i: '2', x: 0, y: 8, w: 6, h: 14 },
		{ i: '3', x: 0, y: 20, w: 3, h: 6 },
		{ i: '4', x: 3, y: 0, w: 3, h: 4 },
		{ i: '5', x: 3, y: 20, w: 3, h: 6 },
	],
	sm: [
		{ i: '1', x: 0, y: 0, w: 6, h: 4 },
		{ i: '2', x: 0, y: 10, w: 6, h: 14 },
		{ i: '3', x: 0, y: 22, w: 6, h: 10 },
		{ i: '4', x: 0, y: 4, w: 6, h: 6 },
		{ i: '5', x: 0, y: 32, w: 6, h: 6 },
	],
};

export const PeblUART = ({ pebl }) => {
	return (
		<WidgetGrid layouts={gridLayout}>
			<div key='1'>
				<PeblUARTMode pebl={pebl} />
			</div>
			<div key='2'>
				<PeblUARTConsole pebl={pebl} />
			</div>
			<div key='3'>
				<PeblUARTSetup pebl={pebl} />
			</div>
			<div key='4'>
				<PeblUARTTrigger pebl={pebl} />
			</div>
			<div key='5'>
				<MQTTUARTSession session={pebl.uart.session} pebl={pebl} UART />
			</div>
		</WidgetGrid>
	);
};
