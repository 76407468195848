import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { WidgetWrapper } from '../../../WidgetWrapper';

import { RequestBuilder } from '../../../../../services/request.service';
import useDeviceErrorHandler from '../../../useDeviceErrorHandler';

const terminatorArrayToString = (array) => {
	let str = '';

	array.forEach((e, i) => {
		str = str.concat(i === array.length - 1 ? `${e}` : `${e}, `);
	});

	return str;
};

const terminatorStringToArray = (string) => {
	let arr = [];
	string.split(',').forEach((e) => arr.push(parseInt(e.trim())));
	return arr;
};

export const PeblUARTTrigger = ({ pebl }) => {
	const errorHandler = useDeviceErrorHandler();
	const { enqueueSnackbar } = useSnackbar();
	const [saveEnabled, setSaveEnabled] = useState(false);
	const [length, setLength] = useState(pebl.uart.trigger.length);
	const [terminator, setTerminator] = useState(
		terminatorArrayToString(pebl.uart.trigger.terminator)
	);
	const [errors, setErrors] = useState({});
	const terminatiorRegex = new RegExp(
		'^([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-5]{2})(,([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-5]{2}))*$'
	);
	const rb = new RequestBuilder(pebl.id);

	useEffect(() => {
		if (!saveEnabled) {
			setLength(pebl.uart.trigger.length);
			setTerminator(terminatorArrayToString(pebl.uart.trigger.terminator));
		}
	}, [pebl]);

	const enableSave = () => {
		if (saveEnabled) {
			return;
		}

		setSaveEnabled(true);
	};

	const handleSectionSave = () => {
		rb.addRequest('uartTrigger', 'W', {
			length: parseInt(length),
			terminator: terminatorStringToArray(terminator),
		});
		rb.send()
			.then(() => {
				enqueueSnackbar(`UART trigger set successfully`, {
					variant: 'success',
				});
			})
			.catch((res) => errorHandler(res));
		setSaveEnabled(false);
	};

	const validateTerminator = (event) => {
		if (!terminatiorRegex.test(event.target.value.replace(/\s/g, ''))) {
			setErrors((prevErrors) => ({
				...prevErrors,
				[event.target.name]: 'invalid terminator',
			}));
		} else {
			setErrors((prevErrors) => {
				delete prevErrors[event.target.name];
				return prevErrors;
			});
		}
	};

	return (
		<WidgetWrapper
			title='Trigger'
			saveEnabled={Object.keys(errors).length !== 0 ? false : saveEnabled}
			onSave={handleSectionSave}>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6}>
					<TextField
						fullWidth
						type='number'
						label='length'
						inputProps={{
							minValue: 0,
							maxValue: 512,
						}}
						value={length}
						onChange={(e) => {
							if(parseInt(e.target.value) > 512) {
								setLength("512");
							} else if (parseInt(e.target.value) < 0) {
								setLength("0");
							} else {
								setLength(e.target.value);
							}
							enableSave();
						}}
						variant='outlined'
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						variant='outlined'
						value={terminator}
						label='terminator'
						name='terminator'
						onChange={(e) => {
							setTerminator(e.target.value);
							validateTerminator(e);
							enableSave();
						}}
						error={Boolean(errors['terminator'])}
						helperText={errors['terminator'] ? errors['terminator'] : ''}
						placeholder='10, 13'
					/>
				</Grid>
			</Grid>
		</WidgetWrapper>
	);
};
