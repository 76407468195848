import React from 'react';

import Typography from '@material-ui/core/Typography';

import { WidgetWrapper } from '../../WidgetWrapper';

export const MACAddresses = ({ networkMac }) => {
	return (
		<WidgetWrapper title='MAC Address' displaySave={false}>
			<Typography>station</Typography>
			<Typography variant='h4'>{networkMac.station.toUpperCase()}</Typography>
			<Typography>ap</Typography>
			<Typography variant='h4'>{networkMac.ap.toUpperCase()}</Typography>
			<Typography>bluetooth</Typography>
			<Typography variant='h4'>{networkMac.bluetooth.toUpperCase()}</Typography>
		</WidgetWrapper>
	);
};
