import React, { useState } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';

import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

import { WidgetWrapper } from '../../WidgetWrapper';
import { IconButton } from '@material-ui/core';

//All this pins stuff is a bit confusing...
//
// Direction:
//  0 - false - out
//  1 - true - in
//
// State:
//  0 - false - off
//  1 - true - on

const useStyles = makeStyles((theme) => ({
	table: {
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	headerCell: {
		border: 'none',
		textAlign: 'right',
		borderRight: '1px solid ' + theme.palette.greyFour.main,
		width: '20%',
		color: theme.palette.greyTwo.main,
	},
	noBottomBorder: {
		border: 'none',
		textAlign: 'center',
	},
	roleButtonGroup: {
		'&:not(:last-child)': {
			borderRight: '1px solid #ccc',
		},
	},
	textRoleButton: {
		padding: '6px 16px',
	},
}));

export const GPIOState = ({ gpio, setGPIO, eventHandlers }) => {
	const classes = useStyles();
	const [sendEnabled, setSendEnabled] = useState(false);

	const enableSend = () => {
		if (sendEnabled) {
			return;
		}

		setSendEnabled(true);
	};

	const handleSend = () => {
		eventHandlers.sectionSend(gpio);
		setSendEnabled(false);
	};

	const handleSave = () => {
		eventHandlers.sectionSave();
	};

	const GPIOStateRow = ({ gpioNo }) => {
		return (
			<TableRow key={`${gpioNo}`}>
				<TableCell className={classes.headerCell}>{gpioNo + 1}</TableCell>
				<TableCell className={classes.noBottomBorder}>
					<Grid component='label' container alignItems='center' spacing={1}>
						<Grid item xs={2} /> {/* Spacing */}
						<Grid item>off</Grid>
						<Grid item>
							<Switch
								checked={Boolean(gpio[gpioNo].state)}
								disabled={gpio[gpioNo].direction === 0 ? false : true}
								onChange={() => {
									setGPIO({
										action: 'CHNG_STATE',
										payload: { gpio: gpioNo, value: !gpio[gpioNo].state },
									});
									enableSend();
								}}
								color='primary'
							/>
						</Grid>
						<Grid item>on</Grid>
					</Grid>
				</TableCell>
			</TableRow>
		);
	};

	const icons = [
		<IconButton onClick={handleSave}>
			<SaveOutlinedIcon />
		</IconButton>,
	];

	return (
		<WidgetWrapper
			title='State'
			icons={icons}
			saveEnabled={sendEnabled}
			onSave={handleSend}>
			<TableContainer className={classes.table}>
				<Table size='small'>
					<TableBody>
						{gpio.map((pin, i) => {
							return <GPIOStateRow key={`${i}`} gpioNo={i} />;
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</WidgetWrapper>
	);
};
