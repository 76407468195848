import React, { useEffect, useReducer, useState } from 'react';
import { useSnackbar } from 'notistack';

import { WidgetGrid } from '../../WidgetGrid';
import { GPIO } from '../../shared/widgets/GPIO';
import { GPIODirection } from '../../shared/widgets/GPIODirection';
import { GPIOState } from '../../shared/widgets/GPIOState';
import useDeviceErrorHandler from '../../useDeviceErrorHandler';
import { GPIOPulse } from '../../shared/widgets/GPIOPulse';

const gridLayout = {
	xl: [
		{ i: '1', x: 0, y: 0, w: 2, h: 4 },
		{ i: '2', x: 2, y: 0, w: 3, h: 8 },
		{ i: '3', x: 5, y: 0, w: 3, h: 8 },
		{ i: '4', x: 0, y: 4, w: 2, h: 4 },
	],
	lg: [
		{ i: '1', x: 0, y: 0, w: 2, h: 4 },
		{ i: '2', x: 2, y: 0, w: 3, h: 8 },
		{ i: '3', x: 5, y: 0, w: 3, h: 8 },
		{ i: '4', x: 0, y: 4, w: 2, h: 4 },
	],
	md: [
		{ i: '1', x: 0, y: 0, w: 3, h: 4 },
		{ i: '2', x: 3, y: 0, w: 3, h: 8 },
		{ i: '3', x: 0, y: 4, w: 3, h: 8 },
		{ i: '4', x: 3, y: 8, w: 3, h: 4 },
	],
	sm: [
		{ i: '1', x: 0, y: 0, w: 4, h: 4 },
		{ i: '2', x: 0, y: 4, w: 4, h: 8 },
		{ i: '3', x: 0, y: 12, w: 4, h: 8 },
		{ i: '4', x: 0, y: 20, w: 4, h: 4 },
	],
};

//All this pins stuff is a bit confusing...
//
// Direction:
//  0 - false - out
//  1 - true - in
//
// State:
//  0 - false - off
//  1 - true - on

const gpioReducer = (state, action) => {
	switch (action.action) {
		case 'CHNG_DIR':
			if (action.payload.value && state[action.payload.gpio].state) {
				state[action.payload.gpio].state = false;
			}
			state[action.payload.gpio].direction = action.payload.value;
			return [...state];
		case 'CHNG_STATE':
			state[action.payload.gpio].state = action.payload.value;
			return [...state];
		case 'INIT':
			let oldFormat = [];
			for (let i = 0; i < action.payload.direction.length; i++) {
				let gpio = {
					direction: action.payload.direction[i],
					state: action.payload.state[i],
				};

				oldFormat.push(gpio);
			}

			return oldFormat;
		default:
			throw new Error('Invalid action passed to gpioReducer');
	}
};

export const PeblGPIO = ({ request, pebl }) => {
	const errorHandler = useDeviceErrorHandler();
	const [gpio, _setGPIO] = useReducer(gpioReducer, []);
	const [edited, setEdited] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (!edited) {
			let gpioCopy = JSON.parse(JSON.stringify(pebl.gpio));
			_setGPIO({ action: 'INIT', payload: gpioCopy });
		}
	}, [pebl.gpio]);

	const setGPIO = (args) => {
		setEdited(true);
		_setGPIO(args);
	};

	const handleGPIODirSend = (gpio) => {
		let allGPIODir = {
			direction: [
				gpio[0].direction ? 1 : 0,
				gpio[1].direction ? 1 : 0,
				gpio[2].direction ? 1 : 0,
				gpio[3].direction ? 1 : 0,
			],
		};

		request.addRequest('gpioDirection', 'W', allGPIODir);
		request.addRequest('gpioState', 'R');
		request
			.sendSingleSection(['gpioDirection', 'gpioState'])
			.then(() => {
				enqueueSnackbar('GPIO direction successfully changed', {
					variant: 'success',
				});
				setEdited(false);
			})
			.catch((res) => errorHandler(res));
	};

	const handleGPIOStateSend = (gpio) => {
		let allGPIOState = {
			state: [
				gpio[0].state ? (gpio[0].direction ? 0 : 1) : 0,
				gpio[1].state ? (gpio[1].direction ? 0 : 1) : 0,
				gpio[2].state ? (gpio[2].direction ? 0 : 1) : 0,
				gpio[3].state ? (gpio[3].direction ? 0 : 1) : 0,
			],
		};

		request.addRequest('gpioState', 'W', allGPIOState);
		request
			.sendSingleSection(['gpioState'])
			.then(() => {
				enqueueSnackbar('GPIO state successfully changed', {
					variant: 'success',
				});
				setEdited(false);
			})
			.catch((res) => errorHandler(res));
	};

	const handleGPIOStateSave = () => {
		request.addRequest('gpioState', 'W', { save: true });
		request
		.send()
		.then(() => {
			enqueueSnackbar('GPIO state is now persistant', {
				variant: 'success',
			});
			setEdited(false);
		})
		.catch((res) => errorHandler(res));
	};

	const handleGPIODirSave = () => {
		request.addRequest('gpioDirection', 'W', { save: true });
		request
			.send()
			.then(() => {
				enqueueSnackbar('GPIO direction is now persistant', {
					variant: 'success',
				});
				setEdited(false);
			})
			.catch((res) => errorHandler(res));
	};

	return (
		<WidgetGrid layouts={gridLayout}>
			<div key='1'>
				<GPIO gpio={pebl.gpio} />
			</div>
			<div key='2'>
				<GPIODirection
					gpio={gpio}
					setGPIO={setGPIO}
					eventHandlers={{
						sectionSend: handleGPIODirSend,
						sectionSave: handleGPIODirSave
					}}
				/>
			</div>
			<div key='3'>
				<GPIOState
					gpio={gpio}
					setGPIO={setGPIO}
					eventHandlers={{
						sectionSend: handleGPIOStateSend,
						sectionSave: handleGPIOStateSave,
					}}
				/>
			</div>
			<div key='4'>
				<GPIOPulse pebl={pebl} />
			</div>
		</WidgetGrid>
	);
};
