import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { FingotiActionButton } from '@fingoti/components';
import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { WidgetWrapper } from '../../../WidgetWrapper';
import { RequestBuilder } from '../../../../../services/request.service';

const useStyles = makeStyles(theme => ({

    actions: {
        margin: '0px auto 0px auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    buttonWrapper: {
        '& > :hover': {
            cursor: 'pointer',
        }
    },
    action: {
        backgroundColor: theme.palette.greyFour.light,
        textAlign: 'center',
        color: theme.palette.greyTwo.main,
        padding: theme.spacing(1) + theme.spacing(1) / 2,
        margin: '0px ' + theme.spacing(2) + 'px 0px ' + theme.spacing(2) + 'px',
        borderRadius: theme.spacing(1),
    },
    activeAction: {
        color: theme.palette.primary.main,
    },
    actionIcon: {
        fontSize: '3rem',
    },
    address: {
        width: '50%',
    }

}))

export const VyneAddress = ({ node }) => {

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [nodeAddress, setNodeAddress] = useState(node.address.value);
    const [nodeStatic, setNodeStatic] = useState(node.address.static);
    const rb = new RequestBuilder(node.gatewayId);

    const enableSave = () => {
        if (saveEnabled) {
            return;
        }

        setSaveEnabled(true);
    }

    const toggleStatic = () => {
        setNodeStatic(!nodeStatic);
        enableSave();
    }

    const handleSetAddress = () => {
        rb.addRequest("nodeAddress", "W", {
            serial: node.id,
            static: nodeStatic,
            address: parseInt(nodeAddress)
        });

        rb.sendSingleSection(["nodeAddress"])
            .then(() => {
                enqueueSnackbar("Node address set succesfully", { variant: 'success' });
            })
            .catch(errors => {
                if (errors && Array.isArray(errors)) {
                    errors.forEach(error => {
                        enqueueSnackbar(`Error ${error.result.code} - ${error.result.message}`, { variant: 'error' });
                    })
                } else {
                    enqueueSnackbar(errors, { variant: 'error' });
                }
            });

        setSaveEnabled(false);
    }

    return (
        <WidgetWrapper title="Address" saveEnabled={saveEnabled} onSave={handleSetAddress}>
            <div className={classes.actions}>
                <TextField
                    id="nodeAddress"
                    type="number"
                    value={nodeAddress}
                    onChange={(e) => {
                        setNodeAddress(e.target.value);
                        enableSave();
                    }}
                    variant="outlined"
                    className={classes.address}
                />
                <FingotiActionButton
                    Icon={LockOutlinedIcon}
                    label="Static"
                    active={nodeStatic}
                    onClick={toggleStatic}
                />
            </div>
        </WidgetWrapper >
    );

}