const envVars = process.env;

export let apiUrl = `${envVars.REACT_APP_RESOURCE_API_URL}/${envVars.REACT_APP_RESOURCE_API_VERSION}`;
export let authUrl = `${envVars.REACT_APP_AUTH_API_URL}/${envVars.REACT_APP_AUTH_API_VERSION}`;
export let cdnUrl = envVars.REACT_APP_CDN_URL;
export let webUrl = envVars.REACT_APP_WEB_URL;
export let portalUrl = envVars.REACT_APP_PORTAL_URL;
export let accountUrl = envVars.REACT_APP_ACCOUNT_URL;
export let developerUrl = envVars.REACT_APP_DEVELOPER_URL;
export let storeUrl = envVars.REACT_APP_STORE_URL;
export let cookieSettings;

let envString = envVars.REACT_APP_ENVIROMENT_STRING;

console.log("RUNNING ENVIROMENT", envString);

const getExpiry = () => {
    var expiry = new Date();
    expiry.setDate(expiry.getDate() + 7);
    return expiry;
}

if(envString === "LOCAL") {
    cookieSettings = () => { return ({ path: '/', expires: getExpiry() }) };
} else if (envString === "DEV") {
    cookieSettings = () => { return ({ domain: '.fingoti.net', path: '/', secure: true, sameSite: 'strict', expires: getExpiry() }) };
} else {
    cookieSettings = () => { return ({ domain: '.fingoti.com', path: '/', secure: true, sameSite: 'strict', expires: getExpiry() }) };
}

// const isDev = process.env.REACT_APP_DEVELOPMENT === "true" ? true : false;

// const getExpiry = () => {
//     var expiry = new Date();
//     expiry.setDate(expiry.getDate() + 7);
//     return expiry;
// }

// if(isDev) {
//     apiUrl = "https://localhost:5001/v1";
//     apiUrl = "https://api.dev.fingoti.net/v1";
//     authUrl = "https://auth.dev.fingoti.net/v1";
//     cdnUrl = "https://cdn.fingoti.com";
//     webUrl = "https://www.fingoti.com";
//     portalUrl = "http://192.168.77.102:3011";
//     accountUrl = "http://192.168.77.102:3010"
//     developerUrl = "http://192.168.77.102:3013";;
//     storeUrl = "http://192.168.77.102:3012";
//     cookieSettings = () => { return ({ path: '/', expires: getExpiry() }) };
// } else {
//     apiUrl = "https://api.dev.fingoti.net/v1";
//     authUrl = "https://auth.dev.fingoti.net/v1";
//     cdnUrl = "https://cdn.fingoti.com";
//     webUrl = "https://www.fingoti.com";
//     portalUrl = "https://portal.dev.fingoti.net"
//     accountUrl = "https://account.dev.fingoti.net";
//     developerUrl = "https://developer.dev.fingoti.net";
//     storeUrl = "https://store.dev.fingoti.net";
//     cookieSettings = () => { return ({ domain: '.fingoti.net', path: '/', expires: getExpiry() }) };
// }
