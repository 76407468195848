import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import { WidgetWrapper } from '../../../WidgetWrapper';
import { RequestBuilder } from '../../../../../services/request.service';
import useDeviceErrorHandler from '../../../useDeviceErrorHandler';

const useStyles = makeStyles((theme) => ({
	checkbox: {
		padding: 0,
		marginRight: theme.spacing(1),
	},
	checkboxLabel: {
		marginLeft: 0,
		[theme.breakpoints.up('sm')]: {
			marginLeft: 'unset',
			marginRight: theme.spacing(3),
		},
	},
}));

export const PeblMQTTSetup = ({ pebl }) => {
	const classes = useStyles();
	const errorHandler = useDeviceErrorHandler();
	const { enqueueSnackbar } = useSnackbar();
	const [saveEnabled, setSaveEnabled] = useState(false);
	const [host, setHost] = useState(pebl.mqtt.setup.host);
	const [port, setPort] = useState(pebl.mqtt.setup.port);
	const [qos, setQos] = useState(pebl.mqtt.setup.qos);
	const [retain, setRetain] = useState(pebl.mqtt.setup.retain);
	const [secure, setSecure] = useState(pebl.mqtt.setup.secure);
	const rb = new RequestBuilder(pebl.id);

	useEffect(() => {
		if (!saveEnabled) {
			setHost(pebl.mqtt.setup.host);
			setPort(pebl.mqtt.setup.port);
			setQos(pebl.mqtt.setup.qos);
			setRetain(pebl.mqtt.setup.retain);
			setSecure(pebl.mqtt.setup.secure);
		}
	}, [pebl]);

	const enableSave = () => {
		if (saveEnabled) {
			return;
		}

		setSaveEnabled(true);
	};

	const handleSave = () => {
		setSaveEnabled(false);
		rb.addRequest('mqttSetup', 'W', {
			host: host,
			port: parseInt(port),
			qos: parseInt(qos),
			retain: retain,
			secure: secure,
		});
		rb.send()
			.then(() => {
				enqueueSnackbar('MQTT Setup saved successfully', {
					variant: 'success',
				});
			})
			.catch((res) => errorHandler(res))
			.finally(() => setSaveEnabled(true));
	};

	return (
		<>
			<WidgetWrapper
				title='Setup'
				displaySave={true}
				saveEnabled={saveEnabled}
				onSave={handleSave}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<TextField
							fullWidth
							variant='outlined'
							label='host'
							value={host}
							onChange={(e) => {
								setHost(e.target.value);
								enableSave();
							}}
							placeholder='mqtt.mydomain.com'
						/>
					</Grid>
					<Grid item xs={6} sm={4}>
						<TextField
							type='number'
							variant='outlined'
							label='port'
							value={port}
							onChange={(e) => {
								setPort(e.target.value);
								enableSave();
							}}
						/>
					</Grid>
					<Grid item xs={6} sm={4}>
						<FormControl fullWidth variant='outlined'>
							<InputLabel id='qos-label'>qos</InputLabel>
							<Select
								variant='outlined'
								label='qos'
								labelId='qos-label'
								value={qos}
								onChange={(e) => {
									setQos(e.target.value);
									enableSave();
								}}>
								<MenuItem key='0' value={0}>
									0
								</MenuItem>
								<MenuItem key='1' value={1}>
									1
								</MenuItem>
								<MenuItem key='2' value={2}>
									2
								</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Box
							height='100%'
							display='flex'
							flexDirection='column'
							justifyContent='space-around'>
							<FormControlLabel
								className={classes.checkboxLabel}
								control={
									<Checkbox
										className={classes.checkbox}
										color='secondary'
										checked={retain}
										onChange={() => {
											setRetain(!retain);
											enableSave();
										}}
									/>
								}
								label='Retain'
							/>
							<FormControlLabel
								className={classes.checkboxLabel}
								control={
									<Checkbox
										className={classes.checkbox}
										color='secondary'
										checked={secure}
										onChange={() => {
											setSecure(!secure);
											enableSave();
										}}
									/>
								}
								label='Secure'
							/>
						</Box>
					</Grid>
				</Grid>
			</WidgetWrapper>
		</>
	);
};
