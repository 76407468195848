import React from 'react';

import { WidgetWrapper } from '../../../WidgetWrapper';

import { Typography } from '@material-ui/core';

import { datetimeService } from '../../../../../services/datetime.service';

export const VyneDataTimestamp = ({ data }) => {

    return (
        <WidgetWrapper title="Timestamp" displaySave={false}>
            <Typography variant="h4">
                { datetimeService.formatDateTime(new Date(data)) }
            </Typography>
        </WidgetWrapper>
    );

}