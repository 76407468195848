import React, { useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
	FingotiHeader,
	FingotiDelete,
	FingotiModal,
	FingotiButton,
	FingotiMarkdown,
	FingotiNoPermission,
	URLGenerator,
	FingotiTable,
} from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import SyncIcon from '@material-ui/icons/Sync';

import { FollowingAdd } from './FollowingAdd';
import { FollowingEdit } from './FollowingEdit';

import { apiService } from '../../../services/api.service';
import { useRoleCheck } from '../../utils/RoleCheck';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(6),
		width: '100%',
	},
	tableHead: {
		color: theme.palette.primary.main,
	},
	noItemsRow: {
		textAlign: 'center',
		height: theme.spacing(32),
		borderBottom: 'none',
	},
	statusIcon: {
		verticalAlign: 'middle',
	},
	actionIcons: {
		display: 'flex',
		alignItems: 'center',
	},
	actionIcon: {
		padding: 0,
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
	},
	pins: {
		'display': 'flex',
		'justifyContent': 'center',
		'alignItems': 'center',
		'& :not(:first-child)': {
			marginLeft: theme.spacing(1) / 2,
		},
	},
	pin: {
		color: theme.palette.secondary.main,
	},
	serial: {
		color: theme.palette.greyTwo.main,
	},
}));

export const Following = () => {
	const classes = useStyles();
	const theme = useTheme();
	const { enqueueSnackbar } = useSnackbar();
	const { roleCheck } = useRoleCheck();
	const [addOpen, setAddOpen] = useState(false);
	// const [infoOpen, setInfoOpen] = useState(false);
	// const [infoConfig, setInfoConfig] = useState({});
	const [editOpen, setEditOpen] = useState(false);
	const [editFollowing, setEditFollowing] = useState({});
	const tableRef = useRef();

	const breadcrumbs = [
		{ text: 'Settings', link: '/settings' },
		{ text: 'Following' },
	];

	const actionButtons = () => {
		return (
			<FingotiButton
				light
				disabled={roleCheck('follow', 2)}
				color='primary'
				onClick={() => setAddOpen(true)}>
				add following
			</FingotiButton>
		);
	};

	const handleAddSuccess = () => {
		setAddOpen(false);
		tableRef.current.onQueryChange();
	};

	// const handleInfoOpen = (following) => {
	// 	setInfoConfig(following);
	// 	setInfoOpen(true);
	// };

	const handleEditOpen = (following) => {
		setEditFollowing(following);
		setEditOpen(true);
	};

	const handleEditSuccess = () => {
		setEditOpen(false);
		tableRef.current.onQueryChange();
	};

	const deleteFollowing = (followId) => {
		apiService
			.deleteData(`/followings/${followId}`)
			.then((result) => {
				enqueueSnackbar(result.message, { variant: 'success' });
				tableRef.current.onQueryChange();
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
			});
	};

	const getFollowings = (query) => {
		return apiService
			.getData(URLGenerator(query, '/followings'))
			.then((data) => {
				return {
					data: data.followings,
					page: data.pageNumber - 1,
					totalCount: data.count,
				};
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
			});
	};

	const enabledRenderer = (rd) => {
		return rd.disabled ? (
			<BlockOutlinedIcon
				style={{ fill: theme.palette.error.main }}
				className={classes.statusIcon}
			/>
		) : (
			<CheckCircleOutlineIcon
				style={{ fill: theme.palette.success.main }}
				className={classes.statusIcon}
			/>
		);
	};

	const sourceRenderer = (rd) => {
		return (
			<>
				<span className={classes.serial}>{`${rd.sourceDevice}`}</span>
				<span className={classes.pin}>{`:${rd.sourcePin}`}</span>
			</>
		);
	};

	const destRenderer = (rd) => {
		return (
			<>
				<span className={classes.serial}>{`${rd.destinationDevice}`}</span>
				<span className={classes.pin}>{`:${rd.destinationPin}`}</span>
			</>
		);
	};

	const typeRenderer = (rd) => {
		return rd.mirror ? (
			<SyncIcon className={classes.statusIcon} />
		) : (
			<SwapHorizIcon className={classes.statusIcon} />
		);
	};

	if (roleCheck('follow', 1)) {
		return <FingotiNoPermission />;
	} else {
		return (
			<>
				<FingotiModal
					title='Create Following'
					open={addOpen}
					setOpen={setAddOpen}>
					<FollowingAdd setOpen={setAddOpen} onSuccess={handleAddSuccess} />
				</FingotiModal>
				{/* <FingotiModal
                    title="Preset Information"
                    open={infoOpen}
                    setOpen={setInfoOpen}
                >
                    <ConfigInfo setOpen={setInfoOpen} config={infoConfig} />
                </FingotiModal> */}
				<FingotiModal
					title='Edit Following'
					open={editOpen}
					setOpen={setEditOpen}>
					<FollowingEdit
						setOpen={setEditOpen}
						following={editFollowing}
						onSuccess={handleEditSuccess}
					/>
				</FingotiModal>
				<FingotiHeader
					breadcrumbs={breadcrumbs}
					sectionIcon={SettingsOutlinedIcon}
					actionButtons={actionButtons()}
				/>
				<FingotiMarkdown path='/documentation/portal/settings/pinfollowing.md' />
				<FingotiTable
					data={getFollowings}
					tableRef={tableRef}
					columns={[
						{ title: 'id', field: 'id', hidden: true },
						{ title: 'following name', field: 'followName' },
						{ title: 'enabled', render: enabledRenderer },
						{
							title: 'source',
							field: 'source',
							render: sourceRenderer,
						},
						{
							title: 'destination',
							field: 'destination',
							render: destRenderer,
						},
						{ title: 'type', render: typeRenderer },
					]}
					actions={[
						(rowData) => ({
							icon: EditOutlinedIcon,
							tooltip: 'edit following',
							onClick: () => handleEditOpen(rowData),
						}),
						(rowData) => ({
							icon: () => (
								<FingotiDelete
									className={classes.actionIcon}
									disabled={roleCheck('follow', 2)}
									tooltipText='delete following'
									onClick={() => deleteFollowing(rowData.id)}
								/>
							),
						}),
					]}
				/>
			</>
		);
	}
};
