import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	widgetWrapper: {
		border: '1px solid' + theme.palette.greyFour.main,
		borderRadius: theme.spacing(1),
		height: '100%',
		background: '#FFF',
		display: 'flex',
		flexDirection: 'column',
	},
	widgetTitle: {
		minHeight: '56px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingLeft: theme.spacing(3),
		borderBottom: '1px solid' + theme.palette.greyFour.main,
		borderRadius: theme.spacing(1) + 'px ' + theme.spacing(1) + 'px 0px 0px',
		backgroundColor: theme.palette.greyFour.main,
	},
	widgetContent: {
		boxSizing: 'border-box',
		padding: theme.spacing(1) + 'px ' + theme.spacing(2) + 'px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
		flexGrow: 1,
	},
	widgetTitleIcons: {
		'& button:not(:last-child)': {
			marginRight: '-12px',
		},
	},
}));

export const WidgetWrapper = ({
	gridKey,
	title,
	saveEnabled,
	displaySave,
	onSave,
	children,
	icons = [],
	contentStyles,
}) => {
	const classes = useStyles();

	return (
		<div key={gridKey} className={classes.widgetWrapper}>
			<div className={classes.widgetTitle}>
				<Typography variant='h6'>{title}</Typography>
				<div className={classes.widgetTitleIcons}>
					{icons.length > 0 ? icons.map((i) => i) : ''}
					{displaySave ? (
						<IconButton disabled={!saveEnabled} onClick={onSave}>
							<SendOutlinedIcon />
						</IconButton>
					) : (
						''
					)}
				</div>
			</div>
			<div className={classes.widgetContent} style={contentStyles}>
				{children}
			</div>
		</div>
	);
};

WidgetWrapper.defaultProps = {
	displaySave: true,
};
