import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { FingotiActionButton } from '@fingoti/components';

import Grid from '@material-ui/core/Grid';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

import { RequestBuilder } from '../../../../services/request.service';
import { WidgetWrapper } from '../../WidgetWrapper';
import useDeviceErrorHandler from '../../useDeviceErrorHandler';

export const ScheduleActions = ({ device, slot }) => {
	const errorHandler = useDeviceErrorHandler();
	const { enqueueSnackbar } = useSnackbar();
	const [enableLoading, setEnableLoading] = useState(false);
	const [clearLoading, setClearLoading] = useState(false);
	const rb = new RequestBuilder(device.id);

	const handleEnableToggle = () => {
		setEnableLoading(true);
		rb.addRequest('scheduleStatus', 'W', {
			slot: slot,
			enabled: !device.schedule.setup[slot].status.enabled,
		});
		rb.send()
			.then(() => {
				enqueueSnackbar(
					`Schedule ${
						device.schedule.setup[slot].status.enabled ? 'disabled' : 'enabled'
					} successfully`,
					{ variant: 'success' }
				);
				setEnableLoading(false);
			})
			.catch((res) => errorHandler(res));
	};

	const handleClear = () => {
		setClearLoading(true);
		rb.addRequest('scheduleSetup', 'W', { slot: slot, clear: true });

		rb.send()
			.then(() => {
				enqueueSnackbar(`Schedule cleared`, { variant: 'success' });
				setClearLoading(false);
			})
			.catch((res) => errorHandler(res));
	};

	return (
		<WidgetWrapper title='Actions' displaySave={false}>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<FingotiActionButton
						Icon={CheckCircleOutlineIcon}
						label='Enable'
						onClick={handleEnableToggle}
						active={device.schedule.setup[slot].status.enabled}
						loading={enableLoading}
					/>
				</Grid>
				<Grid item xs={6}>
					<FingotiActionButton
						Icon={RotateLeftIcon}
						label='Clear'
						onClick={handleClear}
						loading={clearLoading}
					/>
				</Grid>
			</Grid>
		</WidgetWrapper>
	);
};
