import React from 'react';
import { MQTTUARTSession } from '../../shared/widgets/MQTTUARTSession';

import { WidgetGrid } from '../../WidgetGrid';
import { PeblMQTTActions } from './widgets/PeblMQTTActions';
import { PeblMQTTCredentials } from './widgets/PeblMQTTCredentials';
import { PeblMQTTSetup } from './widgets/PeblMQTTSetup';

const gridLayout = {
	xl: [
		{ i: '1', x: 3, y: 0, w: 4, h: 6 },
		{ i: '2', x: 0, y: 6, w: 3, h: 5 },
		{ i: '3', x: 0, y: 0, w: 3, h: 4 },
		{ i: '4', x: 0, y: 11, w: 3, h: 6 },
	],
	lg: [
		{ i: '1', x: 3, y: 0, w: 4, h: 6 },
		{ i: '2', x: 0, y: 6, w: 3, h: 5 },
		{ i: '3', x: 0, y: 0, w: 3, h: 4 },
		{ i: '4', x: 0, y: 11, w: 3, h: 6 },
	],
	md: [
		{ i: '1', x: 3, y: 0, w: 3, h: 6 },
		{ i: '2', x: 0, y: 6, w: 3, h: 5 },
		{ i: '3', x: 0, y: 0, w: 3, h: 4 },
		{ i: '4', x: 3, y: 6, w: 3, h: 6 },
	],
	sm: [
		{ i: '1', x: 0, y: 0, w: 4, h: 8 },
		{ i: '2', x: 0, y: 11, w: 4, h: 5 },
		{ i: '3', x: 0, y: 6, w: 4, h: 4 },
		{ i: '4', x: 0, y: 16, w: 4, h: 6 },
	],
};

export const PeblMQTT = ({ pebl }) => {
	return (
		<WidgetGrid layouts={gridLayout}>
			<div key='1'>
				<PeblMQTTSetup pebl={pebl} />
			</div>
			<div key='2'>
				<PeblMQTTActions pebl={pebl} />
			</div>
			<div key='3'>
				<PeblMQTTCredentials pebl={pebl} />
			</div>
			<div key='4'>
				<MQTTUARTSession session={pebl.mqtt.session} pebl={pebl} />
			</div>
		</WidgetGrid>
	);
};
