import React, { useState } from 'react';
import { FingotiButton } from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(theme => ({

    root: {
        margin: 8,
    },
    sortRoot: {
        border: '1px solid ' + theme.palette.greyFour.main,
        borderRadius: theme.spacing(1) / 2,
        padding: theme.spacing(1),
    },
    sortButton: {
        width: '100%'
    }

}))

export const DeviceSort = ({ onSortChange, onApplySort }) => {

    const classes = useStyles();
    const [sortBy, setSortBy] = useState("online");
    const [sortOrder, setSortOrder] = useState("asc");

    const handleSortBy = (ev) => {
        setSortBy(ev.target.value);
        onSortChange([ev.target.value, sortOrder]);
    }

    const handleSortOrder = (ev) => {
        setSortOrder(ev.target.value);
        onSortChange([sortBy, ev.target.value]);
    }

    return (
        <Grid item xs={12} className={classes.root}>
            <Grid container spacing={2} alignItems='center' className={classes.sortRoot}>
                <Grid item sm={2} xs={12}>
                    <Typography>Sort by</Typography>
                </Grid>
                <Grid item sm={4} xs={6}>
                    <FormControl variant='outlined' size="small" fullWidth>
                        <Select
                            value={sortBy}
                            onChange={handleSortBy}
                        >
                            <MenuItem value="deviceName">Name</MenuItem>
                            <MenuItem value="online">Online</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={4} xs={6}>
                    <FormControl variant='outlined' size="small" fullWidth>
                        <Select
                            value={sortOrder}
                            onChange={handleSortOrder}
                        >
                            <MenuItem value="asc">Ascending</MenuItem>
                            <MenuItem value="des">Descending</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={2} xs={12}>
                    <FingotiButton
                        light
                        color="primary"
                        className={classes.sortButton}
                        onClick={onApplySort}
                    >
                        apply
                    </FingotiButton>
                </Grid>
            </Grid>
        </Grid>
    );
};
