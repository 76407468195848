import React, { useState } from 'react';
import clsx from 'clsx';
import { FingotiButton, FingotiModalActions, FingotiModalContent } from '@fingoti/components';
import { useSnackbar } from 'notistack';

import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import { apiService } from '../../../services/api.service';
import { useDeviceState } from '../../../context/DeviceContext';

const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        justifyContent: 'center',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '60%',
        }
    },
    formControl: {
        width: '100%',
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '50%',
        }
    },
    button: {
        width: '42%',
    },
    switches: {
        display: 'flex',
        justifyContent: 'space-around',
    }
}))


export const FollowingAdd = ({ setOpen, onSuccess }) => {

    const classes = useStyles()
    const { vyneGateways, peblGateways } = useDeviceState();
    const { enqueueSnackbar } = useSnackbar();
    const devices = [...peblGateways, ...vyneGateways];
    const [followName, setFollowName] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [mirror, setMirror] = useState(true);
    const [sourceDevice, setSourceDevice] = useState(devices[0].id);
    const [sourceGpio, setSouceGpio] = useState(1);
    const [destinationDevice, setDestinationDevice] = useState(devices[0].id);
    const [destinationGpio, setDestinationGpio] = useState(1);

    const submitFollowing = () => {

        let body = {
            followName: followName,
            disabled: disabled,
            mirror: mirror,
            sourceDevice: sourceDevice,
            sourcePin: sourceGpio,
            destinationDevice: destinationDevice,
            destinationPin: destinationGpio
        }

        apiService.postData('/followings', body)
            .then(result => {
                enqueueSnackbar(result.message, { variant: "success" });
                onSuccess();
            })
            .catch(error => {
                enqueueSnackbar(error, { variant: "error" });
            })
    }

    return (
        <>
            <FingotiModalContent>
                <div className={classes.root}>
                    <div className={classes.form}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <TextField
                                        fullWidth
                                        required
                                        id='followingName'
                                        name='followingName'
                                        variant='outlined'
                                        label='following name'
                                        value={followName}
                                        onChange={(e) => setFollowName(e.target.value)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={clsx(classes.formControl, classes.switches)}>
                                    <FormControl required variant='outlined'>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={!disabled}
                                                    onChange={() => setDisabled(!disabled)}
                                                    name='disabled'
                                                    color='primary'
                                                />
                                            }
                                            label='enabled'
                                        />
                                    </FormControl>
                                    <FormControl required variant='outlined'>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={mirror}
                                                    onChange={() => setMirror(!mirror)}
                                                    name='mirror'
                                                    color='primary'
                                                />
                                            }
                                            label='mirror'
                                        />
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id='source-device-label'>source device</InputLabel>
                                    <Select
                                        required
                                        id='sourceDevice'
                                        labelId='source-device-label'
                                        variant='outlined'
                                        label='source device'
                                        value={sourceDevice}
                                        onChange={(e) => {
                                            setSourceDevice(e.target.value);
                                        }}
                                    >
                                        {
                                            devices.map(d => <MenuItem value={d.id}>{d.device.name} - {d.id}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <TextField
                                        required
                                        type='number'
                                        id='sourceGpio'
                                        variant='outlined'
                                        label='source gpio'
                                        value={sourceGpio}
                                        onChange={(e) => setSouceGpio(e.target.value)}
                                        inputProps={{ 
                                            min: 1, 
                                            max: devices.find(d => d.id === sourceDevice).type.type === "vyne" ? 16 : 4
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id='destination-device-label'>destination device</InputLabel>
                                    <Select
                                        required
                                        id='destinationDevice'
                                        variant='outlined'
                                        label='destination device'
                                        labelId='destination-device-label'
                                        value={destinationDevice}
                                        onChange={(e) => {
                                            setDestinationDevice(e.target.value);
                                        }}
                                    >
                                        {
                                            devices.map(d => <MenuItem value={d.id}>{d.device.name} - {d.id}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <TextField
                                        required
                                        type='number'
                                        id='destinationGpio'
                                        variant='outlined'
                                        label='destination gpio'
                                        value={destinationGpio}
                                        onChange={(e) => setDestinationGpio(e.target.value)}
                                        inputProps={{ 
                                            min: 1, 
                                            max: devices.find(d => d.id === destinationDevice).type.type === "vyne" ? 16 : 4
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </FingotiModalContent>
            <FingotiModalActions>
                <div className={classes.buttonGroup}>
                    <FingotiButton
                        light
                        type="submit"
                        color="primary"
                        className={classes.button}
                        onClick={submitFollowing}
                    >
                        create following
                    </FingotiButton>
                    <FingotiButton
                        light
                        className={classes.button}
                        onClick={() => setOpen(false)}
                    >
                        Cancel
                    </FingotiButton>
                </div>
            </FingotiModalActions>
        </>
    );

}