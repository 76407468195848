import React from 'react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';

import Box from '@material-ui/core/Box';

import { WidgetWrapper } from '../device/WidgetWrapper';
import { InsufficientData } from './InsufficientData';

export const TotalUsageChart = ({ insufficientData = false, data, timespan }) => {

    const weekdays = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

    const CustomLabel = (value) => {
        if (timespan > 7) {
            var dateParts = value.split("/");
            return dateParts[0];
        } else {
            var dateParts = value.split("/");
            // month is 0-based, that's why we need dataParts[1] - 1
            let dayOfWeek = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]).getDay();
            return weekdays[dayOfWeek]
        }
    }

    return (
        <WidgetWrapper displaySave={false} title='Total Ripple Usage'>
            {
                insufficientData ?
                    <InsufficientData />
                    :
                    <Box paddingY={3}>
                        <ResponsiveContainer width='100%' height={320}>
                            <LineChart
                                data={data}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                            >
                                <defs>
                                    <linearGradient id="grad1" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="1%" stopColor="#FF8C23" stopOpacity={1} />
                                        <stop offset="99%" stopColor="#FFA34F" stopOpacity={0.3} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="date" tickFormatter={CustomLabel} />
                                <YAxis />
                                <Tooltip />
                                <Line type="monotone" dataKey="total" stroke="#FF8C23" fillOpacity={1} fill="url(#grad1)" dot={false} strokeWidth={3} />
                            </LineChart>
                        </ResponsiveContainer>
                    </Box>
            }
        </WidgetWrapper>
    );
}