import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { WidgetWrapper } from '../../../WidgetWrapper';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({

    celsius: {
        color: theme.palette.primary.main
    },
    fahrenheit: {
        color: theme.palette.secondary.main
    }

}))

export const VyneTemp = ({ data }) => {

    const classes = useStyles();

    return (
        <WidgetWrapper title="Temperature" displaySave={false}>
            <Typography className={classes.celsius} variant="h3">
                {data[0].value + data[0].unit}
            </Typography>
            <Typography className={classes.fahrenheit} variant="h3">
                {data[1].value + data[1].unit}
            </Typography>
        </WidgetWrapper>
    );

}