import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
	FingotiButton,
	FingotiActionButton,
	FingotiModalContent,
	FingotiModalActions,
	FingotiModal,
} from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';

import { WidgetWrapper } from '../../../WidgetWrapper';
import { RequestBuilder } from '../../../../../services/request.service';
import useDeviceErrorHandler from '../../../useDeviceErrorHandler';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
	},
	content: {
		display: 'flex',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '75%',
		},
		alignItems: 'center',
		flexDirection: 'column',
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'space-evenly',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
	},
	filename: {
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	fileContents: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	button: {
		width: '42%',
	},
}));

const CertUpload = ({ setOpen, requestBuilder, cert }) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const errorHandler = useDeviceErrorHandler();
	const [filename, setFilename] = useState('');
	const [fileContents, setFileContents] = useState('');
	const [disableSave, setDisableSave] = useState(true);
	const [submitted, setSubmitted] = useState(false);

	const onSelectFile = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader();
			let filenameSplit = e.target.files[0].name.split('.');
			let ext = filenameSplit.pop();
			if (
				filenameSplit.length === 2 &&
				ext !== 'pem' &&
				ext !== 'crt' &&
				ext !== 'cer'
			) {
				enqueueSnackbar(
					'Incorrect file type, please upload a valid .pem, .crt or .cer file',
					{ variant: 'error' }
				);
			} else {
				setFilename(e.target.files[0].name);
				reader.addEventListener('load', () => {
					setFileContents(reader.result.trim());
					setDisableSave(false);
				});
				reader.readAsText(e.target.files[0]);
			}
		}
	};

	const uploadCertToDevice = () => {
		setSubmitted(true);
		requestBuilder.addRequest('mqttCertificate', 'W', { pem: fileContents });
		requestBuilder
			.send()
			.then(() => {
				enqueueSnackbar(`Uploaded certificate to device successfully`, {
					variant: 'success',
				});
				setOpen(false);
			})
			.catch((res) => errorHandler(res))
			.finally(() => setSubmitted(false));
	};

	const clearCertificate = () => {
		setSubmitted(true);
		requestBuilder.addRequest('mqttCertificate', 'W', { clear: true });
		requestBuilder
			.send()
			.then(() => {
				enqueueSnackbar(`Cleared certificate from device successfully`, {
					variant: 'success',
				});
				setOpen(false);
			})
			.catch((res) => errorHandler(res))
			.finally(() => setSubmitted(false));
	};

	return (
		<>
			<FingotiModalContent>
				<div className={classes.root}>
					<div className={classes.content}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={8}>
								<input
									id='file-upload'
									type='file'
									accept='.pem, .crt, .cer'
									hidden
									onChange={onSelectFile}
								/>
								<label
									for='file-upload'
									className='MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-disableElevation'>
									browse for certificate
								</label>
								{filename !== '' && (
									<span className={classes.filename}>{filename}</span>
								)}
							</Grid>
							<Grid item xs={12} md={4}>
								<Box display='flex' justifyContent='flex-end'>
									<FingotiButton
										light
										color='primary'
										onClick={clearCertificate}
										disabled={!cert}>
										Clear Certificate
									</FingotiButton>
								</Box>
							</Grid>
						</Grid>
						<Box
							display='flex'
							alignItems='center'
							flexDirection='column'
							width='100%'>
							<TextField
								fullWidth
								label='Certificate'
								multiline
								rows={14}
								value={fileContents}
								className={classes.fileContents}
								onChange={(e) => setFileContents(e.target.value)}
								variant='outlined'
								inputProps={{
									style: { fontFamily: "'Courier New', monospace" },
								}}
							/>
						</Box>
					</div>
				</div>
			</FingotiModalContent>
			<FingotiModalActions>
				<div className={classes.buttonGroup}>
					<FingotiButton
						light
						color='primary'
						onClick={uploadCertToDevice}
						loading={submitted}
						disabled={disableSave}
						className={classes.button}>
						upload certificate
					</FingotiButton>
					<FingotiButton
						light
						className={classes.button}
						onClick={() => setOpen(false)}>
						Cancel
					</FingotiButton>
				</div>
			</FingotiModalActions>
		</>
	);
};

export const PeblMQTTActions = ({ pebl }) => {
	const { enqueueSnackbar } = useSnackbar();
	const errorHandler = useDeviceErrorHandler();
	const [enabled, setEnabled] = useState(pebl.mqtt.status.enabled);
	const [enableLoading, setEnableLoading] = useState(false);
	const [certUploadOpen, setCertUploadOpen] = useState(false);
	const rb = new RequestBuilder(pebl.id);

	useEffect(() => {}, [pebl]);

	const handleEnableToggle = () => {
		setEnableLoading(true);
		rb.addRequest('mqttStatus', 'W', { enabled: !enabled });
		rb.send()
			.then(() => {
				enqueueSnackbar(`MQTT ${!enabled ? 'Enabled' : 'Disabled'}`, {
					variant: 'success',
				});
				setEnabled(!enabled);
			})
			.catch((err) => errorHandler(err))
			.finally(() => setEnableLoading(false));
	};

	return (
		<>
			<FingotiModal
				title='Upload Certificate'
				open={certUploadOpen}
				setOpen={setCertUploadOpen}>
				<CertUpload
					setOpen={setCertUploadOpen}
					requestBuilder={rb}
					cert={pebl.mqtt.certificate}
				/>
			</FingotiModal>
			<WidgetWrapper title='Actions' displaySave={false}>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<FingotiActionButton
							Icon={CheckCircleOutlineIcon}
							label='Enable'
							active={enabled}
							loading={enableLoading}
							onClick={handleEnableToggle}
						/>
					</Grid>
					<Grid item xs={6}>
						<FingotiActionButton
							Icon={PublishOutlinedIcon}
							label='Certificate'
							active={pebl.mqtt.certificate}
							onClick={() => setCertUploadOpen(true)}
						/>
					</Grid>
				</Grid>
			</WidgetWrapper>
		</>
	);
};
