import React, { useEffect } from 'react';

import { WidgetGrid } from '../../WidgetGrid';
import { VyneNodeDetect } from './widgets/VyneNodeDetect';

const gridLayout = {
    xl: [
        { i: '1', x: 0, y: 0, w: 3, h: 7 },
    ],
    lg:  [
        { i: '1', x: 0, y: 0, w: 4, h: 7 },
    ],
    md:  [
        { i: '1', x: 0, y: 0, w: 4, h: 7 },
    ],
    sm:  [
        { i: '1', x: 0, y: 0, w: 4, h: 7 },
    ],
}

export const VyneGatewayNode = ({ vyne }) => {

    useEffect(() => {}, [vyne])

    return (
        <WidgetGrid layouts={gridLayout}>
            <div key='1'>
                <VyneNodeDetect nodeDetect={vyne.node.detect} />
            </div>
        </WidgetGrid>
    );

}