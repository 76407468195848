import React, { useEffect } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { FingotiButton } from '@fingoti/components';

const useStyles = makeStyles(theme => ({

    deviceList: {
        height: '67vh',
        overflowY: 'scroll',
        overflowX: 'hidden',
        scrollbarColor: theme.palette.greyTwo.main + ' ' + theme.palette.greyFour.main,
        scrollbarWidth: 'thin',
        [theme.breakpoints.up('md')]: {
            paddingRight: theme.spacing(4) + 'px !important',
        }
    },
    deviceDetails: {
        display: 'flex',
        flexDirection: 'column',
    },
    detailsContent: {
        paddingLeft: theme.spacing(4),
    },
    noDevices: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.greyThree.main,
        flexDirection: 'column',
    },

}))

export const DevicesView = ({ deviceList, deviceDetails, openClaimModal, isNodes = false }) => {

    const classes = useStyles();
    const theme = useTheme();

    useEffect(() => {})

    return (
        <Grid container spacing={2}>
            <Grid item xl={7} lg={9} md={7} xs={12} className={classes.deviceList}>
                {
                    deviceList.props.children[1].length > 0 ?
                        deviceList
                        :
                        <div className={classes.noDevices}>
                            <Typography style={{ marginBottom: theme.spacing(4) }}>You currently have no devices claimed</Typography>
                            {
                                !isNodes ?
                                    <FingotiButton
                                        light
                                        color="primary"
                                        onClick={openClaimModal}
                                    >
                                        Claim device
                                    </FingotiButton>
                                    :
                                    <></>
                            }
                        </div>
                }
            </Grid>
            <Grid item xl={5} lg={3} md={5} xs={12} className={classes.deviceDetails}>
                {deviceDetails}
            </Grid>
        </Grid>
    );
}