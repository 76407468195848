import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import {
	FingotiButton,
	FingotiModalActions,
	FingotiModalContent,
} from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import Validation from '../../../services/validation.service';
import { apiService } from '../../../services/api.service';
import { useForceUpdate } from '../../utils/ForceUpdate';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
	},
	content: {
		display: 'flex',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '75%',
		},
		alignItems: 'center',
		flexDirection: 'column',
	},
	serial: {
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'space-evenly',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
	},
	button: {
		width: '42%',
	},
}));

export const ClaimGateway = ({ setOpen }) => {
	const classes = useStyles();
	const forceUpdate = useForceUpdate();
	const { enqueueSnackbar } = useSnackbar();
	const [serial, setSerial] = useState('');
	const [errors, setErrors] = useState({});
	const [submitting, setSubmitting] = useState(false);

	const handleSetErrors = (errors) => {
		setErrors(errors);
		forceUpdate();
	};

	const validate = new Validation(errors, handleSetErrors);

	const submitClaim = () => {
		setSubmitting(true);
		apiService
			.postData('/device', { serial: serial })
			.then((result) => {
				enqueueSnackbar(result.message, { variant: 'success' });
				setSubmitting(false);
				setOpen(false);
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
				setSubmitting(false);
			})
			.finally(() => setSubmitting(false));
	};

	return (
		<>
			<FingotiModalContent>
				<div className={classes.root}>
					<div className={classes.content}>
						<FormControl
							fullWidth
							variant='outlined'
							className={classes.serial}>
							<TextField
								autoFocus
								fullWidth
								required
								id='serialNo'
								name='serialNo'
								variant='outlined'
								label='Serial No'
								value={serial}
								onChange={(e) => {
									setSerial(e.target.value.toUpperCase());
									e.target.value.length === 10 && validate.checkAgainstRegex(e);
								}}
								onBlur={(e) => validate.checkAgainstRegex(e)}
								error={Boolean(errors['serialNo'])}
								helperText={
									Boolean(errors['serialNo']) ? errors['serialNo'].msg : ''
								}
								inputProps={{ maxLength: 10 }}
							/>
						</FormControl>
					</div>
				</div>
			</FingotiModalContent>
			<FingotiModalActions>
				<div className={classes.buttonGroup}>
					<FingotiButton
						light
						color='primary'
						className={classes.button}
						disabled={Boolean(
							Object.keys(errors).length > 0 || serial.length !== 10
						)}
						onClick={submitClaim}
						loading={submitting}>
						START CLAIM
					</FingotiButton>
					<FingotiButton
						light
						className={classes.button}
						onClick={() => setOpen(false)}>
						CANCEL
					</FingotiButton>
				</div>
			</FingotiModalActions>
		</>
	);
};
