import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({

    pin: {
        borderRadius: '50%',
        width: theme.spacing(3),
        height: theme.spacing(3),
        border: '1.5px solid' + theme.palette.greyFour.main,
    }

}))

export const DeviceGPIO = ({ data, gpio, onClick, readOnly = false }) => {

    const classes = useStyles();

    const fingotiGreyTwo = '#808080';
    const fingotiGreyFour = '#E0E0E0';
    let styles = {};
    
    if(data.direction[gpio]) {
        styles.backgroundColor = fingotiGreyFour;
    }
    
    if(data.state[gpio]) {
        styles.backgroundColor = fingotiGreyTwo;
    }

    return (
        <div className={classes.pin} style={styles} onClick={readOnly ? () => {} : () => onClick()} />
    )
 
}