import { apiUrl } from './config';
import { authService } from './auth.service';
const signalR = require('@microsoft/signalr');

export var deviceHub;

export const buildSignalRHub = () => {
	deviceHub = new signalR.HubConnectionBuilder()
		.withUrl(`${apiUrl}/hubs/userhub`, {
			accessTokenFactory: authService.authToken,
		})
		.withAutomaticReconnect()
		.build();
};
