import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { WidgetWrapper } from '../../../WidgetWrapper';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({

    active: {
        color: theme.palette.primary.main
    },
    idle: {
        color: theme.palette.greyThree.main
    },

}))


export const VynePir = ({ data }) => {

    const classes = useStyles();

    return (
        <WidgetWrapper title="Motion" displaySave={false}>
            <Typography variant="h3" className={data[0].value === "ACTIVE" ? classes.active : classes.idle}>
                {data[0].value}
            </Typography>
        </WidgetWrapper>
    );

}