import React, { useState } from 'react';
import GaugeChart from 'react-gauge-chart';
import { useSnackbar } from 'notistack';

import RefreshIcon from '@material-ui/icons/Refresh';

import makeStyles from '@material-ui/core/styles/makeStyles';

import IconButton from '@material-ui/core/IconButton';

import { WidgetWrapper } from '../../WidgetWrapper';
import { RequestBuilder } from '../../../../services/request.service';
import useDeviceErrorHandler from '../../useDeviceErrorHandler';

const useStyles = makeStyles((theme) => ({
	power: {
		display: 'flex',
		justifyContent: 'center',
	},
}));

export const Power = ({
	voltage = false,
	minPower,
	maxPower,
	currentPower,
    device,
}) => {
    const errorHandler = useDeviceErrorHandler();
    const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
    const [refreshDisabled, setRefreshDisabled] = useState(false);
    const rb = new RequestBuilder(device.id);

	let title = 'Current';
	let labelSuffix = ' mA';

	if (voltage) {
		title = 'Voltage';
		labelSuffix = ' V';
	}

	const levels = maxPower - minPower;
	const segments = levels;
	const powerAsPercent = (currentPower - minPower) / (maxPower - minPower);

	const segmentColors = () => {
		const endsColour = { r: 226, g: 215, b: 45 };
		const midColour = { r: 106, g: 215, b: 45 };

		const rDiff = endsColour.r - midColour.r;
		const gDiff = endsColour.g - midColour.g;
		const bDiff = endsColour.b - midColour.b;

		const rSegValue = Math.floor(rDiff / (segments / 2));
		const gSegValue = Math.floor(gDiff / (segments / 2));
		const bSegValue = Math.floor(bDiff / (segments / 2));

		let colours = [];
		let currentColour = { r: 0, g: 0, b: 0 };
		for (let i = 0; i < segments; i++) {
			if (i === 0 || i === segments) {
				//Either end
				currentColour = endsColour;
			} else if (i === Math.floor(segments / 2)) {
				//Midpoint
				currentColour = midColour;
			} else if (i <= Math.floor(segments / 2)) {
				//first half
				currentColour = {
					r: currentColour.r - rSegValue,
					g: currentColour.g - gSegValue,
					b: currentColour.b - bSegValue,
				};
			} else {
				//second half
				currentColour = {
					r: currentColour.r + rSegValue,
					g: currentColour.g + gSegValue,
					b: currentColour.b + bSegValue,
				};
			}

			colours.push(
				`#${Math.abs(currentColour.r).toString(16)}${Math.abs(
					currentColour.g
				).toString(16)}${Math.abs(currentColour.b).toString(16)}`
			);
		}

		return colours;
	};

	const handleRefresh = () => {
        setRefreshDisabled(true);
		rb.addRequest('devicePower', 'R');
		rb.send()
			.then(() => {
				enqueueSnackbar(`Refreshing device ${voltage ? 'voltage' : 'current'} `, {
					variant: 'success',
				});
			})
			.catch((res) => errorHandler(res))
            .finally(() => setRefreshDisabled(false));
	};

	const icons = [
		<IconButton disabled={refreshDisabled} onClick={handleRefresh}>
			<RefreshIcon />
		</IconButton>,
	];

	return (
		<WidgetWrapper title={title} icons={icons} displaySave={false}>
			<div className={classes.power}>
				<GaugeChart
					style={{ width: 300 }}
					marginInPercent={0.02}
					id='gauge-chart2'
					nrOfLevels={levels}
					percent={powerAsPercent}
					colors={segmentColors()}
					needleBaseColor='#C0C0C0'
					needleColor='#C0C0C0'
					textColor='#404040'
					animate={false}
					formatTextValue={() => `${currentPower}${labelSuffix}`}
				/>
			</div>
		</WidgetWrapper>
	);
};
