import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import AccessibilityNewOutlinedIcon from '@material-ui/icons/AccessibilityNewOutlined';
import HighlightOff from '@material-ui/icons/HighlightOff';
import PowerOutlinedIcon from '@material-ui/icons/PowerOutlined';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import FlashOnOutlinedIcon from '@material-ui/icons/FlashOnOutlined';

import {
	QuestionIcon,
	DeviceIcon,
	GatewayIcon,
	ThermometerIcon,
	LightBulbIcon,
} from '../../../icons/FingotIcons';

import { apiService } from '../../../services/api.service';
import { RequestBuilder } from '../../../services/request.service';
import useDeviceErrorHandler from '../useDeviceErrorHandler';
import { deviceType } from '../_deviceFunctions';

const useStyles = makeStyles((theme) => ({
	'root': {
		'border': '1px solid' + theme.palette.greyFour.main,
		'borderRadius': theme.spacing(1),
		'&:hover': {
			cursor: 'pointer',
		},
	},
	'selected': {
		boxShadow: '4px 4px 6px ' + theme.palette.greyFour.main,
		backgroundColor: theme.palette.greyFour.light,
		transition:
			'box-shadow 0.125s ease-in-out, background-color 0.125s ease-in-out',
	},
	'indicatior': {
		borderRadius: theme.spacing(1) + 'px 0px 0px ' + theme.spacing(1) + 'px',
		transition: 'background-color 0.25s ease-in-out',
	},
	'online': {
		backgroundColor: theme.palette.success.main,
	},
	'offline': {
		backgroundColor: theme.palette.greyFour.main,
	},
	'disabled': {
		backgroundColor: theme.palette.error.main,
	},
	'unclaimed': {
		backgroundColor: theme.palette.warning.main,
	},
	'identifying': {
		animation: `$identifyFade 2s infinite`,
		transition: 'background-color 0.25s ease-in-out',
	},
	'@keyframes identifyFade': {
		'0%': {
			backgroundColor: theme.palette.greyFour.main,
		},
		'25%': {
			backgroundColor: theme.palette.info.main,
		},
		'75%': {
			backgroundColor: theme.palette.info.main,
		},
		'100%': {
			backgroundColor: theme.palette.greyFour.main,
		},
	},
	'main': {
		padding: theme.spacing(1),
		paddingLeft: theme.spacing(2),
	},
	'serial': {
		color: theme.palette.greyTwo.main,
		marginTop: '-' + theme.spacing(1) / 2 + 'px',
	},
	'iconWrapper': {
		display: 'flex',
		alignItems: 'center',
	},
	'typeIcon': {
		height: '3rem',
		width: '3rem',
	},
	'iconsRoot': {
		marginTop: theme.spacing(2),
		minHeight: '24px',
	},
	'icon': {
		'padding': 0,
		'&:not(:last-child)': {
			marginRight: theme.spacing(2),
		},
	},
	'confirmRemove': {
		fill: theme.palette.warning.main,
	},
	'name': {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
}));

export const DeviceCard = ({
	device,
	type,
	onClick,
	unsetSelectedDevice,
	selected,
}) => {
	const classes = useStyles();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const errorHandler = useDeviceErrorHandler();
	const rb = new RequestBuilder(device.id);
	const [showConfirmRemove, setShowConfirmRemove] = useState(false);
	const [showConfirmUpdate, setShowConfirmUpdate] = useState(false);
	let confirmRemoveTimeout;
	let confirmUpdateTimeout;

	useEffect(() => {
		return () => {
			clearTimeout(confirmRemoveTimeout);
			clearTimeout(confirmUpdateTimeout);
		};
	}, []);

	const getIcons = () => {
		const RemoveDeviceIcon = () => {
			if (showConfirmRemove) {
				return <ErrorOutlineOutlinedIcon className={classes.confirmRemove} />;
			} else {
				return <HighlightOff />;
			}
		};

		const UpdateDeviceIcon = () => {
			if (showConfirmUpdate) {
				return <ErrorOutlineOutlinedIcon className={classes.confirmRemove} />;
			} else {
				return <CloudDownloadOutlinedIcon />;
			}
		};

		const handleSettingsClick = () => {
			let urlType;

			let masterPart = device.partNumber.includes('-')
			? device.partNumber.split('-')[0]
			: device.partNumber;

			switch (deviceType[masterPart]) {
				case 'pebl':
				case 'vyne':
					urlType = 'gateways';
					break;
				default:
					urlType = 'nodes';
					break;
			}

			history.push(`/devices/${urlType}/${device.id}`);
		};

		const handleRemove = () => {
			apiService
				.deleteData(`/device/${device.id}`)
				.then((result) => {
					enqueueSnackbar(result.message, { variant: 'success' });
					if (selected) {
						unsetSelectedDevice();
					}
				})
				.catch((error) => {
					enqueueSnackbar(error, { variant: 'error' });
				});
		};

		const handleConfirmRemove = () => {
			setShowConfirmRemove(true);
			confirmRemoveTimeout = setTimeout(
				() => setShowConfirmRemove(false),
				3000
			);
		};

		const handleConfirmUpdate = () => {
			setShowConfirmUpdate(true);
			confirmUpdateTimeout = setTimeout(
				() => setShowConfirmUpdate(false),
				3000
			);
		};

		const handleUpdate = () => {
			apiService
				.postData('/device/update', { devices: [device.id] })
				.then((result) => {
					enqueueSnackbar(result.message, { variant: 'success' });
				})
				.catch((error) => {
					enqueueSnackbar(error, { variant: 'error' });
				});
		};

		const handleRestart = () => {
			rb.addRequest('devicePower', 'W', {
				restart: true,
			});
			rb.send()
				.then(() => {
					enqueueSnackbar('Device restarting', { variant: 'success' });
				})
				.catch((res) => errorHandler(res));
		};

		const handleRefresh = () => {
			rb.addRequest('deviceInformation', 'R');
			rb.send()
				.then(() => {
					enqueueSnackbar('Device information updated', {
						variant: 'success',
					});
				})
				.catch((res) => errorHandler(res));
		};

		let masterPart = device.partNumber.includes('-')
			? device.partNumber.split('-')[0]
			: device.partNumber;

		switch (deviceType[masterPart]) {
			case 'pebl':
			case 'vyne':
				if (device.claim.complete) {
					return [
						{
							icon: SettingsOutlinedIcon,
							onClick: () => handleSettingsClick(),
							tooltip: 'settings',
							disabled: false,
						},
						{
							icon: UpdateDeviceIcon,
							onClick: showConfirmUpdate ? handleUpdate : handleConfirmUpdate,
							tooltip: showConfirmUpdate ? 'confirm update' : 'update device',
							disabled: device.device.cloud.connected
								? device.systemVersion === device.device.version.software
									? true
									: false
								: true,
						},
						{
							icon: RefreshOutlinedIcon,
							onClick: () => handleRefresh(),
							tooltip: 'refresh information',
							disabled: !device.device.cloud.connected,
						},
						{
							icon: PowerSettingsNewOutlinedIcon,
							onClick: () => handleRestart(),
							tooltip: 'reboot device',
							disabled: !device.device.cloud.connected,
						},
						{
							icon: RemoveDeviceIcon,
							onClick: showConfirmRemove ? handleRemove : handleConfirmRemove,
							tooltip: showConfirmRemove ? 'confirm unclaim' : 'unclaim device',
							disabled: device.device.cloud.connected,
						},
					];
				} else {
					return [
						{
							icon: RemoveDeviceIcon,
							onClick: showConfirmRemove ? handleRemove : handleConfirmRemove,
							tooltip: showConfirmRemove ? 'confrim unclaim' : 'unclaim device',
							disabled: false,
						},
					];
				}
			case 't&h':
			case 'lux':
			case 'pir':
			case 'psu':
			case 'poe':
			case 'ups':
				return [
					{
						icon: SettingsOutlinedIcon,
						onClick: () => handleSettingsClick(),
						tooltip: 'settings',
						disabled: false,
					},
					{
						icon: RemoveDeviceIcon,
						onClick: showConfirmRemove ? handleRemove : handleConfirmRemove,
						tooltip: showConfirmRemove ? 'confrim unlatch' : 'unlatch device',
						disabled: false,
					},
				];
			default:
				return null;
		}
	};

	let deviceIcon;
	let isNode = true;
	let invalid = false;
	let claimed = false;
	let enabled = true;
	let online = false;
	let identifying = false;

	let masterPart = device.partNumber.includes('-')
	? device.partNumber.split('-')[0]
	: device.partNumber;

	switch (deviceType[masterPart]) {
		case 'pebl':
			isNode = false;
			online = device.device.cloud.connected;
			claimed = device.claim.complete;
			enabled = device.device.cloud.enabled;
			deviceIcon = <DeviceIcon className={classes.typeIcon} />;
			break;
		case 'vyne':
			isNode = false;
			online = device.device.cloud.connected;
			claimed = device.claim.complete;
			enabled = device.device.cloud.enabled;
			deviceIcon = <GatewayIcon className={classes.typeIcon} />;
			break;
		case 'lux':
			online = device.online;
			claimed = true;
			enabled = device.enable;
			deviceIcon = <LightBulbIcon className={classes.typeIcon} />;
			identifying = device.identify;
			break;
		case 't&h':
			online = device.online;
			claimed = true;
			enabled = device.enable;
			deviceIcon = <ThermometerIcon className={classes.typeIcon} />;
			identifying = device.identify;
			break;
		case 'pir':
			online = device.online;
			claimed = true;
			enabled = device.enable;
			deviceIcon = (
				<AccessibilityNewOutlinedIcon className={classes.typeIcon} />
			);
			identifying = device.identify;
			break;
		case 'psu':
			online = device.online;
			claimed = true;
			enabled = device.enable;
			deviceIcon = <PowerOutlinedIcon className={classes.typeIcon} />;
			identifying = device.identify;
			break;
		case 'poe':
			online = device.online;
			claimed = true;
			enabled = device.enable;
			deviceIcon = <FlashOnOutlinedIcon className={classes.typeIcon} />;
			identifying = device.identify;
			break;
		case 'ups':
			online = device.online;
			claimed = true;
			enabled = device.enable;
			deviceIcon = <BatteryFullIcon className={classes.typeIcon} />;
			identifying = device.identify;
			break;
		case 'foreign node':
			invalid = true;
			claimed = true;
			deviceIcon = <ErrorOutlineOutlinedIcon className={classes.typeIcon} />;
			break;
		default:
			claimed = true;
			deviceIcon = <QuestionIcon className={classes.typeIcon} />;
			break;
	}

	let indicatorClass;

	if (identifying) {
		indicatorClass = classes.identifying;
	} else if (!enabled && online) {
		indicatorClass = classes.disabled;
	} else if (!claimed) {
		indicatorClass = classes.unclaimed;
	} else if (invalid) {
		indicatorClass = classes.unclaimed;
	} else if (online) {
		indicatorClass = classes.online;
	} else {
		indicatorClass = classes.offline;
	}

	let icons = getIcons(type, device, onClick);

	return (
		<Grid
			container
			className={clsx(classes.root, { [classes.selected]: selected })}
			onClick={() => onClick(device)}>
			<Grid item xs={1} className={clsx(indicatorClass, classes.indicatior)} />
			<Grid item xs={9} className={classes.main}>
				<Typography className={classes.name} variant='h6'>
					{isNode ? device.name : device.device.name}
				</Typography>
				<Typography className={classes.serial} variant='body2'>
					{device.id}
				</Typography>
				<div className={classes.iconsRoot}>
					{icons
						? icons.map((CardIcon, i) => {
								return (
									<Tooltip title={CardIcon.tooltip} key={i}>
										<IconButton
											disabled={CardIcon.disabled}
											className={classes.icon}
											onClick={CardIcon.onClick}>
											<CardIcon.icon />
										</IconButton>
									</Tooltip>
								);
						  })
						: ''}
				</div>
			</Grid>
			<Grid item xs={2} className={classes.iconWrapper}>
				{deviceIcon}
			</Grid>
		</Grid>
	);
};
