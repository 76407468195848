import React, { useEffect } from 'react';

import { RequestBuilder } from '../../../../services/request.service';
import { WidgetGrid } from '../../WidgetGrid';
import { Identification } from '../../shared/widgets/Identification';
import { Power } from '../../shared/widgets/Power';
import { Version } from '../../shared/widgets/Version';
import { VyneActions } from './widgets/VyneActions';


const gridLayout = {
    xl: [
        { i: '1', x: 0, y: 0, w: 3, h: 7 },
        { i: '2', x: 3, y: 0, w: 3, h: 7 },
        { i: '3', x: 6, y: 0, w: 3, h: 7 },
        { i: '4', x: 9, y: 0, w: 3, h: 4 },
        { i: '5', x: 0, y: 7, w: 3, h: 8 },
    ],
    lg:  [
        { i: '1', x: 0, y: 0, w: 3, h: 7 },
        { i: '2', x: 3, y: 0, w: 3, h: 7 },
        { i: '3', x: 6, y: 0, w: 3, h: 7 },
        { i: '4', x: 0, y: 7, w: 3, h: 4 },
        { i: '5', x: 3, y: 7, w: 3, h: 8 },
    ],
    md:  [
        { i: '1', x: 0, y: 0, w: 3, h: 7 },
        { i: '2', x: 3, y: 0, w: 3, h: 7 },
        { i: '3', x: 0, y: 7, w: 3, h: 7 },
        { i: '4', x: 3, y: 7, w: 3, h: 4 },
        { i: '5', x: 0, y: 7, w: 3, h: 8 },
    ],
    sm:  [
        { i: '1', x: 0, y: 0, w: 4, h: 7 },
        { i: '2', x: 0, y: 7, w: 4, h: 7 },
        { i: '3', x: 0, y: 14, w: 4, h: 7 },
        { i: '4', x: 0, y: 21, w: 4, h: 4 },
        { i: '5', x: 0, y: 25, w: 4, h: 8 },
    ],
}

export const VyneNodeDevice = ({ node }) => {

    useEffect(() => { }, [node])

    const request = new RequestBuilder(node.id);


    const handleSectionSave = (properties) => {
        request.sendSingleSection(properties);
    }


    return (
        <WidgetGrid layouts={gridLayout}>
            <div key='1'>
                <Identification deviceName={node.name} deviceId={node.id} />
            </div>
            <div key='2'>
                <Power voltage power={parseFloat((node.power.voltage / 1000).toFixed(1))} targetPower={node.type.targetVoltage} />
            </div>
            <div key='3'>
                <Power power={parseFloat((node.power.current / 1000).toFixed(1))} targetPower={node.type.targetCurrent} />
            </div>
            <div key='4'>
                <Version deviceVersion={node.version} />
            </div>
            <div key='5'>
                <VyneActions node={node} />
            </div>
        </WidgetGrid>
    );

}