import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import { WidgetWrapper } from '../../../WidgetWrapper';

import { RequestBuilder } from '../../../../../services/request.service';
import useDeviceErrorHandler from '../../../useDeviceErrorHandler';

const useStyles = makeStyles((theme) => ({
	checkbox: {
		padding: 0,
		marginRight: theme.spacing(1),
	},
	checkboxLabel: {
		marginLeft: 0,
		[theme.breakpoints.up('sm')]: {
			marginLeft: 'unset',
			marginRight: theme.spacing(3),
		},
	},
}));

export const PeblSleep = ({ pebl }) => {
	const { enqueueSnackbar } = useSnackbar();
	const errorHandler = useDeviceErrorHandler();
	const [saveEnabled, setSaveEnabled] = useState(true);
	const [duration, setDuration] = useState(1);
	const rb = new RequestBuilder(pebl.id);
	const classes = useStyles();

	const handleSectionSave = () => {
		setSaveEnabled(false);
		rb.addRequest('deviceSleep', 'W', { duration: duration * 60 });
		rb.send()
			.then(() => {
				enqueueSnackbar(
					`Device is going to sleep for ${
						duration !== 0
							? duration + ` minute${duration > 1 ? 's' : ''}`
							: 'forever'
					}`,
					{ variant: 'success' }
				);
			})
			.catch((res) => errorHandler(res))
			.finally(() => setSaveEnabled(true));
	};

	return (
		<WidgetWrapper
			title='Sleep'
			saveEnabled={saveEnabled}
			onSave={handleSectionSave}>
			<Grid container spacing={2} alignItems='center'>
				<Grid item xs={8}>
					<TextField
						fullWidth
						type='number'
						variant='outlined'
						label='period (sec)'
						value={duration !== 0 ? duration : ''}
						disabled={duration === 0}
						inputProps={{
							min: 1,
							max: 1800,
						}}
						onChange={(e) => {
							if(parseInt(e.target.value) > 30) {
								setDuration(30);
							} else if(parseInt(e.target.value) < 0) {
								setDuration(0);
							} else {
								setDuration(e.target.value);
							}
						}}
					/>
				</Grid>
				<Grid item xs={4}>
					<FormControlLabel
						className={classes.checkboxLabel}
						control={
							<Checkbox
								className={classes.checkbox}
								color='secondary'
								checked={duration === 0}
								onChange={() => {
									setDuration(duration !== 0 ? 0 : 1);
								}}
							/>
						}
						label='Forever'
					/>
				</Grid>
			</Grid>
		</WidgetWrapper>
	);
};
