import React, { useEffect } from 'react';

import Typography from '@material-ui/core/Typography';

import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { PeblDetails } from './gateway/pebl/PeblDetails';
import { VyneGatewayDetails } from './gateway/vyne/VyneGatewayDetails';
import { VyneNodeDetails } from './node/vyne/VyneNodeDetails';
import { deviceType } from './_deviceFunctions';

export const DeviceDetails = (device, classes) => {
	console.log('dev', device);
	if (device !== undefined && device.id) {
		let masterPart = device.partNumber.includes('-')
			? device.partNumber.split('-')[0]
			: device.partNumber;
		switch (deviceType[masterPart]) {
			case 'pebl':
				return <PeblDetails pebl={device} />;
			case 'vyne':
				return <VyneGatewayDetails vyne={device} />;
			default:
				if (device.type.type === 'foreign node') {
					return (
						<div className={classes.invalidNodeWrapper}>
							<div className={classes.invalidNode}>
								<ErrorOutlineOutlinedIcon className={classes.invalidNodeIcon} />
								<Typography>
									This node is latched to a Vyne network belonging to a
									different organisation, the node will be unavaliable to use
									until it has been unlatched from the current owning
									organisation
								</Typography>
							</div>
						</div>
					);
				} else {
					return <VyneNodeDetails node={device} />;
				}
		}
	} else {
		return (
			<div className={classes.noneSelected}>
				<Typography variant='h5'>Device Overview</Typography>
				<div className={classes.noneSelectedContent}>
					<div style={{ textAlign: 'center' }}>
						<InfoOutlinedIcon className={classes.noneSelectedIcon} />
						<Typography>Please select a device from the list</Typography>
					</div>
				</div>
			</div>
		);
	}
};
