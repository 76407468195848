import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import {
	FingotiLoading,
	FingotiHeader,
	FingotiButton,
	FingotiTable,
	URLGenerator,
} from '@fingoti/components';
import clsx from 'clsx';

import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReplayOutlinedIcon from '@material-ui/icons/ReplayOutlined';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { WebhookIcon } from '../../../icons/FingotIcons';

import { apiService } from '../../../services/api.service';
import { datetimeService } from '../../../services/datetime.service';
import { useProfileState } from '../../../context/ProfileContext';
import { useWebhookFunctions } from './_webhookFunctions';
import { useRoleCheck } from '../../utils/RoleCheck';

const detailTableStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
	noBottomBorder: {
		border: 'none',
	},
	headerCell: {
		border: 'none',
		textAlign: 'right',
		borderRight: '1px solid ' + theme.palette.greyFour.main,
		width: '30%',
		color: theme.palette.greyTwo.main,
	},
	verticalTop: {
		verticalAlign: 'top',
	},
	enabledIcon: {
		verticalAlign: 'middle',
		fill: theme.palette.success.main,
	},
	disabledIcon: {
		verticalAlign: 'middle',
		fill: theme.palette.error.main,
	},
	headerValue: {
		whiteSpace: 'normal',
		wordBreak: 'break-word',
	},
	chip: {
		marginRight: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
}));

const standardTableStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(3),
		width: '100%',
	},
	tableHead: {
		color: theme.palette.primary.main,
	},
	verticalTop: {
		verticalAlign: 'top',
	},
	noItemsRow: {
		textAlign: 'center',
		height: theme.spacing(16),
		borderBottom: 'none',
	},
	actionIcons: {
		display: 'flex',
		alignItems: 'center',
	},
	actionIcon: {
		padding: 0,
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
	},
	maxAttempts: {
		color: theme.palette.error.main,
	},
	allowWrap: {
		wordBreak: 'break-word',
		[theme.breakpoints.up('md')]: {
			wordBreak: 'normal',
		},
	},
}));

const WebhookInfo = ({ webhook, createdBy }) => {
	const classes = detailTableStyles();

	return (
		<TableContainer>
			<Table className={classes.root}>
				<TableBody>
					<TableRow key='1'>
						<TableCell className={classes.headerCell}>webhook no</TableCell>
						<TableCell className={classes.noBottomBorder}>
							{webhook.webhookNumber}
						</TableCell>
					</TableRow>
					<TableRow key='2'>
						<TableCell className={classes.headerCell}>enabled</TableCell>
						<TableCell className={classes.noBottomBorder}>
							{webhook.disabled ? (
								<BlockOutlinedIcon className={classes.disabledIcon} />
							) : (
								<CheckCircleOutlineIcon className={classes.enabledIcon} />
							)}
						</TableCell>
					</TableRow>
					<TableRow key='3'>
						<TableCell
							className={clsx(classes.headerCell, classes.verticalTop)}>
							devices
						</TableCell>
						<TableCell className={classes.noBottomBorder}>
							{webhook.devices.map((device) => {
								return <Chip className={classes.chip} label={device} />;
							})}
						</TableCell>
					</TableRow>
					<TableRow key='4'>
						<TableCell className={classes.headerCell}>property</TableCell>
						<TableCell className={classes.noBottomBorder}>
							{webhook.properties.map((property, i) =>
								i === 0 ? property : `, ${property}`
							)}
						</TableCell>
					</TableRow>
					<TableRow key='5'>
						<TableCell
							className={clsx(classes.headerCell, classes.verticalTop)}>
							url
						</TableCell>
						<TableCell className={classes.noBottomBorder}>
							{webhook.webhookUrl}
						</TableCell>
					</TableRow>
					<TableRow key='6'>
						<TableCell className={classes.headerCell}>last call</TableCell>
						<TableCell className={classes.noBottomBorder}>
							{webhook.lastCall
								? datetimeService.formatDateTime(new Date(webhook.lastCall))
								: 'Never'}
						</TableCell>
					</TableRow>
					<TableRow key='7'>
						<TableCell className={classes.headerCell}>created on</TableCell>
						<TableCell className={classes.noBottomBorder}>
							{datetimeService.formatDateTime(new Date(webhook.createdOn))}
						</TableCell>
					</TableRow>
					<TableRow key='8'>
						<TableCell className={classes.headerCell}>created by</TableCell>
						<TableCell className={classes.noBottomBorder}>
							{createdBy}
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};

const HeaderTable = ({ headers }) => {
	const classes = standardTableStyles();

	return (
		<Table className={classes.root}>
			<TableHead>
				<TableRow>
					<TableCell className={classes.tableHead}>key</TableCell>
					<TableCell className={classes.tableHead}>value</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{headers.map((header, i) => {
					return (
						<TableRow key={i}>
							<TableCell className={classes.verticalTop}>
								{header.key}
							</TableCell>
							<TableCell className={classes.allowWrap}>
								{header.value}
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		</Table>
	);
};

const CallsTable = ({ webhook }) => {
	const theme = useTheme();
	const classes = standardTableStyles();
	const { singleRetry, statusEnum } = useWebhookFunctions();
	const history = useHistory();
	const calls = webhook.calls;

	const handleCallInfoClick = (call) => {
		history.push(`/webhooks/logs`, {
			call: call,
			webhookNumber: webhook.webhookNumber,
			webhookId: webhook.id,
		});
	};

	const getCalls = (query) => {

		return apiService
			.getData(URLGenerator(query, `/webhook/${webhook.id}`))
			.then((data) => {
				return {
					data: data.webhooks[0].calls,
					page: data.webhooks[0].pageNumber - 1,
					totalCount: data.webhooks[0].count,
				};
			})
			.catch((e) => {
				console.error('get calls error', e);
			});
	};

	return (
		<FingotiTable
			columns={[
				{ title: 'id', field: 'id', hidden: true },
				{ title: 'count', field: 'count', hidden: true },
				{
					title: 'timestamp',
					field: 'timestamp',
					render: (rd) =>
						datetimeService.formatDateTime(new Date(rd.timestamp)),
					filtering: false,
				},
				{
					title: 'status',
					field: 'status',
					render: (rd) => statusEnum(rd.status),
					lookup: { 0: 'Queued', 1: 'Pending', 2: 'Failed', 3: 'Success' },
				},
				{ title: 'url', field: 'requestUrl', filtering: false },
				{ title: 'attmepts', render: (rd) => rd.attempts.length },
			]}
			actions={[
				(rowData) =>
					rowData.status === 2 && rowData.count < 10
						? {
								icon: ReplayOutlinedIcon,
								tooltip: 'retry call',
								onClick: () => singleRetry(rowData.id, webhook.id),
						  }
						: null,
				(rowData) => ({
					icon: InfoOutlinedIcon,
					tooltip: 'call info',
					onClick: () => handleCallInfoClick(rowData),
				}),
			]}
			data={getCalls}
		/>
	);

	// return (
	//     <TableContainer>
	//         <Table className={classes.root}>
	//             <TableHead>
	//                 <TableRow>
	//                     <TableCell className={classes.tableHead}>timestamp</TableCell>
	//                     <TableCell className={classes.tableHead}>status</TableCell>
	//                     <TableCell className={classes.tableHead}>url</TableCell>
	//                     <TableCell className={classes.tableHead}>attempts</TableCell>
	//                     <TableCell className={classes.tableHead}>actions</TableCell>
	//                 </TableRow>
	//             </TableHead>
	//             <TableBody>
	//                 {
	//                     calls.length === 0 ?
	//                         <TableRow>
	//                             <TableCell colSpan={4} className={classes.noItemsRow}>
	//                                 This webhook hasn't been called yet
	//                             </TableCell>
	//                         </TableRow>
	//                         :
	//                         calls.map(call => {
	//                             return (
	//                                 <TableRow key={call.id}>
	//                                     <TableCell style={{ minWidth: 'max-content' }}>{datetimeService.formatDateTime(new Date(call.timestamp))}</TableCell>
	//                                     <TableCell>{statusEnum(call.status)}</TableCell>
	//                                     <TableCell>{call.requestUrl}</TableCell>
	//                                     <TableCell className={call.count === 10 ? classes.maxAttempts : ""}>{call.count}</TableCell>
	//                                     <TableCell>
	//                                         <div className={classes.actionIcons}>
	//                                             <Tooltip title="call info">
	//                                                 <IconButton className={classes.actionIcon} onClick={() => handleCallInfoClick(call)}>
	//                                                     <InfoOutlinedIcon />
	//                                                 </IconButton>
	//                                             </Tooltip>
	//                                             {
	//                                                 call.status === 2 && call.count < 10 ?
	//                                                     <Tooltip title="retry call">
	//                                                         <IconButton className={classes.actionIcon} onClick={() => singleRetry(call.id, webhook.id)}>
	//                                                             <ReplayOutlinedIcon />
	//                                                         </IconButton>
	//                                                     </Tooltip>
	//                                                     :
	//                                                     ""
	//                                             }
	//                                         </div>
	//                                     </TableCell>
	//                                 </TableRow>
	//                             )
	//                         })

	//                 }
	//             </TableBody>
	//         </Table>
	//     </TableContainer >
	// )
};

export const WebhookDetails = ({ webhookId }) => {
	const { enqueueSnackbar } = useSnackbar();
	const { loading, users } = useProfileState();
	const history = useHistory();
	const [thisLoading, setThisLoading] = useState(true);
	const [webhook, setWebhook] = useState({});
	const [createdBy, setCreatedBy] = useState({});
	const { multiRetry } = useWebhookFunctions();
	const { roleCheck } = useRoleCheck();

	useEffect(() => {
		if (!loading) {
			setThisLoading(true);
			apiService
				.getData(`/webhook/${webhookId}`)
				.then((data) => {
					setWebhook(data.webhooks[0]);
					let createdBy = users.find((user) => user.id === data.webhooks[0].createdBy);
					console.log("cre", createdBy);
					if (createdBy) {
						setCreatedBy(`${createdBy.forename} ${createdBy.surname}`);
					} else {
						setCreatedBy('N/A');
					}
					setThisLoading(false);
				})
				.catch((error) => {
					history.push('/webhooks/logs');
					enqueueSnackbar(error, { variant: 'error' });
					setThisLoading(false);
				});
		}
	}, [loading]);

	const breadcrumbs = [
		{ text: 'Webhooks', link: '/webhooks' },
		{ text: webhook.webhookNumber, link: '' },
	];

	const actionButtons = () => {
		return (
			<React.Fragment>
				<FingotiButton
					light
					disabled={true}
					color='primary'
					onClick={() => multiRetry([webhook])}>
					retry all
				</FingotiButton>
			</React.Fragment>
		);
	};

	return thisLoading ? (
		<FingotiLoading />
	) : (
		<>
			<FingotiHeader
				breadcrumbs={breadcrumbs}
				actionButtons={actionButtons()}
				sectionIcon={WebhookIcon}
			/>
			<Grid container spacing={4}>
				<Grid item xs={12} md={4}>
					<Typography variant='h5'>Details</Typography>
					<WebhookInfo webhook={webhook} createdBy={createdBy} />
				</Grid>
				<Grid item xs={12} md={8}>
					<Typography variant='h5'>Headers</Typography>
					<HeaderTable headers={webhook.webhookHeaders} />
				</Grid>
				<Grid item xs={12}>
					<Box mb={2}>
						<Typography variant='h5'>Calls</Typography>
					</Box>
					<CallsTable webhook={webhook} />
				</Grid>
			</Grid>
		</>
	);
};
