import React from 'react';
import { useSnackbar } from 'notistack';

import { RequestBuilder } from '../../../../services/request.service';
import { WifiCredentials } from '../../shared/widgets/wifi/WifiCredentials';
import { WifiSignal } from '../../shared/widgets/wifi/WifiSignal';
import { WidgetGrid } from '../../WidgetGrid';
import useDeviceErrorHandler from '../../useDeviceErrorHandler';

const gridLayout = {
	xl: [
		{ i: '1', x: 0, y: 0, w: 3, h: 7 },
		{ i: '2', x: 3, y: 0, w: 3, h: 7 },
	],
	lg: [
		{ i: '1', x: 0, y: 0, w: 3, h: 7 },
		{ i: '2', x: 3, y: 0, w: 3, h: 7 },
	],
	md: [
		{ i: '1', x: 0, y: 0, w: 3, h: 7 },
		{ i: '2', x: 0, y: 14, w: 3, h: 7 },
	],
	sm: [
		{ i: '1', x: 0, y: 0, w: 4, h: 7 },
		{ i: '2', x: 0, y: 0, w: 4, h: 7 },
	],
};

export const PeblWifi = ({ pebl }) => {
	const errorHandler = useDeviceErrorHandler();
	const { enqueueSnackbar } = useSnackbar();

	const rb = new RequestBuilder(pebl.id);

	const handleWifiSave = (wifiCredentials) => {
		if (wifiCredentials.primaryPass !== '') {
			rb.addRequest('wifiCredentials', 'W', {
				slot: 0,
				ssid: wifiCredentials.primarySSID,
				password: wifiCredentials.primaryPass,
			});
		}

		if (wifiCredentials.secondaryPass !== '') {
			rb.addRequest('wifiCredentials', 'W', {
				slot: 1,
				ssid: wifiCredentials.secondarySSID,
				password: wifiCredentials.secondaryPass,
			});
		}

		rb.sendSingleSection(['wifiCredentials'])
			.then(() => {
				enqueueSnackbar('Wifi credentials successfully changed', {
					variant: 'success',
				});
			})
			.catch((res) => errorHandler(res));
	};

	return (
		<WidgetGrid layouts={gridLayout}>
			<div key='1'>
				<WifiCredentials
					device={pebl}
					eventHandlers={{
						sectionSave: handleWifiSave,
					}}
				/>
			</div>
			<div key='2'>
				<WifiSignal signalLevel={pebl.wifi.status.signal} device={pebl} />
			</div>
		</WidgetGrid>
	);
};
