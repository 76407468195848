import { gatewayType, nodeType } from '../components/device/_deviceFunctions';

export const deviceProcessor = (pl) => {

    pl.peblGateways.forEach(gw => {
        let masterPart = gw.partNumber.includes("-") ? gw.partNumber.split("-")[0] : gw.partNumber;

        gw.type = gatewayType[masterPart];
    })

    pl.vyneGateways.forEach(gw => {
        let masterPart = gw.partNumber.includes("-") ? gw.partNumber.split("-")[0] : gw.partNumber;

        gw.type = gatewayType[masterPart];

        gw.node.detect.forEach(nd => {
            let node = pl.vyneNodes.find(vn => vn.id === nd.serial)

            if (!node) {
                //forign node, fake the node for display
                let fakeNode = {
                    gatewayId: gw.id,
                    id: nd.serial,
                    type: "foreign node",
                    nodeName: "Foreign Node",
                    partNumber: "000000",
                    nodeEnabled: true,
                    nodeAddress: {
                        static: false,
                        address: nd.address
                    },
                }

                pl.vyneNodes.push(fakeNode);
            }

        })
    })

    pl.vyneNodes.forEach(vn => {
        vn.type = nodeType[vn.partNumber];
        let parent = pl.vyneGateways.find(gw => gw.id === vn.gatewayId);
        if(typeof parent !== 'undefined') {
            vn.online = parent.node.detect.some(pnd => pnd.serial === vn.id);
        } else {
            vn.online = false;
        }
    })

    return pl;
}

export const deviceUpdater = (state, pl) => {

    const allNodes = [ ...state.vyneNodes, ...pl.vyneNodes ];

    pl.peblGateways.forEach(dev => {
        let target = state.peblGateways.find(sd => sd.id === dev.id);

        dev.type = gatewayType[dev.partNumber];

        if (!target) {
            state.peblGateways.push(dev);
        } else {
            Object.assign(target, dev);
        }

    })

    pl.vyneGateways.forEach(dev => {
        let target = state.vyneGateways.find(sd => sd.id === dev.id);

        dev.type = gatewayType[dev.partNumber];
        
        dev.node.detect.forEach(nd => {
            let node = allNodes.find(vn => vn.id === nd.serial)

            if (!node) {
                //forign node, fake the node for display
                let fakeNode = {
                    gatewayId: dev.id,
                    id: nd.serial,
                    type: "foreign node",
                    nodeName: "Foreign Node",
                    partNumber: "000000",
                    nodeEnabled: true,
                    nodeAddress: {
                        static: false,
                        address: nd.address
                    },
                }

                pl.vyneNodes.push(fakeNode);
            }

        })

        if (!target) {
            state.vyneGateways.push(dev);
        } else {
            Object.assign(target, dev);
        }

    })

    pl.vyneNodes.forEach(dev => {
        let target = state.vyneNodes.find(sd => sd.id === dev.id);

        dev.type = nodeType[dev.partNumber];

        if (!target) {
            state.vyneNodes.push(dev);
        } else {
            Object.assign(target, dev);
        }
    })

    state.vyneNodes.forEach(vn => {

        let parent = state.vyneGateways.find(gw => gw.id === vn.gatewayId);
        if(typeof parent !== 'undefined') {
            vn.online = parent.node.detect.some(pnd => pnd.serial === vn.id);
        } else {
            vn.online = false;
        }

    })

    let invalidNodes = state.vyneNodes.filter(vn => vn.partNumber === "000000");
    if (invalidNodes.length > 0) {
        invalidNodes.forEach(inv => {
            let parent = state.vyneGateways.find(gw => gw.id === inv.gatewayId);
            let isPresent = parent.nodeDetect.some(nd => nd.serial === inv.id);
            if (!isPresent) {
                let removeIndex = state.vyneNodes.findIndex(vn => vn.id === inv.id);
                state.vyneNodes.splice(removeIndex, 1);
            }
        })
    }

    state.onlineGateways = pl.onlineGateways;
    state.claimPendingCount = pl.claimPendingCount;
    state.ownedDeviceCount = pl.ownedDeviceCount;

    return state;
}