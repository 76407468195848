import React, { useState, useRef, useEffect, useReducer } from 'react';
import {
	FingotiButton,
	FingotiDelete,
	FingotiLoading,
	FingotiModalActions,
	FingotiModalContent,
} from '@fingoti/components';
import { useSnackbar } from 'notistack';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Popper from '@material-ui/core/Popper';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useDeviceState } from '../../../context/DeviceContext';
import { apiService } from '../../../services/api.service';
import { cdnUrl } from '../../../services/config';

const defaultHeaders = [
	'A-IM',
	'Accept-Charset',
	'Accept-Encoding',
	'Accept-Language',
	'Accept-Datetime',
	'Access-Control-Request-Method',
	'Access-Control-Request-Headers',
	'Authorization',
	'Cache-Control',
	'Cookie',
	'Forwarded',
	'From',
	'If-Match',
	'If-None-Match',
	'If-Range',
	'If-Unmodified-Since',
	'Max-Forwards',
	'Origin',
	'Pragma',
	'Proxy-Authorization',
	'Upgrade',
	'Via',
	'Warning',
];

const useStyles = makeStyles((theme) => ({
	accordRoot: {
		'&::before': {
			backgroundColor: '#FFF',
		},
	},
	accord: {
		border: '1px solid' + theme.palette.greyFour.main,
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'space-evenly',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
	},
	button: {
		width: '42%',
	},
}));

const standardTableStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	tableHead: {
		color: theme.palette.primary.main,
		backgroundColor: '#FFF',
	},
	verticalTop: {
		verticalAlign: 'top',
	},
	lastRow: {
		textAlign: 'left',
		borderBottom: 'none',
	},
	actionIcon: {
		padding: 0,
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
	},
	inputField: {
		minWidth: '50vw',
		[theme.breakpoints.up('md')]: {
			minWidth: 'auto',
		},
	},
}));

const CustomPopper = (props) => {
	return <Popper {...props} style={{ ...props.style, zIndex: 1400 }} />;
};

const BasicSettings = ({ childRef, setCurrentDevices }) => {
	const { peblGateways, vyneGateways } = useDeviceState();
	const [url, setUrl] = useState('https://');
	const [selectedProperties, setSelectedProperties] = useState([]);
	const [disabled, setDisabled] = useState(false);
	const [devices, setDevices] = useState([]);
	const [selectedDevices, setSelectedDevices] = useState([]);
	const [thisLoading, setThisLoading] = useState(true);
	const [properties, setProperties] = useState([]);
	const [propertiesLoading, setPropertiesLoading] = useState(true);
	const [propertiesError, setPropertiesError] = useState(false);

	useEffect(() => {
		childRef.current = () => ({
			devices: selectedDevices,
			disabled: disabled,
			url: url,
			properties: selectedProperties,
			reset: resetFields,
		});
		setDevices([...peblGateways, ...vyneGateways]);
		setThisLoading(false);
	}, [url, selectedProperties, peblGateways, vyneGateways, selectedDevices]);

	useEffect(() => {
		getProperties();
	}, []);

	const getProperties = () => {
		fetch(`${cdnUrl}/static/metadata/properties_v1.0.json`)
			.then(async (response) => {
				if (!response.ok) {
					throw Error('Error getting properties');
				} else {
					const data = await response.json();
					return data;
				}
			})
			.then((data) => {
				setProperties(data.properties);
				setPropertiesLoading(false);
			})
			.catch((e) => {
				setPropertiesError(true);
				setPropertiesLoading(false);
			});
	};

	const resetFields = () => {
		setUrl('');
		setSelectedProperties([]);
		setSelectedDevices([]);
	};

	return thisLoading ? (
		<FingotiLoading />
	) : (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<TextField
					fullWidth
					variant='outlined'
					name='url'
					id='url'
					value={url}
					onChange={(e) => setUrl(e.target.value)}
					label='url'
				/>
			</Grid>
			<Grid item xs={12}>
				{propertiesLoading ? (
					<FingotiLoading />
				) : !propertiesError ? (
					<Autocomplete
						multiple
						options={properties}
						value={selectedProperties}
						filterSelectedOptions
						onChange={(e, v) => {
							setSelectedProperties(v);
						}}
						renderInput={(params) => (
							<TextField variant='outlined' label='properties' {...params} />
						)}
						renderTags={(tags, tagProps) =>
							tags.map((tag, i) => (
								<Chip {...tagProps({ index: `${i}` })} label={tag} />
							))
						}
						PopperComponent={CustomPopper}
					/>
				) : (
					'Error getting properties'
				)}
			</Grid>
			<Grid item xs={12}>
				<Autocomplete
					multiple
					options={devices}
					getOptionLabel={(option) => `${option.device.name} - ${option.id}`}
					filterSelectedOptions
					onChange={(e, v) => {
						setCurrentDevices(v);
						setSelectedDevices(v);
					}}
					renderInput={(params) => (
						<TextField variant='outlined' label='devices' {...params} />
					)}
					renderTags={(tags, tagProps) =>
						tags.map((tag, i) => (
							<Chip {...tagProps({ index: `${i}` })} label={tag.id} />
						))
					}
					PopperComponent={CustomPopper}
				/>
			</Grid>
			<Grid item xs={12}>
				<FormControlLabel
					control={
						<Switch
							checked={!disabled}
							onChange={() => setDisabled(!disabled)}
							color='primary'
						/>
					}
					label='Enabled'
				/>
			</Grid>
		</Grid>
	);
};

const headerReducer = (state, action) => {
	switch (action.type) {
		case 'CHANGE_KEY':
			if (action.payload.event) {
				const t = action.payload.event.target;

				if (action.payload.event.type === 'click') {
					let idParts = t.id.split('-');
					state[idParts[0]].key = t.innerText;
				} else {
					state[t.id][t.name] = t.value;
				}

				return [...state];
			} else {
				return [...state];
			}
		case 'CHANGE_VALUE':
			const t = action.payload.event.target;
			state[t.id][t.name] = t.value;
			return [...state];
		case 'ADD_ROW':
			return [...state, { rowKey: Date.now(), key: '', value: '' }];
		case 'REMOVE_ROW':
			state.splice(action.payload.index, 1);
			return [...state];
		case 'RESET':
			return [{ rowKey: Date.now(), key: '', value: '' }];
		default:
			console.error('Invalid action type passed to headerReducer');
			return [...state];
	}
};

const HeaderTable = ({ childRef }) => {
	const classes = standardTableStyles();
	const [headers, setHeaders] = useReducer(headerReducer, [
		{ rowKey: Date.now(), key: '', value: '' },
	]);

	useEffect(() => {
		childRef.current = () => ({ headers: getHeaders, reset: resetFields });
	});

	const resetFields = () => {
		setHeaders({ action: 'RESET' });
	};

	const getHeaders = () => {
		let cleanHeaders = [];

		headers.forEach((header) => {
			if (header.key === '') return;

			if (header.value === '') header.value = null;

			if (header.rowKey) delete header.rowKey;

			cleanHeaders.unshift(header);
		});

		return cleanHeaders;
	};

	return (
		<TableContainer style={{ maxHeight: 500 }}>
			<Table className={classes.root}>
				<TableHead>
					<TableRow>
						<TableCell width='50%' className={classes.tableHead}>
							key
						</TableCell>
						<TableCell width='45%' className={classes.tableHead}>
							value
						</TableCell>
						<TableCell width='5%' className={classes.tableHead}>
							actions
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody className={classes.scrollTableBody}>
					{headers.map((header, i) => {
						return (
							<TableRow key={`${header.rowKey}`}>
								<TableCell>
									<Autocomplete
										freeSolo
										id={`${i}`}
										disableClearable
										options={defaultHeaders}
										value={header.key}
										name='key'
										size='small'
										className={classes.inputField}
										onChange={(e) =>
											setHeaders({ type: 'CHANGE_KEY', payload: { event: e } })
										}
										renderInput={(params) => (
											<TextField
												{...params}
												fullWidth
												variant='outlined'
												name='key'
											/>
										)}
										PopperComponent={CustomPopper}
									/>
								</TableCell>
								<TableCell>
									<TextField
										fullWidth
										variant='outlined'
										size='small'
										value={header.value}
										className={classes.inputField}
										name='value'
										id={`${i}`}
										onChange={(e) =>
											setHeaders({
												type: 'CHANGE_VALUE',
												payload: { event: e },
											})
										}
									/>
								</TableCell>
								<TableCell>
									<FingotiDelete
										className={classes.actionIcon}
										onClick={() =>
											setHeaders({ type: 'REMOVE_ROW', payload: { index: i } })
										}
									/>
								</TableCell>
							</TableRow>
						);
					})}
					<TableRow>
						<TableCell colSpan={2} className={classes.lastRow}>
							<FingotiButton
								light
								color='primary'
								onClick={() => setHeaders({ type: 'ADD_ROW' })}>
								add header
							</FingotiButton>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export const WebhookAdd = ({ onAddSuccess, setOpen }) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [expanded, setExpanded] = useState('p1');
	const [currentDevices, setCurrentDevices] = useState([]);
	const settingsRef = useRef(null);
	const headersRef = useRef(null);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const handleSubmit = () => {
		let headers = headersRef.current();
		let settings = settingsRef.current();
		let devices = [];

		settings.devices.forEach((device) => devices.unshift(device.id));

		let data = {
			disabled: settings.disabled,
			webhookUrl: settings.url,
			webhookHeaders: headers.headers(),
			properties: settings.properties,
			devices: devices,
		};

		apiService
			.postData('/webhook', data)
			.then((result) => {
				enqueueSnackbar(result.message, { variant: 'success' });
				settings.reset();
				headers.reset();
				onAddSuccess();
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
			});
	};

	const handleCancel = () => {
		settingsRef.current().reset();
		headersRef.current().reset();
		setOpen(false);
	};

	return (
		<>
			<FingotiModalContent>
				<div>
					<Accordion
						square
						elevation={0}
						classes={{ root: classes.accordRoot }}
						className={classes.accord}
						expanded={expanded === 'p1'}
						onChange={handleChange('p1')}>
						<AccordionSummary
							className={classes.accordHead}
							expandIcon={<ExpandMoreIcon />}>
							<Typography>Settings</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<BasicSettings
								setCurrentDevices={setCurrentDevices}
								childRef={settingsRef}
							/>
						</AccordionDetails>
					</Accordion>
					<Accordion
						square
						elevation={0}
						classes={{ root: classes.accordRoot }}
						className={classes.accord}
						expanded={expanded === 'p2'}
						onChange={handleChange('p2')}>
						<AccordionSummary
							className={classes.accordHead}
							expandIcon={<ExpandMoreIcon />}>
							<Typography>Headers</Typography>
						</AccordionSummary>
						<AccordionDetails className={classes.accordTable}>
							<HeaderTable childRef={headersRef} />
						</AccordionDetails>
					</Accordion>
				</div>
			</FingotiModalContent>
			<FingotiModalActions>
				<div className={classes.buttonGroup}>
					<FingotiButton
						light
						color='primary'
						className={classes.button}
						disabled={currentDevices.length > 0 ? false : true}
						onClick={handleSubmit}>
						SAVE
					</FingotiButton>
					<FingotiButton
						light
						className={classes.button}
						onClick={handleCancel}>
						CANCEL
					</FingotiButton>
				</div>
			</FingotiModalActions>
		</>
	);
};
