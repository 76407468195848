import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';
import { useSnackbar } from 'notistack';

export default function useDeviceErrorHandler() {
	const { enqueueSnackbar } = useSnackbar();

	const errorHandler = (response) => {
		if (Array.isArray(response)) {
			response
				.filter((r) => !r.success)
				.forEach((e) => {
					enqueueSnackbar(`Error ${e.error.code} - ${e.error.message}`, {
						variant: 'error',
					});
				});
		} else {
			enqueueSnackbar(`${response}`, {
				variant: 'error',
			});
		}
	};

	return errorHandler;
}
