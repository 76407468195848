import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { FingotiNotFound, FingotiLoading } from '@fingoti/components';

import Layout from './components/Layout';
import { Dashboard } from './components/dashboard/Dashboard';
import { DeviceTabs } from './components/device/DeviceTabs';
import { GatewayTabs } from './components/device/gateway/GatewayTabs';
import { NodeTabs } from './components/device/node/NodeTabs';
import { Webhooks } from './components/engine/webhooks/Webhooks';
import { Settings } from './components/settings/Settings';

import { deviceHub } from './services/signalr.service';
import { authService } from './services/auth.service';
import { useProfileDispatch, useProfileState } from './context/ProfileContext';
import { ProfileData } from './context/ProfileData';
import { accountUrl } from './services/config';
import 'react-json-pretty/themes/monikai.css';
import './main.css';
import { ReportTabs } from './components/report/ReportTabs';

const PrivateRoute = ({ children, ...rest }) => {
	const { loggedIn, loading } = useProfileState();

	//not logged in so redirect to login page with the return url
	if (!loggedIn & !loading) {
		console.log('no user');
		window.location.assign(`${accountUrl}?ReturnUrl=${window.location}`);
	}

	if (loggedIn & !loading) {
		// authorised so return component
		return <Route {...rest}>{children}</Route>;
	}

	return <FingotiLoading fullHeight />
};

const App = () => {
	const profileData = ProfileData();
	const dispatch = useProfileDispatch();
	const [cookies, setCookie] = useCookies();

	const handleVisibilityChange = (e) => {
		if (document.visibilityState === 'visible') {
			authService.findAOCookie().then((aoCookie) => {
				authService.findAppToken().then((appToken) => {
					console.log('aoCookie', aoCookie);
					console.log('oid', appToken.oid);
					if (aoCookie !== appToken.oid) {
						localStorage.setItem('switchingOrganisation', true);
						deviceHub.invoke('RemoveFromOrganisation').then((res) => {
							console.log(`${res.message}`);
							console.log('res from remove from org', res);
							deviceHub.stop();
							authService.getAppToken().then(() => {
								profileData.getData();
							});
						});
					}
				});
			});
		}
	};

	document.addEventListener('visibilitychange', handleVisibilityChange, false);

	useEffect(() => {
		console.log('Cookies');
		console.log(cookies.ActiveOrganisation);
		if (cookies.ActiveOrganisation) {
			authService
				.findAppToken()
				.then((appToken) => {
					let hasExpired =
						Date.now() / 1000 > new Date(appToken.exp) ? true : false;
					if (!hasExpired) {
						if (cookies.ActiveOrganisation !== appToken.oid) {
							authService.getAppToken().then(() => {
								profileData.getData();
							});
						} else {
							dispatch({ type: 'LOGGED_IN' });
							profileData.getData();
						}
					} else {
						authService
							.getAppToken()
							.then(() => {
								profileData.getData();
							})
							.catch(() => {
								dispatch({ type: 'LOADED' });
							});
					}
				})
				.catch(() => {
					authService
						.getAppToken()
						.then(() => {
							profileData.getData();
						})
						.catch(() => {
							dispatch({ type: 'LOADED' });
						});
				});
		} else {
			dispatch({ type: 'LOADED' });
			// authService.findAppToken()
			//   .then(appToken => {
			//     setCookie("ActiveOrganisation", appToken.oid, cookieSettings());
			//     let hasExpired = Date.now() / 1000 > new Date(appToken.exp) ? true : false;
			//     if (!hasExpired) {
			//       dispatch({ type: "LOGGED_IN" })
			//       profileData.getData();
			//     } else {
			//       authService.getAppToken()
			//         .then(() => {
			//           profileData.getData();
			//         })
			//         .catch(() => {
			//           dispatch({ type: 'LOADED' });
			//         })
			//     }
			//   })
			//   .catch(() => {
			//     dispatch({ type: 'LOADED' });
			//   })
		}
	}, []);

	return (
		<div className='App'>
			<Layout>
				<Switch>
					<PrivateRoute exact path='/'>
						{/* {
              !loggedIn ?
                <FingotiLoading />
                :
                <Redirect to='/devices' />
            } */}
						<Dashboard />
					</PrivateRoute>
					<PrivateRoute exact path='/devices'>
						<DeviceTabs activeMenu={0} />
					</PrivateRoute>
					<PrivateRoute exact path='/devices/gateways'>
						<DeviceTabs activeMenu={1} />
					</PrivateRoute>
					<PrivateRoute exact path='/devices/gateways/:id'>
						<GatewayTabs />
					</PrivateRoute>
					<PrivateRoute exact path='/devices/nodes'>
						<DeviceTabs activeMenu={2} />
					</PrivateRoute>
					<PrivateRoute exact path='/devices/nodes/:id'>
						<NodeTabs />
					</PrivateRoute>

					<PrivateRoute exact path='/webhooks'>
						<Webhooks activeMenu={0} />
					</PrivateRoute>
					<PrivateRoute exact path='/webhooks/logs'>
						<Webhooks activeMenu={1} />
					</PrivateRoute>
					<PrivateRoute exact path='/webhooks/:whid'>
						<Webhooks activeMenu={0} />
					</PrivateRoute>

					<PrivateRoute exact path='/settings'>
						<Settings activeMenu={0} />
					</PrivateRoute>
					<PrivateRoute exact path='/settings/presets'>
						<Settings activeMenu={1} />
					</PrivateRoute>
					<PrivateRoute exact path='/settings/following'>
						<Settings activeMenu={2} />
					</PrivateRoute>

					<PrivateRoute exact path='/reports'>
						<ReportTabs activeMenu={0} />
					</PrivateRoute>
					<PrivateRoute exact path='/reports/command-log'>
						<ReportTabs activeMenu={1} />
					</PrivateRoute>

					{/* Keep at bottom */}
					<Route component={FingotiNotFound} />
				</Switch>
			</Layout>
		</div>
	);
};

export default App;
