import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { WidgetWrapper } from '../../../WidgetWrapper';
import { RequestBuilder } from '../../../../../services/request.service';

const useStyles = makeStyles(theme => ({

    address: {
        width: '75%',
    }

}))

export const VyneTriggerPin = ({ node }) => {

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [triggerPin, setTriggerPin] = useState(((node.nodeSetup >>> 8) & 15) + 1);
    const rb = new RequestBuilder(node.gatewayId);

    useEffect(() => {}, [node.nodeSetup])

    const enableSave = () => {
        if (saveEnabled) {
            return;
        }

        setSaveEnabled(true);
    }

    const handleSetSetup = () => {
        let setup = (node.nodeSetup & 61695) | ((triggerPin - 1) << 8);

        rb.addRequest("nodeSetup", "W", {
            serial: node.id,
            setup: setup
        });

        rb.sendSingleSection(["nodeSetup"])
            .then(() => {
                enqueueSnackbar("Node setup set succesfully", { variant: 'success' });
            })
            .catch(errors => {
                if (errors && Array.isArray(errors)) {
                    errors.forEach(error => {
                        enqueueSnackbar(`Error ${error.result.code} - ${error.result.message}`, { variant: 'error' });
                    })
                } else {
                    enqueueSnackbar(errors, { variant: 'error' });
                }
            });
            
        setSaveEnabled(false);
    }

    return (
        <WidgetWrapper title="Trigger Control" saveEnabled={saveEnabled} onSave={handleSetSetup}>
                <Typography>pin to toggle</Typography>
                <TextField
                    id="nodeAddress"
                    type="number"
                    InputProps={{ inputProps: { min: 1, max: 4 } }}
                    value={triggerPin}
                    onChange={(e) => {
                        setTriggerPin(e.target.value);
                        enableSave();
                    }}
                    variant="outlined"
                    className={classes.address}
                />
        </WidgetWrapper >
    );

}