import React, { useEffect, useState } from 'react';
import { useRouteMatch, useHistory, Link as RouterLink } from 'react-router-dom';
import { FingotiLoading, FingotiTabs } from '@fingoti/components';

import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';

import { Configs } from './configs/Configs';
import { General } from './general';
import { Following } from './following/Following';

const useStyles = makeStyles((theme) => ({

    root: {
        width: '100%',
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: '100%'
    },
    tab: {
        width: '100%',
    },
    tabRoot: {
        minWidth: 'unset',
    },
    tabLabelRight: {
        alignItems: 'unset',
        textAlign: 'right',
        paddingRight: theme.spacing(1),
    },
    boxPadding: {
        padding: theme.spacing(6),
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
        }
    },
}));

const TabPanel = (props) => {

    const { passClass, children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div className={passClass}>
                    {children}
                </div>
            )}
        </div>
    );
}

const RouterTab = (props) => <Tab component={RouterLink} {...props} />

export const Settings = ({ activeMenu }) => {

    const classes = useStyles();
    const match = useRouteMatch();
    const history = useHistory();
    const [value, setValue] = useState(activeMenu)

    useEffect(() => {
        setValue(activeMenu);
    });

    const handleChange = (event, newValue) => {

        let isTwoPartPath = match.path.indexOf('/', 1);
        let baseRoute = "/settings";

        if (isTwoPartPath !== -1) {
            //Path is /foo/bar, need to get rid of the /bar
            baseRoute = match.path.slice(0, isTwoPartPath)
        }

        switch (newValue) {
            case 0:
                history.push(`${baseRoute}`);
                break;
            case 1:
                history.push(`${baseRoute}/presets`);
                break;
            case 2:
                history.push(`${baseRoute}/pinfollowing`);
                break;
            default:
                console.log("not a valid value");
                break;
        }

        setValue(newValue);

    };

    const tabProps = (index) => {

        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
            classes: {
                wrapper: classes.tabLabelRight,
                root: classes.tabRoot
            }
        };

    }

    return (
        value === null ?
            <FingotiLoading />
            :
            <div className={classes.root}>
                <FingotiTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="settings tabs"
                >
                    <RouterTab to={'/settings'} label="General" {...tabProps(0)} />,
                    <RouterTab to={'/settings/presets'} label="Device Presets" {...tabProps(1)} />
                    <RouterTab to={'/settings/following'} label="Following" {...tabProps(2)} />
                </FingotiTabs>

                <TabPanel passClass={classes.boxPadding} className={classes.tab} value={value} index={0}>
                    <General />
                </TabPanel>

                <TabPanel passClass={classes.boxPadding} className={classes.tab} value={value} index={1}>
                    <Configs />
                </TabPanel>

                <TabPanel passClass={classes.boxPadding} className={classes.tab} value={value} index={2}>
                    <Following />
                </TabPanel>
            </div>
    );


}