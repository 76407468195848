import React, { useEffect, useState } from 'react';

import { WidgetGrid } from '../WidgetGrid';
import { ScheduleActions } from '../shared/widgets/ScheduleActions';
import { ScheduleSlot } from '../shared/widgets/ScheduleSlot';
import { ScheduleRequest } from '../shared/widgets/ScheduleRequest';
import { ScheduleCron } from '../shared/widgets/ScheduleCron';

const gridLayout = {
    xl: [
        { i: '1', x: 0, y: 0, w: 3, h: 4 },
        { i: '2', x: 3, y: 0, w: 4, h: 4 },
        { i: '3', x: 3, y: 4, w: 4, h: 10 },
        { i: '4', x: 0, y: 4, w: 3, h: 5 },
    ],
    lg:  [
        { i: '1', x: 0, y: 0, w: 3, h: 4 },
        { i: '2', x: 3, y: 0, w: 4, h: 4 },
        { i: '3', x: 3, y: 4, w: 4, h: 10 },
        { i: '4', x: 0, y: 4, w: 3, h: 5 }
    ],
    md:  [
        { i: '1', x: 0, y: 0, w: 3, h: 4 },
        { i: '2', x: 3, y: 0, w: 3, h: 5 },
        { i: '3', x: 3, y: 4, w: 3, h: 10 },
        { i: '4', x: 0, y: 4, w: 3, h: 5 }
    ],
    sm:  [
        { i: '1', x: 0, y: 0, w: 4, h: 4 },
        { i: '2', x: 0, y: 9, w: 4, h: 5 },
        { i: '3', x: 0, y: 14, w: 4, h: 10 },
        { i: '4', x: 0, y: 4 , w: 4, h: 5},
    ],
}

export const GatewaySchedule = ({ device }) => {

    const [selectedSlot, setSelectedSlot] = useState(0);

    useEffect(() => {}, [device, selectedSlot])

    return (
        <WidgetGrid layouts={gridLayout}>
            <div key='1'>
                <ScheduleSlot slot={selectedSlot} setSelectedSlot={setSelectedSlot} />
            </div>
            <div key='2'>
                <ScheduleCron device={device} slot={selectedSlot} />
            </div>
            <div key='3'>
                <ScheduleRequest device={device} slot={selectedSlot} />
            </div>
            <div key='4'>
                <ScheduleActions device={device} slot={selectedSlot} />
            </div>
        </WidgetGrid>
    );

}