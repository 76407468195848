import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';

import { WidgetWrapper } from '../../WidgetWrapper';
import { RequestBuilder } from '../../../../services/request.service';
import useDeviceErrorHandler from '../../useDeviceErrorHandler';

const useStyles = makeStyles(theme => ({

    fields: {
        margin: '0px auto 0px auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    field: {
        width: '32%',
    }

}))

export const TimerInterval = ({ device }) => {

    const errorHandler = useDeviceErrorHandler();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [hours, setHours] = useState(0);
    const [mins, setMins] = useState(0);
    const [secs, setSecs] = useState(0);
    const [minSecDisabled, setMinSecDisabled] = useState(false);
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [updates, setUpdates] = useState(true);
    const rb = new RequestBuilder(device.id);

    useEffect(() => {
        if (updates && !saveEnabled) {
            secondsToTime(device.timer.interval);
        }
    }, [device])

    useEffect(() => {
        if (saveEnabled) {
            if (parseInt(hours) === 0 && parseInt(mins) === 0 && parseInt(secs) === 0) {
                setSecs("1");
            }
        }
    }, [hours, mins, secs])

    const secondsToTime = (secs) => {
        var h = Math.floor(secs / 3600).toString(),
            m = Math.floor(secs % 3600 / 60).toString(),
            s = Math.floor(secs % 60).toString();

        setHours(h);
        setMins(m);
        setSecs(s);
    }

    const enableSave = () => {
        if (saveEnabled) {
            return;
        }

        setSaveEnabled(true);
    }

    const toggleUpdate = () => {
        setUpdates(!updates);
    }

    const handleHoursChange = (e) => {
        if (e.target.value === "24") {
            setHours(e.target.value);
            setMins('0');
            setSecs('0');
            setMinSecDisabled(true);
        } else {
            setHours(Number(e.target.value).toString());
            if (minSecDisabled) {
                setMinSecDisabled(false);
            }
        }
    }

    const handleIntervalSave = () => {
        let hs = parseInt(hours) * 3600
        let ms = parseInt(mins) * 60
        let ss = parseInt(secs)

        let interval = hs + ms + ss;

        if (interval < 1) {
            enqueueSnackbar(`Interval cannot be less than 1 second`, { variant: 'error' });
            return;
        }

        rb.addRequest("timerInterval", "W", { interval: interval });

        rb.send()
            .then(() => {
                enqueueSnackbar("Interval successfully saved", { variant: 'success' });
                setSaveEnabled(false);
            })
            .catch((res) => errorHandler(res));
    }

    return (
        <WidgetWrapper title="Interval" saveEnabled={saveEnabled} onSave={handleIntervalSave}>
            <div className={classes.fields}>
                <TextField
                    id="hours"
                    type="number"
                    label="hours"
                    value={hours}
                    inputProps={{ min: 0, max: 24 }}
                    onChange={(e) => {
                        if (parseInt(e.target.value) > 24) {
                            handleHoursChange({ target: { value: "24" } });
                        } else {
                            handleHoursChange(e);
                        }
                        enableSave();
                    }}
                    onFocus={toggleUpdate}
                    onBlur={toggleUpdate}
                    variant="outlined"
                    className={classes.field}
                />
                <TextField
                    id="mins"
                    type="number"
                    label="mins"
                    value={mins}
                    disabled={minSecDisabled}
                    inputProps={{ min: 0, max: 59 }}
                    onChange={(e) => {
                        if (parseInt(e.target.value) > 59) {
                            setMins("59");
                        } else {
                            setMins(Number(e.target.value).toString());
                        }
                        enableSave();
                    }}
                    onFocus={toggleUpdate}
                    onBlur={toggleUpdate}
                    variant="outlined"
                    className={classes.field}
                />
                <TextField
                    id="secs"
                    type="number"
                    label="secs"
                    value={secs}
                    disabled={minSecDisabled}
                    inputProps={{ min: hours === "0" && mins === "0" ? 1 : 0, max: 59 }}
                    onChange={(e) => {
                        if (parseInt(e.target.value) > 59) {
                            setSecs("59")
                        } else {
                            setSecs(Number(e.target.value).toString());
                        }
                        enableSave();
                    }}
                    onFocus={toggleUpdate}
                    onBlur={toggleUpdate}
                    variant="outlined"
                    className={classes.field}
                />
            </div>
        </WidgetWrapper >
    );

}