import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { WidgetWrapper } from '../../WidgetWrapper';
import { RequestBuilder } from '../../../../services/request.service';
import useDeviceErrorHandler from '../../useDeviceErrorHandler';

const useStyles = makeStyles((theme) => ({
	inactive: {
		color: theme.palette.greyThree.main,
		fontStyle: 'italic',
	},
	active: {
		color: theme.palette.primary.main,
	},
	checkbox: {
		padding: 0,
		marginRight: theme.spacing(1),
	},
	checkboxLabel: {
		marginLeft: 0,
		[theme.breakpoints.up('sm')]: {
			marginLeft: 'unset',
			marginRight: theme.spacing(3),
		},
	},
}));

export const MQTTUARTSession = ({ session, pebl, UART = false }) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const errorHandler = useDeviceErrorHandler();
	const [saveEnabled, setSaveEnabled] = useState(true);
	const [duration, setDuration] = useState('10');
	const [errors, setErrors] = useState({});
	const rb = new RequestBuilder(pebl.id);
	const regex = new RegExp('^[0-9]*$');

	const handleSectionSave = () => {
		setSaveEnabled(false);
		rb.addRequest(`${UART ? 'uart' : 'mqtt'}Session`, 'W', {
			duration: parseInt(duration),
		});
		rb.send()
			.then(() => {
				enqueueSnackbar(`${UART ? 'UART' : 'MQTT'} session set successfully`, {
					variant: 'success',
				});
			})
			.catch((res) => errorHandler(res))
			.finally(() => setSaveEnabled(true));
	};

	const handleDurationChange = (e) => {
		if (regex.test(e.target.value)) {
			let value = parseInt(e.target.value);
			if (value <= 1440) {
				setErrors({});
				setSaveEnabled();
				setDuration(e.target.value);
			} else {
				setSaveEnabled(false);
				setErrors({ [e.target.name]: 'Duration cannot be greater than 1440' });
				setDuration(e.target.value);
			}
		} else {
			setSaveEnabled(false);
			setErrors({ [e.target.name]: 'Duration must be a number' });
		}
	};

	return (
		<WidgetWrapper
			title='Session'
			saveEnabled={saveEnabled}
			onSave={handleSectionSave}>
			<Typography
				variant='h4'
				className={clsx({
					[classes.active]: session,
					[classes.inactive]: !session,
				})}>
				{session ? 'Active' : 'Inactive'}
			</Typography>

			<Grid container alignItems='center'>
				<Grid item xs={8}>
					<TextField
						variant='outlined'
						name='duration'
						disabled={duration === 0}
						value={duration !== 0 ? duration : ''}
						label='duration'
						onChange={handleDurationChange}
						placeholder='10'
						error={Boolean(errors['duration'])}
						helperText={errors['duration']}
					/>
				</Grid>
				<Grid item xs={4}>
					<FormControlLabel
						className={classes.checkboxLabel}
						control={
							<Checkbox
								className={classes.checkbox}
								color='secondary'
								checked={duration === 0}
								onChange={() => {
									setDuration(duration !== 0 ? 0 : 1);
								}}
							/>
						}
						label='Forever'
					/>
				</Grid>
			</Grid>
		</WidgetWrapper>
	);
};
