import React, { useEffect } from 'react';

import { WidgetGrid } from '../../WidgetGrid';
import { PeblAppearance } from './widgets/PeblAppearance';
import { Identification } from '../../shared/widgets/Identification';
import { Power } from '../../shared/widgets/Power';
import { Version } from '../../shared/widgets/Version';
import { GatewayActions } from '../../shared/widgets/GatewayActions';
import { PeblBusMode } from './widgets/PeblBusMode';
import { Location } from '../../shared/widgets/Location';
import { PeblLocalKey } from './widgets/PeblLocalKey';
import { PeblSleep } from './widgets/PeblSleep';
import { PeblSupportAccess } from './widgets/PeblSupportAccess';

const gridLayout = {
	xl: [
		{ i: '1', x: 0, y: 0, w: 3, h: 7 },
		{ i: '2', x: 0, y: 7, w: 3, h: 8 },
		{ i: '3', x: 3, y: 0, w: 3, h: 7 },
		{ i: '4', x: 3, y: 7, w: 3, h: 4 },
		{ i: '5', x: 6, y: 0, w: 3, h: 11 },
		{ i: '6', x: 3, y: 11, w: 3, h: 4 },
		{ i: '7', x: 3, y: 15, w: 3, h: 3 },
		{ i: '8', x: 6, y: 11, w: 3, h: 7 },
		{ i: '9', x: 0, y: 15, w: 3, h: 4 },
		{ i: '10', x: 3, y: 18, w: 3, h: 3 },
	],
	lg: [
		{ i: '1', x: 0, y: 0, w: 3, h: 7 },
		{ i: '2', x: 0, y: 7, w: 3, h: 8 },
		{ i: '3', x: 3, y: 0, w: 3, h: 7 },
		{ i: '4', x: 3, y: 7, w: 3, h: 4 },
		{ i: '5', x: 3, y: 11, w: 3, h: 11 },
		{ i: '6', x: 0, y: 11, w: 3, h: 4 },
		{ i: '7', x: 3, y: 15, w: 3, h: 3 },
		{ i: '8', x: 0, y: 11, w: 3, h: 7 },
		{ i: '9', x: 3, y: 19, w: 3, h: 4 },
		{ i: '10', x: 0, y: 18, w: 3, h: 3 },
	],
	md: [
		{ i: '1', x: 0, y: 0, w: 3, h: 7 },
		{ i: '2', x: 0, y: 7, w: 3, h: 8 },
		{ i: '3', x: 3, y: 0, w: 3, h: 7 },
		{ i: '4', x: 3, y: 7, w: 3, h: 4 },
		{ i: '5', x: 0, y: 11, w: 3, h: 11 },
		{ i: '6', x: 3, y: 11, w: 3, h: 4 },
		{ i: '7', x: 0, y: 15, w: 3, h: 3 },
		{ i: '8', x: 3, y: 15, w: 3, h: 7 },
		{ i: '9', x: 3, y: 22, w: 3, h: 4 },
		{ i: '10', x: 3, y: 26, w: 3, h: 3 },
	],
	sm: [
		{ i: '1', x: 0, y: 0, w: 4, h: 7 },
		{ i: '2', x: 0, y: 7, w: 4, h: 8 },
		{ i: '3', x: 0, y: 22, w: 4, h: 7 },
		{ i: '4', x: 0, y: 18, w: 4, h: 4 },
		{ i: '5', x: 0, y: 26, w: 4, h: 11 },
		{ i: '6', x: 0, y: 37, w: 4, h: 4 },
		{ i: '7', x: 0, y: 37, w: 4, h: 3 },
		{ i: '8', x: 0, y: 41, w: 4, h: 7 },
		{ i: '9', x: 0, y: 48, w: 4, h: 4 },
        { i: '10', x: 0, y: 52, w: 4, h: 3 }
	],
};

export const PeblDevice = ({ pebl }) => {
	return (
		<WidgetGrid layouts={gridLayout}>
			<div key='1'>
				<Identification deviceName={pebl.device.name} deviceId={pebl.id} />
			</div>
			<div key='2'>
				<GatewayActions device={pebl} />
			</div>
			<div key='3'>
				<Power
					voltage
					currentPower={(pebl.device.power
						? pebl.device.power.voltage / 1000
						: 0
					).toFixed(1)}
					maxPower={16}
					minPower={3}
					device={pebl}
				/>
			</div>
			<div key='4'>
				<Version deviceVersion={pebl.device.version} />
			</div>
			<div key='5'>
				<PeblAppearance pebl={pebl} />
			</div>
			<div key='6'>
				<PeblBusMode pebl={pebl} />
			</div>
			<div key='7'>
				<PeblLocalKey pebl={pebl} />
			</div>
			<div key='8'>
				<Location device={pebl} />
			</div>
			<div key='9'>
				<PeblSleep pebl={pebl} />
			</div>
			<div key='10'>
				<PeblSupportAccess pebl={pebl} />
			</div>
		</WidgetGrid>
	);
};
