import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { FingotiHeader, FingotiButton } from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import { ThermometerIcon, LightBulbIcon, QuestionIcon  } from '../../../../icons/FingotIcons';
import AccessibilityNewOutlinedIcon from '@material-ui/icons/AccessibilityNewOutlined';
import PowerOutlinedIcon from '@material-ui/icons/PowerOutlined';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import FlashOnOutlinedIcon from '@material-ui/icons/FlashOnOutlined';

import { RequestBuilder } from '../../../../services/request.service';
import { datetimeService } from '../../../../services/datetime.service';
import { useRoleCheck } from '../../../utils/RoleCheck';

import { VyneNodeDevice } from './VyneNodeDevice';
import { VyneDataTempHumid } from './VyneDataTempHumid';
import { VyneDataLux } from './VyneDataLux';
import { VyneDataPir } from './VyneDataPir';
import { VyneDataUps } from './VyneDataUps';
import { VyneSetupLux } from './VyneSetupLux';
import { VyneSetupPir } from './VyneSetupPir';


const useStyles = makeStyles((theme) => ({

    root: {
        width: '100%',
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: '100%'
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '10%',
        [theme.breakpoints.down('lg')]: {
            width: '13%',
        },
        [theme.breakpoints.down('md')]: {
            width: '20%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '25%',
        },
    },
    tab: {
        width: '100%',
    },
    tabRoot: {
        minWidth: 'unset',
    },
    tabLabelRight: {
        alignItems: 'unset',
        textAlign: 'right',
        paddingRight: theme.spacing(1),
    },
    boxPadding: {
        padding: theme.spacing(6),
        paddingTop: theme.spacing(3)
    },
    lastUpdated: {
        marginRight: theme.spacing(2),
    },
    refreshing: {
        marginRight: theme.spacing(2),
    }

}));

const TabPanel = (props) => {
    const { passClass, children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div className={passClass}>
                    {children}
                </div>
            )}
        </div>
    );
}

const tabProps = (index) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


export const VyneNodeTabs = ({ node }) => {

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [activeMenu, setActiveMenu] = useState(0);
    const [refreshing, setRefreshing] = useState(false);
    const { roleCheck } = useRoleCheck();
    const rb = new RequestBuilder(node.gatewayId);

    const refreshNodeData = () => {
        setRefreshing(true);
        rb.addRequest('nodeInformation', 'R', { "serial": node.id })
        rb.sendSingleSection(["nodeInformation"])
        .then(() => {
            setRefreshing(false);
        })
        .catch(errors => {
            if (errors && Array.isArray(errors)) {
                errors.forEach(error => {
                    enqueueSnackbar(`Error ${error.result.code} - ${error.result.message}`, { variant: 'error' });
                    setRefreshing(false);
                })
            } else {
                enqueueSnackbar(errors, { variant: 'error' });
                setRefreshing(false);
            }
        });
    }

    const handleChange = (event, newValue) => {
        if (activeMenu === newValue) {
            return;
        }

        setActiveMenu(newValue);
    };

    const breadcrumbs = [
        { text: 'Devices', link: '/devices' },
        { text: 'Nodes', link: '/devices/nodes' },
        { text: node.name, link: '' }
    ]

    const getDataTab = () => {
        switch (node.type.type) {
            case "t&h":
                return <VyneDataTempHumid node={node} />
            case "lux":
                return <VyneDataLux node={node} />
            case "pir":
                return <VyneDataPir node={node} />
            case "ups":
                return <VyneDataUps node={node} />
            default:
                return <div>make a data tab for this type</div>
        }
    }

    const getSetupTab = () => {
        switch (node.type.type) {
            case "t&h":
            case "poe":
            case "ups":
            case "psu":
            case "lux":
                return <VyneSetupLux node={node} />
            case "pir":
                return <VyneSetupPir node={node} />
            default:
                return <div>make a setup tab for this type</div>
        }
    }

    const getDeviceIcon = () => {
        switch(node.type.type) {
        case "lux":
            return LightBulbIcon 
        case "t&h":
            return ThermometerIcon
        case "pir":
            return AccessibilityNewOutlinedIcon
        case "psu":
            return PowerOutlinedIcon
        case "poe":
            return FlashOnOutlinedIcon
        case "ups":
            return BatteryFullIcon
        default:
            return QuestionIcon
        }
    }

    const actionButtons = () => {
        return (
            <React.Fragment>
                {refreshing
                    ? <CircularProgress size={25} className={classes.refreshing} />
                    : <Typography className={classes.lastUpdated}>last updated: {datetimeService.formatDateTime(new Date(node.data.timestamp))} </Typography>
                }
                <FingotiButton
                    light
                    color="primary"
                    onClick={refreshNodeData}
                    disabled={refreshing || !node.online || roleCheck("device", 2)}
                >
                    refresh node
            </FingotiButton>
            </React.Fragment>
        );
    }

    return (
        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                value={activeMenu}
                variant="fullWidth"
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                className={classes.tabs}
            >
                <Tab classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }} label="device" {...tabProps(0)} />
                <Tab classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }} label="data" {...tabProps(1)} />
                <Tab classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }} label="setup" {...tabProps(2)} />
            </Tabs>

            <TabPanel passClass={classes.boxPadding} className={classes.tab} value={activeMenu} index={0}>
                <FingotiHeader breadcrumbs={breadcrumbs} sectionIcon={getDeviceIcon()} actionButtons={actionButtons()} badge={{ show: true, online: node.online }} />
                <VyneNodeDevice node={node} />
            </TabPanel>

            <TabPanel passClass={classes.boxPadding} className={classes.tab} value={activeMenu} index={1}>
                <FingotiHeader breadcrumbs={breadcrumbs} sectionIcon={getDeviceIcon()} actionButtons={actionButtons()} badge={{ show: true, online: node.online }}  />
                {
                    getDataTab()
                }
            </TabPanel>

            <TabPanel passClass={classes.boxPadding} className={classes.tab} value={activeMenu} index={2}>
                <FingotiHeader breadcrumbs={breadcrumbs} sectionIcon={getDeviceIcon()} actionButtons={actionButtons()} badge={{ show: true, online: node.online }}  />
                {
                    getSetupTab()
                }
            </TabPanel>
        </div>
    );

}