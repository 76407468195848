import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import { WidgetWrapper } from '../../../WidgetWrapper';

import { RequestBuilder } from '../../../../../services/request.service';
import useDeviceErrorHandler from '../../../useDeviceErrorHandler';

const env = process.env.REACT_APP_ENVIROMENT_STRING;

export const PeblBusMode = ({ pebl }) => {
	const { enqueueSnackbar } = useSnackbar();
	const errorHandler = useDeviceErrorHandler();
	const [saveEnabled, setSaveEnabled] = useState(false);
	const [busMode, setBusMode] = useState(pebl.device.bus.protocol);
	const rb = new RequestBuilder(pebl.id);

	useEffect(() => {
		if (!saveEnabled) {
			setBusMode(pebl.device.bus.protocol);
		}
	}, [pebl]);

	const enableSave = () => {
		if (saveEnabled) {
			return;
		}

		setSaveEnabled(true);
	};

	const handleSectionSave = () => {
		//Clear persisted UART data
		localStorage.removeItem(`${pebl.id}-UART-DATA`);
		rb.addRequest('deviceBus', 'W', { protocol: busMode });
		rb.send()
			.then(() => {
				enqueueSnackbar(`Bus mode set successfully`, { variant: 'success' });
			})
			.catch((res) => errorHandler(res));
		setSaveEnabled(false);
	};

	return (
		<WidgetWrapper
			title='Bus Mode'
			saveEnabled={saveEnabled}
			onSave={handleSectionSave}>
			<FormControl fullWidth variant='outlined'>
				<InputLabel id='busMode-label'>mode</InputLabel>
				<Select
					variant='outlined'
					label='mode'
					labelId='busMode-label'
					value={busMode}
					onChange={(e) => {
						setBusMode(e.target.value);
						enableSave();
					}}>
					<MenuItem key={0} value={0}>
						UART
					</MenuItem>
					<MenuItem key={1} value={1}>
						I2C
					</MenuItem>
					{env !== 'PROD' && (
						<MenuItem key={2} value={2}>
							Debug
						</MenuItem>
					)}
				</Select>
			</FormControl>
		</WidgetWrapper>
	);
};
