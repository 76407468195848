import React, { useEffect, useState } from 'react';
import { FingotiHeader, FingotiMarkdown } from '@fingoti/components';

import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

export const General = () => {

    const breadcrumbs = [
        { text: 'Settings', link: '/settings' },
        { text: 'General' }
    ]

    return (
        <>
            <FingotiHeader
                breadcrumbs={breadcrumbs}
                sectionIcon={SettingsOutlinedIcon}
            />
            <FingotiMarkdown
                path="/documentation/portal/settings/general.md"
            />
        </>
    )
}