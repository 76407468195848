import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';

import { WidgetWrapper } from '../../../WidgetWrapper';

import { apiService } from '../../../../../services/api.service';
import useDeviceErrorHandler from '../../../useDeviceErrorHandler';

export const PeblSupportAccess = ({ pebl }) => {
	const { enqueueSnackbar } = useSnackbar();
	const errorHandler = useDeviceErrorHandler();
	const [saveEnabled, setSaveEnabled] = useState(false);
	const [enabled, setEnabled] = useState(pebl.device.support.enabled);

	useEffect(() => {
		if (!saveEnabled) {
			setEnabled(pebl.device.support.enabled);
		}
	}, [pebl]);

	const enableSave = () => {
		if (saveEnabled) {
			return;
		}

		setSaveEnabled(true);
	};

	const handleSectionSave = () => {
		apiService
			.patchData(`/device/${pebl.id}`, {
				device: { support: { enabled: enabled } },
			})
			.then((result) => {
				enqueueSnackbar(result.message, { variant: 'success' });
				setSaveEnabled(false);
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
			});
	};

	return (
		<WidgetWrapper
			title='Support Access'
			saveEnabled={saveEnabled}
			onSave={handleSectionSave}>
			<Grid component='label' container alignItems='center' spacing={1}>
				<Grid item>disabled</Grid>
				<Grid item>
					<Switch
						checked={enabled}
						onChange={() => {
							setEnabled(!enabled);
							enableSave();
						}}
						color='primary'
					/>
				</Grid>
				<Grid item>enabled</Grid>
			</Grid>
		</WidgetWrapper>
	);
};
