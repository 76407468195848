import React, { useEffect } from 'react';

import { useDeviceState } from '../../../../context/DeviceContext';
import { WidgetGrid } from '../../WidgetGrid';
import { VyneUps } from './widgets/VyneUps';
import { VyneDataTimestamp } from './widgets/VyneDataTimestamp';

const gridLayout = {
    xl: [
        { i: '1', x: 0, y: 0, w: 3, h: 4 },
        { i: '2', x: 3, y: 0, w: 3, h: 4 },
    ],
    lg: [
        { i: '1', x: 0, y: 0, w: 3, h: 4 },
        { i: '2', x: 3, y: 0, w: 4, h: 4 },
    ],
    md: [
        { i: '1', x: 0, y: 0, w: 6, h: 4 },
        { i: '2', x: 0, y: 4, w: 6, h: 4 },
    ],
    sm: [
        { i: '1', x: 0, y: 0, w: 4, h: 4 },
        { i: '2', x: 0, y: 4, w: 4, h: 4 },
    ],
}

export const VyneDataUps = ({ node }) => {
    
    const { vyneGateways, loaded } = useDeviceState();
    
    useEffect(() => { }, [node])
    
    let gateway = vyneGateways.find(d => d.id === node.gatewayId);
    //const values = node.type.getData(node.data.value);
    
    return (
        <>
            <WidgetGrid layouts={gridLayout}>
                <div key='1'>
                    <VyneUps data={gateway.device.power.source} />
                </div>
                <div key='2'>
                    <VyneDataTimestamp data={node.data.timestamp} />
                </div>
            </WidgetGrid>
        </>
    );

}