import React, { useState, useEffect } from 'react';
import { useRouteMatch, useHistory, Link as RouterLink } from 'react-router-dom';
import { FingotiLoading, FingotiTabs, FingotiNoPermission } from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Gateways } from './gateway/Gateways';
import { Nodes } from './node/Nodes';
import { AllDevices } from './AllDevices';
import { useRoleCheck } from '../utils/RoleCheck';

const useStyles = makeStyles((theme) => ({

    root: {
        width: '100%',
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: '100%'
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '10%',
        [theme.breakpoints.down('lg')]: {
            width: '13%',
        },
        [theme.breakpoints.down('md')]: {
            width: '20%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '25%',
        },
    },
    tab: {
        width: '100%',
    },
    tabRoot: {
        minWidth: 'unset',
    },
    tabLabelRight: {
        alignItems: 'unset',
        textAlign: 'right',
        paddingRight: theme.spacing(1),
    },
    boxPadding: {
        padding: theme.spacing(6),
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
        }
    }

}));

const TabPanel = (props) => {
    const { passClass, children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div className={passClass}>
                    {children}
                </div>
            )}
        </div>
    );
}

const tabProps = (index) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const RouterTab = (props) => <Tab component={RouterLink} {...props} />

export const DeviceTabs = ({ activeMenu }) => {

    const classes = useStyles();
    const match = useRouteMatch();
    const history = useHistory();
    const { roleCheck } = useRoleCheck();
    const [value, setValue] = useState(activeMenu);

    useEffect(() => {
        setValue(activeMenu);
    });

    useEffect(() => {
        return () => {
            localStorage.removeItem("device-sort");
        }
    }, []);

    const handleChange = (event, newValue) => {

        let isTwoPartPath = match.path.indexOf('/', 1);
        let baseRoute = "/devices";

        if (isTwoPartPath !== -1) {
            //Path is /foo/bar, need to get rid of the /bar
            baseRoute = match.path.slice(0, isTwoPartPath)
        }

        switch (newValue) {
            case 0:
                window.history.pushState({}, 'Fingoti Portal', `${baseRoute}`)
                break;
            case 1:
                history.push(`${baseRoute}/gateways`);
                break;
            case 2:
                history.push(`${baseRoute}/nodes`);
                break;
            default:
                console.log("not a valid value");
                break;
        }

        setValue(newValue);

    };

    return (
        value === null ?
            <FingotiLoading />
            :
            !roleCheck("device", 1) ?
                <div className={classes.root}>
                    <FingotiTabs
                        value={value}
                        onChange={handleChange}
                        aria-label="device settings tabs"
                    >
                        <RouterTab to={'/devices'} classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }} label="All" {...tabProps(0)} />
                        <RouterTab to={'/devices/gateways'} classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }} label="Gateways" {...tabProps(1)} />
                        <RouterTab to={'/devices/nodes'} classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }} label="Nodes" {...tabProps(2)} />
                    </FingotiTabs>

                    <TabPanel passClass={classes.boxPadding} className={classes.tab} value={value} index={0}>
                        <AllDevices />
                    </TabPanel>

                    <TabPanel passClass={classes.boxPadding} className={classes.tab} value={value} index={1}>
                        <Gateways />
                    </TabPanel>

                    <TabPanel passClass={classes.boxPadding} className={classes.tab} value={value} index={2}>
                        <Nodes />
                    </TabPanel>
                </div>
                :
                <FingotiNoPermission />
    );

}