import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FingotiActionButton } from '@fingoti/components';
import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import EjectIcon from '@material-ui/icons/Eject';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';

import { WidgetWrapper } from '../../../WidgetWrapper';
import { apiService } from '../../../../../services/api.service';
import { RequestBuilder } from '../../../../../services/request.service';

const useStyles = makeStyles(theme => ({

    actions: {
        margin: '0px auto 0px auto',
        display: 'flex',
        flexDirection: 'row',
    },
    buttonWrapper: {
        '& > :hover': {
            cursor: 'pointer',
        }
    },
    action: {
        backgroundColor: theme.palette.greyFour.light,
        textAlign: 'center',
        color: theme.palette.greyTwo.main,
        padding: theme.spacing(1) + theme.spacing(1) / 2,
        margin: '0px ' + theme.spacing(2) + 'px 0px ' + theme.spacing(2) + 'px',
        borderRadius: theme.spacing(1),
    },
    activeAction: {
        color: theme.palette.primary.main,
    },
    actionIcon: {
        fontSize: '3rem',
    },

}))

export const VyneActions = ({ node }) => {

    const classes = useStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [identifyLoading, setIdentifyLoading] = useState(false);
    const [unlatchLoading, setUnlatchLoading] = useState(false);
    const [restartLoading, setRestartLoading] = useState(false);
    const [endisLoading, setEnDisLoading] = useState(false);
    const rb = new RequestBuilder(node.gatewayId);

    const handleIdentify = () => {
        setIdentifyLoading(true);
        rb.addRequest("nodeIdentify", "W", {
            serial: node.id,
            identify: !node.identify
        })
        rb.sendSingleSection(["nodeIdentify"])
            .then(() => {
                enqueueSnackbar(!node.identify ? "Identifying started" : "Identifying stopped", { variant: 'success' });
                setIdentifyLoading(false);
            })
            .catch(errors => {
                if (errors && Array.isArray(errors)) {
                    errors.forEach(error => {
                        enqueueSnackbar(`Error ${error.result.code} - ${error.result.message}`, { variant: 'error' });
                    })
                } else {
                    enqueueSnackbar(errors, { variant: 'error' });
                }
                setIdentifyLoading(false);
            });
    }


    const handleUnlatch = () => {
        setUnlatchLoading(true);
        apiService.deleteData(`/device/${node.id}`)
            .then(result => {
                enqueueSnackbar(result.message, { variant: 'success' });
                history.goBack();
                setUnlatchLoading(false);
            })
            .catch(error => {
                enqueueSnackbar(error, { variant: 'error' });
                setUnlatchLoading(false);
            })
    }

    const handleRestart = () => {
        setRestartLoading(true);
        rb.addRequest("nodeAction", "W", {
            serial: node.id,
            action: "restart"
        })
        rb.sendSingleSection(["nodeAction"])
            .then(() => {
                enqueueSnackbar("Node restarting", { variant: 'success' });
                setRestartLoading(false);
            })
            .catch(errors => {
                if (errors && Array.isArray(errors)) {
                    errors.forEach(error => {
                        enqueueSnackbar(`Error ${error.result.code} - ${error.result.message}`, { variant: 'error' });
                    })
                } else {
                    enqueueSnackbar(errors, { variant: 'error' });
                }
                setRestartLoading(false);
            });
    }

    const handleEnableDisable = () => {
        setEnDisLoading(true);
        rb.addRequest("nodeEnable", "W", {
            serial: node.id,
            enable: !node.enable
        })
        rb.sendSingleSection(["nodeEnable"])
            .then(() => {
                enqueueSnackbar(`Node ${node.enable ? "disabled" : "enabled"}`, { variant: 'success' });
                setEnDisLoading(false);
            })
            .catch(errors => {
                if (errors && Array.isArray(errors)) {
                    errors.forEach(error => {
                        enqueueSnackbar(`Error ${error.result.code} - ${error.result.message}`, { variant: 'error' });
                    })
                } else {
                    enqueueSnackbar(errors, { variant: 'error' });
                }
                setEnDisLoading(false);
            });
    }

    return (
        <WidgetWrapper title="Actions" displaySave={false}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FingotiActionButton
                        Icon={RotateLeftIcon}
                        label="Restart"
                        loading={restartLoading}
                        onClick={handleRestart}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FingotiActionButton
                        Icon={EjectIcon}
                        label="Unlatch"
                        loading={unlatchLoading}
                        onClick={handleUnlatch}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FingotiActionButton
                        Icon={VisibilityIcon}
                        label="Identify"
                        loading={identifyLoading}
                        active={node.identify}
                        onClick={handleIdentify}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FingotiActionButton
                        Icon={BlockOutlinedIcon}
                        label="Disable"
                        loading={endisLoading}
                        active={!node.enable}
                        onClick={handleEnableDisable}
                    />
                </Grid>
            </Grid>
        </WidgetWrapper >
    );

}