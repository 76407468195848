import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

import { FingotiLoading } from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';

import { datetimeService } from '../../services/datetime.service';
import { apiService } from '../../services/api.service';
import { WidgetWrapper } from '../device/WidgetWrapper';
import { useProfileState } from '../../context/ProfileContext';

const useStyles = makeStyles((theme) => ({
	subscription: {
		color: theme.palette.primary.main,
	},
	payg: {
		color: theme.palette.secondary.main,
	},
	largeText: {
		fontSize: '2.5rem',
		[theme.breakpoints.up('md')]: {
			fontSize: '4rem',
		},
	},
	smallText: {
		fontSize: '1.5rem',
		color: theme.palette.greyTwo.main,
		whiteSpace: 'nowrap',
		[theme.breakpoints.up('md')]: {
			fontSize: '2.5rem',
		},
	},
	labelText: {
		margin: 0,
		lineHeight: 1,
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(2),
		},
		[theme.breakpoints.up('md')]: {
			marginBottom: theme.spacing(1) + theme.spacing(1) / 2,
		},
	},
	noMargin: {
		margin: 0,
		lineHeight: 1,
	},
}));

export const RippleStats = () => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [thisLoading, setThisLoading] = useState(true);
	const [paygBalance, setPaygBalance] = useState(undefined);
	const [subscription, setSubscription] = useState(undefined);
	const { organisation, loading } = useProfileState();

	useEffect(() => {
		setThisLoading(true);
		if (!loading) {
			getBillingInfo();
		}
	}, [loading, organisation]);

	const getBillingInfo = () => {
		apiService
			.getData('/billing')
			.then((result) => {
				setPaygBalance(result.paygBalance);
				setSubscription(
					result.subscriptions.find(
						(sub) => sub.subscriptionId === result.subscriptionId
					)
				);
				setThisLoading(false);
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
				setThisLoading(false);
			});
	};

	return (
		<WidgetWrapper displaySave={false} title='Ripples and Subscriptions'>
			{
				<Box>
					{thisLoading ? (
						<FingotiLoading fullHeight={false} />
					) : (
						<>
							<Box display='flex' flexWrap='wrap' alignItems='flex-end'>
								{organisation.partnerId !== null ? (
									<>
										<Typography
											className={clsx([
												classes.subscription,
												classes.largeText,
												classes.noMargin,
											])}>
											0
										</Typography>
										<Typography className={classes.labelText}>
											Your subscription is managed by your Partner
										</Typography>
									</>
								) : subscription ? (
									<>
										<Typography
											className={clsx([
												classes.subscription,
												classes.largeText,
												classes.noMargin,
											])}>
											{new Intl.NumberFormat('en-GB').format(
												subscription.balance
											)}
											<small
												className={clsx([classes.smallText, classes.noMargin])}>
												{' '}
												/{' '}
												{new Intl.NumberFormat('en-GB', {
													notation: 'compact',
													compactDisplay: 'short',
												})
													.format(subscription.planSelected.ripples)
													.toLocaleLowerCase()}
											</small>
										</Typography>
										<Typography className={classes.labelText}>
											{subscription.planSelected.reference} Subscription Ripples
											<br />
											<small>
												Renews on{' '}
												{datetimeService.formatDate(
													new Date(subscription.renewalDate)
												)}
											</small>
										</Typography>
									</>
								) : (
									<>
										<Typography
											className={clsx([
												classes.subscription,
												classes.largeText,
												classes.noMargin,
											])}>
											0
										</Typography>
										<Typography className={classes.labelText}>
											No Active Subscription
										</Typography>
									</>
								)}
							</Box>
							<Box display='flex' flexWrap='wrap' alignItems='flex-end' mt={1}>
								<Typography
									className={clsx([
										classes.payg,
										classes.largeText,
										classes.noMargin,
									])}>
									{new Intl.NumberFormat('en-GB').format(paygBalance)}
								</Typography>
								<Typography
									className={classes.labelText}
									style={{ whiteSpace: 'nowrap' }}>
									PAYG Ripples
								</Typography>
							</Box>
						</>
					)}
				</Box>
			}
		</WidgetWrapper>
	);
};
