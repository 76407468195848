import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import { WidgetWrapper } from '../../WidgetWrapper';

import { RequestBuilder } from '../../../../services/request.service';
import useDeviceErrorHandler from '../../useDeviceErrorHandler';

const useStyles = makeStyles((theme) => ({
	checkbox: {
		padding: 0,
		marginRight: theme.spacing(1),
	},
	checkboxLabel: {
		marginLeft: 0,
		[theme.breakpoints.up('sm')]: {
			marginLeft: 'unset',
			marginRight: theme.spacing(3),
		},
	},
}));

export const GPIOPulse = ({ pebl }) => {
	const { enqueueSnackbar } = useSnackbar();
	const errorHandler = useDeviceErrorHandler();
	const [saveEnabled, setSaveEnabled] = useState(true);
	const [gpio, setGPIO] = useState(1);
	const [duration, setDuration] = useState(1);
	const rb = new RequestBuilder(pebl.id);
	const classes = useStyles();

	const handleSectionSave = () => {
		setSaveEnabled(false);
		rb.addRequest('gpioPulse', 'W', {
			gpio: gpio,
			duration: parseInt(duration),
		});
		rb.send()
			.then(() => {
				enqueueSnackbar(
					`GPIO ${gpio} will pulse for ${
						duration + ` second${duration > 1 ? 's' : ''}`
					}`,
					{ variant: 'success' }
				);
			})
			.catch((res) => errorHandler(res))
			.finally(() => setSaveEnabled(true));
	};

	return (
		<WidgetWrapper
			title='Pulse'
			saveEnabled={saveEnabled}
			onSave={handleSectionSave}>
			<Grid container spacing={2} alignItems='center'>
				<Grid item xs={5}>
					<FormControl fullWidth variant='outlined'>
						<InputLabel id='gpio-label'>gpio</InputLabel>
						<Select
							variant='outlined'
							label='gpio'
							labelId='gpio-label'
							value={gpio}
							onChange={(e) => setGPIO(e.target.value)}>
							<MenuItem key='0' value={1}>
								1
							</MenuItem>
							<MenuItem key='1' value={2}>
								2
							</MenuItem>
							<MenuItem key='2' value={3}>
								3
							</MenuItem>
							<MenuItem key='3' value={4}>
								4
							</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={7}>
					<TextField
						fullWidth
						type='number'
						variant='outlined'
						label='duration (sec)'
						value={duration}
						inputProps={{
							min: 1,
							max: 30,
						}}
						onChange={(e) => {
							if (parseInt(e.target.value) > 30) {
								setDuration(30);
							} else if (parseInt(e.target.value) < 1) {
								setDuration(1);
							} else {
								setDuration(e.target.value);
							}
						}}
					/>
				</Grid>
			</Grid>
		</WidgetWrapper>
	);
};
