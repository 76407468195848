import React, { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	FingotiButton,
	FingotiModal,
	FingotiDelete,
	FingotiHeader,
	FingotiMarkdown,
	URLGenerator,
	FingotiTable,
	FingotiLoading,
} from '@fingoti/components';
import { useSnackbar } from 'notistack';

import makeStyles from '@material-ui/core/styles/makeStyles';

import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import { WebhookIcon } from '../../../icons/FingotIcons';

import { useRoleCheck } from '../../utils/RoleCheck';
import { datetimeService } from '../../../services/datetime.service';
import { apiService } from '../../../services/api.service';
import { WebhookAdd } from './WebhookAdd';
import { WebhookEdit } from './WebhookEdit';
import { useProfileState } from '../../../context/ProfileContext';

const useStyles = makeStyles((theme) => ({
	actionIcon: {
		padding: 0,
	},
	enabledIcon: {
		verticalAlign: 'middle',
		fill: theme.palette.success.main,
	},
	disabledIcon: {
		verticalAlign: 'middle',
		fill: theme.palette.error.main,
	},
	secureIcon: {
		verticalAlign: 'middle',
		fill: theme.palette.success.main,
	},
	insecureIcon: {
		verticalAlign: 'middle',
		fill: theme.palette.action.disabled,
	},
}));

export const WebhookTable = () => {
	const classes = useStyles();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const [addOpen, setAddOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);
	const [editWebhook, setEditWebhook] = useState({});
	const [thisLoading, setThisLoading] = useState(true);
	const { roleCheck } = useRoleCheck();
	const { organisation, loading } = useProfileState();
	const tableRef = useRef();

	useEffect(() => {
		setThisLoading(true);
		if (organisation && !loading) {
			setThisLoading(false);
		}
	}, [organisation, loading]);

	const getWebhooks = async (query) => {
		return apiService
			.getData(URLGenerator(query, '/webhook'))
			.then((data) => {
				return {
					data: data.webhooks,
					page: data.pageNumber - 1,
					totalCount: data.count,
				};
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
			});
	};

	const addWebhookSuccess = () => {
		tableRef.current.onQueryChange();
		setAddOpen(false);
	};

	const editWebhookSuccess = () => {
		tableRef.current.onQueryChange();
		setEditOpen(false);
	};

	const handleInfoClick = (whid) => {
		history.push(`/webhooks/${whid}`);
	};

	const handleEditClick = (webhook) => {
		setEditWebhook(webhook);
		setEditOpen(true);
	};

	const handleDeleteWebhook = (webhookId) => {
		apiService
			.deleteData(`/webhook/${webhookId}`)
			.then((result) => {
				tableRef.current.onQueryChange();
				enqueueSnackbar(result.message, { variant: 'success' });
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
			});
	};

	const breadcrumbs = [
		{ text: 'Webhooks', link: '/webhooks' },
		{ text: 'Manage', link: '/webhooks' },
	];

	const actionButtons = () => {
		return (
			<React.Fragment>
				<FingotiButton
					light
					disabled={roleCheck('webhook', 2)}
					color='primary'
					onClick={() => setAddOpen(true)}>
					add webhook
				</FingotiButton>
			</React.Fragment>
		);
	};

	const enabledRenderer = (rd) => {
		return rd.disabled ? (
			<BlockOutlinedIcon className={classes.disabledIcon} />
		) : (
			<CheckCircleOutlineIcon className={classes.enabledIcon} />
		);
	};

	const secureRenderer = (rd) => {
		return rd.webhookUrl.toLowerCase().startsWith('https://') ? (
			<LockOutlinedIcon className={classes.secureIcon} />
		) : (
			<LockOpenOutlinedIcon className={classes.insecureIcon} />
		);
	};

	const triggersRenderer = (rd) => {
		return `${rd.triggers} Triggers`;
	};

	const lastRunRenderer = (rd) => {
		return rd.lastCall
			? datetimeService.formatDateTime(new Date(rd.lastCall))
			: 'Never';
	};

	const propertiesRenderer = (rd) => {
		let propertiesString = '';
		rd.properties.forEach(
			(property, i) =>
				(propertiesString += i === 0 ? property : `, ${property}`)
		);

		return propertiesString;
	};

	return (
		<>
			<FingotiModal title='Add A Webhook' open={addOpen} setOpen={setAddOpen}>
				<WebhookAdd onAddSuccess={addWebhookSuccess} setOpen={setAddOpen} />
			</FingotiModal>
			<FingotiModal title='Edit Webhook' open={editOpen} setOpen={setEditOpen}>
				<WebhookEdit
					webhook={editWebhook}
					onEditSuccess={editWebhookSuccess}
					setOpen={setEditOpen}
				/>
			</FingotiModal>
			<FingotiHeader
				actionButtons={actionButtons()}
				breadcrumbs={breadcrumbs}
				sectionIcon={WebhookIcon}
			/>
			{thisLoading ? (
				<FingotiLoading />
			) : (
				<>
					<FingotiMarkdown path='/documentation/portal/webhook/manage.md' />
					<FingotiTable
						data={getWebhooks}
						tableRef={tableRef}
						columns={[
							{ title: 'id', field: 'id', hidden: true },
							{
								title: 'webhook id',
								field: 'webhookNumber',
								cellStyle: {
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								},
							},
							{ title: 'enabled', render: enabledRenderer },
							{
								title: 'properties',
								field: 'property',
								render: propertiesRenderer,
								cellStyle: {
									maxWidth: 0,
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								},
							},
							{ title: 'secure', render: secureRenderer },
							{ title: 'url', field: 'webhookUrl', filtering: false },
							{
								title: 'triggers',
								render: triggersRenderer,
								cellStyle: {
									maxWidth: 0,
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								},
							},
							{
								title: 'last run',
								render: lastRunRenderer,
								cellStyle: {
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								},
							},
						]}
						actions={[
							(rowData) => ({
								icon: InfoOutlinedIcon,
								tooltip: 'webhook information',
								onClick: () => handleInfoClick(rowData.id),
							}),
							(rowData) => ({
								icon: EditOutlinedIcon,
								tooltip: 'edit webhook',
								onClick: () => handleEditClick(rowData),
							}),
							(rowData) => ({
								icon: () => (
									<FingotiDelete
										disabled={roleCheck('webhook', 2)}
										tooltipText='delete webhook'
										className={classes.actionIcon}
										onClick={() => handleDeleteWebhook(rowData.id)}
									/>
								),
							}),
						]}
					/>
				</>
			)}
		</>
	);
};
