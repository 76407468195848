import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import { WidgetWrapper } from '../../../WidgetWrapper';

import { RequestBuilder } from '../../../../../services/request.service';
import useDeviceErrorHandler from '../../../useDeviceErrorHandler';

export const PeblUARTMode = ({ pebl }) => {
	const errorHandler = useDeviceErrorHandler();
	const { enqueueSnackbar } = useSnackbar();
	const [saveEnabled, setSaveEnabled] = useState(false);
	const [UARTMode, setUARTMode] = useState(pebl.uart.mode);
	const rb = new RequestBuilder(pebl.id);

	useEffect(() => {
		if (!saveEnabled) {
			setUARTMode(pebl.uart.mode);
		}
	}, [pebl]);

	const enableSave = () => {
		if (saveEnabled) {
			return;
		}

		setSaveEnabled(true);
	};

	const handleSectionSave = () => {
		rb.addRequest('uartMode', 'W', { mode: UARTMode });
		rb.send()
			.then(() => {
				enqueueSnackbar(`UART mode set`, { variant: 'success' });
			})
			.catch((res) => errorHandler(res));
		setSaveEnabled(false);
	};

	return (
		<WidgetWrapper
			title='Mode'
			saveEnabled={saveEnabled}
			onSave={handleSectionSave}>
			<FormControl fullWidth variant='outlined'>
				<InputLabel id='busMode-label'>mode</InputLabel>
				<Select
					variant='outlined'
					label='mode'
					labelId='busMode-label'
					value={UARTMode}
					onChange={(e) => {
						setUARTMode(e.target.value);
						enableSave();
					}}>
					<MenuItem key={0} value={0}>
						Command interpreter
					</MenuItem>
					<MenuItem key={1} value={1}>
						Serial passthrough
					</MenuItem>
				</Select>
			</FormControl>
		</WidgetWrapper>
	);
};
