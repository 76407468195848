import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { FingotiActionButton } from '@fingoti/components';

import Grid from '@material-ui/core/Grid';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

import { RequestBuilder } from '../../../../services/request.service';
import { WidgetWrapper } from '../../WidgetWrapper';
import useDeviceErrorHandler from '../../useDeviceErrorHandler';

export const TimerActions = ({ device }) => {

    const errorHandler = useDeviceErrorHandler();
    const { enqueueSnackbar } = useSnackbar();
    const [enableLoading, setEnableLoading] = useState(false);
    const [repeatLoading, setRepeatLoading] = useState(false);
    const rb = new RequestBuilder(device.id);

    const handleEnableToggle = () => {
        setEnableLoading(true);
        rb.addRequest("timerStatus", "W", { enabled: !device.timer.status.enabled })
        rb.send()
            .then(() => {
                enqueueSnackbar(`Timer ${device.timer.status.enabled  ? "disabled" : "enabled"} successfully`, { variant: 'success' });
                setEnableLoading(false);
            })
            .catch((res) => errorHandler(res));
    }

    const handleRepeatToggle = () => {
        setRepeatLoading(true);
        rb.addRequest("timerStatus", "W", { repeat: !device.timer.status.repeat });

        rb.send()
            .then(() => {
                enqueueSnackbar(`Timer set ${device.timer.status.repeat ? "to not" : "to"} repeat`, { variant: 'success' });
                setRepeatLoading(false);
            })
            .catch((res) => errorHandler(res));
    }

    return (
        <WidgetWrapper title="Actions" displaySave={false}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FingotiActionButton
                        Icon={CheckCircleOutlineIcon}
                        label="Enable"
                        onClick={handleEnableToggle}
                        active={device.timer.status.enabled}
                        loading={enableLoading}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FingotiActionButton
                        Icon={RotateLeftIcon}
                        label="Repeat"
                        onClick={handleRepeatToggle}
                        active={device.timer.status.repeat}
                        loading={repeatLoading}
                    />
                </Grid>
            </Grid>
        </WidgetWrapper>
    );

}