import React from 'react';
import { FingotiFullscreenStatus } from '@fingoti/components';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

import Layout from './components/Layout';
import { portalUrl } from './services/config';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		console.error(error);
		// You can also log the error to an error reporting service
		//logErrorToMyService(error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<Layout>
					<Box
						height='70vh'
						display='flex'
						flexDirection='column'
						alignItems='center'
						justifyContent='center'>
						<FingotiFullscreenStatus
							success={false}
							tagline='Oh crumbs!'
							message='Looks like that failed successfully, please click the link below so we can give it another go'>
							<Link href={portalUrl}>Try again</Link>
						</FingotiFullscreenStatus>
					</Box>
				</Layout>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
