import React, { useState } from 'react';
import { FingotiHeader } from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { DeviceIcon } from '../../../../icons/FingotIcons';

import { VyneDevice } from './VyneGatewayDevice';
import { VyneGatewayNetwork } from './VyneGatewayNetwork';
import { VyneGatewayGPIO } from './VyneGatewayGPIO';
import { VyneGatewayNode } from './VyneGatewayNode';
import { GatewayTimer } from '../GatewayTimer';
import { GatewaySchedule } from '../GatewaySchedule';

import { RequestBuilder } from '../../../../services/request.service';



const useStyles = makeStyles((theme) => ({

    root: {
        width: '100%',
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: '100%'
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '10%',
        [theme.breakpoints.down('lg')]: {
            width: '13%',
        },
        [theme.breakpoints.down('md')]: {
            width: '20%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '25%',
        },
    },
    tab: {
        width: '100%',
    },
    tabRoot: {
        minWidth: 'unset',
    },
    tabLabelRight: {
        alignItems: 'unset',
        textAlign: 'right',
        paddingRight: theme.spacing(1),
    },
    boxPadding: {
        padding: theme.spacing(6),
        paddingTop: theme.spacing(3)
    }

}));

const TabPanel = (props) => {
    const { passClass, children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div className={passClass}>
                    {children}
                </div>
            )}
        </div>
    );
}


export const VyneGatewayTabs = ({ vyne }) => {

    const classes = useStyles();
    const [activeMenu, setActiveMenu] = useState(0);
    const hasBeenSeen = vyne.network.mac.ap !== null ? true : false;
    const reqBuilder = new RequestBuilder(vyne.id);

    const handleChange = (event, newValue) => {
        if (activeMenu === newValue) {
            return;
        }

        setActiveMenu(newValue);
    };

    const breadcrumbs = [
        { text: 'Devices', link: '/devices' },
        { text: 'Gateways', link: '/devices/gateways' },
        { text: vyne.device.name, link: '' }
    ]

    const tabProps = (index) => {

        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
            disabled: index === 0 ? false : !hasBeenSeen,
            classes: {
                wrapper: classes.tabLabelRight,
                root: classes.tabRoot
            }
        };

    }

    return (
        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                value={activeMenu}
                variant="fullWidth"
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                className={classes.tabs}
            >
                <Tab label="device" {...tabProps(0)} />
                <Tab label="gpio" {...tabProps(1)} />
                <Tab label="network" {...tabProps(2)} />
                <Tab label="node" {...tabProps(3)} />
                <Tab label="schedule" {...tabProps(4)} />
                <Tab label="timer" {...tabProps(5)} />
            </Tabs>

            <TabPanel passClass={classes.boxPadding} className={classes.tab} value={activeMenu} index={0}>
                <FingotiHeader breadcrumbs={breadcrumbs} sectionIcon={DeviceIcon} badge={{ show: true, online: vyne.device.cloud.connected }} />
                <VyneDevice vyne={vyne} />
            </TabPanel>

            <TabPanel passClass={classes.boxPadding} className={classes.tab} value={activeMenu} index={1}>
                <FingotiHeader breadcrumbs={breadcrumbs} sectionIcon={DeviceIcon} badge={{ show: true, online: vyne.device.cloud.connected }} />
                <VyneGatewayGPIO request={reqBuilder} vyne={vyne} />
            </TabPanel>

            <TabPanel passClass={classes.boxPadding} className={classes.tab} value={activeMenu} index={2}>
                <FingotiHeader breadcrumbs={breadcrumbs} sectionIcon={DeviceIcon} badge={{ show: true, online: vyne.device.cloud.connected }} />
                <VyneGatewayNetwork vyne={vyne} />
            </TabPanel>

            <TabPanel passClass={classes.boxPadding} className={classes.tab} value={activeMenu} index={3}>
                <FingotiHeader breadcrumbs={breadcrumbs} sectionIcon={DeviceIcon} badge={{ show: true, online: vyne.systemCloud }} />
                <VyneGatewayNode vyne={vyne} />
            </TabPanel>

            <TabPanel passClass={classes.boxPadding} className={classes.tab} value={activeMenu} index={4}>
                <FingotiHeader breadcrumbs={breadcrumbs} sectionIcon={DeviceIcon} badge={{ show: true, online: vyne.device.cloud.connected }} />
                <GatewaySchedule device={vyne} />
            </TabPanel>

            <TabPanel passClass={classes.boxPadding} className={classes.tab} value={activeMenu} index={5}>
                <FingotiHeader breadcrumbs={breadcrumbs} sectionIcon={DeviceIcon} badge={{ show: true, online: vyne.device.cloud.connected }} />
                <GatewayTimer device={vyne} />
            </TabPanel>
        </div>
    );

}