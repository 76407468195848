import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FingotiLoading, FingotiNoPermission } from '@fingoti/components';

import { useDeviceState } from '../../../context/DeviceContext';
import { deviceType } from '../_deviceFunctions';
import { PeblTabs } from './pebl/PeblTabs';
import { VyneGatewayTabs } from './vyne/VyneGatewayTabs';
import { useRoleCheck } from '../../utils/RoleCheck';

export const GatewayTabs = () => {
	const history = useHistory();
	const { id } = useParams();
	const { roleCheck } = useRoleCheck();
	const { peblGateways, vyneGateways, loaded } = useDeviceState();
	const [device, setDevice] = useState({});
	const [thisLoading, setThisLoading] = useState(true);

	useEffect(() => {
		setThisLoading(true);
		if (peblGateways && vyneGateways && loaded) {
			getDevice();
		}
	});

	const getDevice = () => {
		let allDevices = [...peblGateways, ...vyneGateways];
		let foundDevice = allDevices.find((d) => d.id === id);

		if (!foundDevice) {
			history.push('/devices/gateways');
		} else {
			setDevice(foundDevice);
			setThisLoading(false);
		}
	};

	if (thisLoading) {
		return <FingotiLoading />;
	} else if (roleCheck('device', 1)) {
		return <FingotiNoPermission />;
	} else {
		let masterPart = device.partNumber.includes('-')
			? device.partNumber.split('-')[0]
			: device.partNumber;
		if (deviceType[masterPart] === 'pebl') {
			return <PeblTabs pebl={device} />;
		} else {
			return <VyneGatewayTabs vyne={device} />;
		}
	}
};
