import React, { useEffect } from 'react';

import { WidgetGrid } from '../../WidgetGrid';
import { VyneLux } from './widgets/VyneLux';
import { VyneDataTimestamp } from './widgets/VyneDataTimestamp';

const gridLayout = {
    xl: [
        { i: '1', x: 0, y: 0, w: 3, h: 4 },
        { i: '2', x: 3, y: 0, w: 3, h: 4 },
    ],
    lg: [
        { i: '1', x: 0, y: 0, w: 3, h: 4 },
        { i: '2', x: 3, y: 0, w: 4, h: 4 },
    ],
    md: [
        { i: '1', x: 0, y: 0, w: 6, h: 4 },
        { i: '2', x: 0, y: 4, w: 6, h: 4 },
    ],
    sm: [
        { i: '1', x: 0, y: 0, w: 4, h: 4 },
        { i: '2', x: 0, y: 4, w: 4, h: 4 },
    ],
}

export const VyneDataLux = ({ node }) => {

    useEffect(() => { }, [node])

    const values = node.type.getData(node.data.value);

    return (
        <>
            <WidgetGrid layouts={gridLayout}>
                <div key='1'>
                    <VyneLux data={values} />
                </div>
                <div key='2'>
                    <VyneDataTimestamp data={node.data.timestamp} />
                </div>
            </WidgetGrid>
        </>
    );

}