import React, { useState, useEffect } from 'react';
import { FingotiHeader, FingotiLoading } from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { DeviceIcon } from '../../../icons/FingotIcons';

import { DevicesView } from '../DevicesView';
import { DeviceList } from '../DeviceList';
import { DeviceDetails } from '../DeviceDetails';

import { useDeviceState } from '../../../context/DeviceContext';
import { sortDevices } from '../_deviceFunctions';

const useStyles = makeStyles(theme => ({

    noneSelected: {
        paddingLeft: theme.spacing(4),
        height: '100%',
        display: 'flex',
    },
    noneSelectedContent: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    noneSelectedIcon:{
        fontSize: '3rem',
        color: theme.palette.greyOne.main
    },
    invalidNodeWrapper: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    invalidNode: {
        borderRadius: theme.spacing(1) / 2,
        padding: theme.spacing(4),
        margin: theme.spacing(2),
        color: theme.palette.error.main,
        textAlign: 'center',
    },
    invalidNodeIcon: {
        fontSize: '8rem',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2)
    }

}))


export const Nodes = () => {

    const classes = useStyles(); //Called here and passed to avoid breaking hook rules 
    const { vyneNodes, loaded } = useDeviceState();
    const [thisLoading, setThisLoading] = useState(true);
    const [selectedNode, setSelectedNode] = useState({});
    const [devices, setDevices] = useState([]);
    const [sortActive, setSortActive] = useState(false);
    const [sortDetails, setSortDetails] = useState(["online", "asc"]);

    useEffect(() => {
        let persistedSort = localStorage.getItem("device-sort");
        if (loaded) {
            if (sortActive || persistedSort != null) {
                let sd = persistedSort !== null ? JSON.parse(persistedSort) : sortDetails;
                let sortedDevices = sortDevices(
                    [...vyneNodes],
                    sd[0],
                    sd[1]
                );
                setDevices(sortedDevices);
            } else {
                setDevices([...vyneNodes])
            }
            setThisLoading(false);
        }
    }, [loaded, vyneNodes, selectedNode])

    const applySort = () => {
        setSortActive(true);
        localStorage.setItem("device-sort", JSON.stringify(sortDetails));
        let sortedDevices = sortDevices(
            [...vyneNodes],
            sortDetails[0],
            sortDetails[1]
        );
        setDevices(sortedDevices);
    }

    const breadcrumbs = [
        { text: 'Devices', link: '/devices' },
        { text: 'Nodes', link: '' }
    ]

    const handleCardClick = (pebl) => {
        setSelectedNode(pebl);
    }

    const unsetSelectedNode = () => {
        setSelectedNode({});
    }

    return (
        thisLoading ?
            <FingotiLoading />
            :
            <>
                <FingotiHeader
                    breadcrumbs={breadcrumbs}
                    sectionIcon={DeviceIcon}
                />
                <DevicesView
                    isNodes={true}
                    deviceDetails={DeviceDetails(selectedNode, classes)}
                    deviceList={DeviceList(devices, handleCardClick, unsetSelectedNode, selectedNode,
                        applySort, setSortDetails)}
                />
            </>
    )
}