import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import { FingotiLoading } from '@fingoti/components';

import { useDeviceState } from '../../../context/DeviceContext';
import { VyneNodeTabs } from './vyne/VyneNodeTabs';

export const NodeTabs = () => {

    const history = useHistory();
    const { id } = useParams();
    const { vyneNodes, vyneGateways, loaded } = useDeviceState();
    const [device, setDevice] = useState({});
    const [thisLoading, setThisLoading] = useState(true);

    useEffect(() => {
        setThisLoading(true);
        if (vyneNodes && loaded) {
            getDevice();
        }
    });

    const getDevice = () => {
        let foundDevice = vyneNodes.find(d => d.id === id);
        
        if (!foundDevice) {
            history.push('/devices/nodes');
        } else {
            setDevice(foundDevice);
            setThisLoading(false);
        }
    }

    if (thisLoading) {
        return (
            <FingotiLoading />
        )
    } else {
        return (
            <VyneNodeTabs node={device} />
        )
    }

}