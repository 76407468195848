import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { WidgetWrapper } from '../../WidgetWrapper';
import { RequestBuilder } from '../../../../services/request.service';
import useDeviceErrorHandler from '../../useDeviceErrorHandler';

const useStyles = makeStyles((theme) => ({
	jsonReq: {
		fontFamily: "'Courier New', monospace",
	},
}));

export const TimerRequest = ({ device }) => {
	const errorHandler = useDeviceErrorHandler();
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [request, setRequest] = useState(
		JSON.stringify({ request: device.timer.request }, null, 2)
	);
	const [saveEnabled, setSaveEnabled] = useState(false);
	const [clearEnabled, setClearEnabled] = useState(true);
	const rb = new RequestBuilder(device.id);

	useEffect(() => {
		if (!saveEnabled) {
			setRequest(JSON.stringify({ request: device.timer.request }, null, 2));
		}
	}, [device]);

	const enableSave = () => {
		if (saveEnabled) {
			return;
		}

		setSaveEnabled(true);
	};

	const handleRequestSave = () => {
		let requestJson;
		try {
			requestJson = JSON.parse(request);
		} catch (e) {
			enqueueSnackbar('JSON not valid', { variant: 'error' });
			return;
		}

		rb.addRequest('timerRequest', 'W', JSON.parse(request));

		rb.send()
			.then(() => {
				enqueueSnackbar('Request successfully saved', { variant: 'success' });
				setRequest(JSON.stringify(requestJson, null, 2));
				setSaveEnabled(false);
			})
			.catch((res) => errorHandler(res));
	};

	const handleRequestClear = () => {
		setClearEnabled(false);
		rb.addRequest('timerRequest', 'W', { clear: true });

		rb.send()
			.then(() => {
				enqueueSnackbar('Request successfully cleared', { variant: 'success' });
				setSaveEnabled(false);
			})
			.catch((res) => errorHandler(res))
			.finally(() => setClearEnabled(true));
	};

	return (
		<WidgetWrapper
			title='Request'
			saveEnabled={saveEnabled}
			onSave={handleRequestSave}
			icons={[
				<IconButton
					onClick={handleRequestClear}
					disabled={device.timer.request.length === 0 || !clearEnabled}>
					<DeleteOutlinedIcon />
				</IconButton>,
			]}>
			<TextField
				id='requestjson'
				label='request json'
				multiline
				rowsMax={14}
				rows={14}
				value={request}
				onChange={(e) => {
					setRequest(e.target.value);
					enableSave();
				}}
				variant='outlined'
				InputProps={{
					classes: {
						input: classes.jsonReq,
					},
				}}
			/>
		</WidgetWrapper>
	);
};
