import React, { useState } from 'react';
import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

import IconButton from '@material-ui/core/IconButton';

import { WidgetWrapper } from '../../WidgetWrapper';

//All this pins stuff is a bit confusing...
//
// Direction:
//  0 - false - out
//  1 - true - in
//
// State:
//  0 - false - off
//  1 - true - on

const useStyles = makeStyles((theme) => ({
	table: {
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	headerCell: {
		border: 'none',
		textAlign: 'right',
		borderRight: '1px solid ' + theme.palette.greyFour.main,
		width: '20%',
		color: theme.palette.greyTwo.main,
	},
	noBottomBorder: {
		border: 'none',
		textAlign: 'center',
	},
	roleButtonGroup: {
		'&:not(:last-child)': {
			borderRight: '1px solid #ccc',
		},
	},
	textRoleButton: {
		padding: '6px 16px',
	},
}));

export const GPIODirection = ({ gpio, setGPIO, eventHandlers }) => {
	const classes = useStyles();
	const [sendEnabled, setSendEnabled] = useState(false);

	const enableSend = () => {
		if (sendEnabled) {
			return;
		}

		setSendEnabled(true);
	};

	const handleSend = () => {
		eventHandlers.sectionSend(gpio);
		setSendEnabled(false);
	};

	const handleSave = () => {
		eventHandlers.sectionSave();
	};

	const GPIODirectionRow = ({ gpio, gpioNo }) => {
		return (
			<TableRow key={`${gpioNo}`}>
				<TableCell className={classes.headerCell}>{gpioNo + 1}</TableCell>
				<TableCell className={classes.noBottomBorder}>
					<ButtonGroup disableElevation>
						<Button
							color='default'
							variant={gpio.direction ? 'contained' : 'text'}
							onClick={() => {
								setGPIO({
									action: 'CHNG_DIR',
									payload: { gpio: gpioNo, value: true },
								});
								enableSend();
							}}
							className={clsx(classes.textRoleButton, classes.roleButtonGroup)}>
							Input
						</Button>
						<Button
							color={!gpio.direction ? 'primary' : 'default'}
							variant={!gpio.direction ? 'contained' : 'text'}
							onClick={() => {
								setGPIO({
									action: 'CHNG_DIR',
									payload: { gpio: gpioNo, value: false },
								});
								enableSend();
							}}
							className={clsx(classes.textRoleButton, classes.roleButtonGroup)}>
							Output
						</Button>
					</ButtonGroup>
				</TableCell>
			</TableRow>
		);
	};

	const icons = [
		<IconButton onClick={handleSave}>
			<SaveOutlinedIcon />
		</IconButton>,
	];

	return (
		<WidgetWrapper
			title='Direction'
			icons={icons}
			saveEnabled={sendEnabled}
			onSave={handleSend}>
			<TableContainer className={classes.table}>
				<Table size='small'>
					<TableBody>
						{gpio.map((g, i) => {
							return <GPIODirectionRow key={`${i}`} gpio={g} gpioNo={i} />;
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</WidgetWrapper>
	);
};
