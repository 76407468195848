import React, { useEffect } from 'react';

import { WidgetGrid } from '../../WidgetGrid';
import { Identification } from '../../shared/widgets/Identification';
import { Power } from '../../shared/widgets/Power';
import { Version } from '../../shared/widgets/Version';
import { GatewayActions } from '../../shared/widgets/GatewayActions';

const gridLayout = {
    xl: [
        { i: '1', x: 0, y: 0, w: 3, h: 7 },
        { i: '2', x: 0, y: 7, w: 3, h: 7 },
        { i: '3', x: 3, y: 0, w: 3, h: 7 },
        { i: '4', x: 6, y: 0, w: 3, h: 8 },
        { i: '5', x: 3, y: 7, w: 3, h: 4 },

    ],
    lg: [
        { i: '1', x: 0, y: 0, w: 3, h: 7 },
        { i: '2', x: 3, y: 0, w: 3, h: 7 },
        { i: '3', x: 6, y: 0, w: 3, h: 7 },
        { i: '4', x: 0, y: 7, w: 3, h: 8 },
        { i: '5', x: 3, y: 7, w: 4, h: 4 },
    ],
    md: [
        { i: '1', x: 0, y: 0, w: 3, h: 7 },
        { i: '2', x: 0, y: 7, w: 3, h: 7 },
        { i: '3', x: 3, y: 7, w: 3, h: 7 },
        { i: '4', x: 3, y: 14, w: 3, h: 8 },
        { i: '5', x: 0, y: 14, w: 3, h: 4 },
    ],
    sm: [
        { i: '1', x: 0, y: 0, w: 4, h: 7 },
        { i: '2', x: 0, y: 7, w: 4, h: 7 },
        { i: '3', x: 0, y: 26, w: 4, h: 7 },
        { i: '4', x: 0, y: 19, w: 4, h: 8 },
        { i: '5', x: 0, y: 12, w: 4, h: 4 },
    ],
}

export const VyneDevice = ({ vyne }) => {

    return (
        <WidgetGrid layouts={gridLayout}>
            <div key='1'>
                <Identification deviceName={vyne.device.name} deviceId={vyne.id} />
            </div>
            <div key='2'>
                <Power currentPower={(vyne.device.power ? vyne.device.power.current / 1000 : 0).toFixed(1)} maxPower={130} minPower={60} />
            </div>
            <div key='3'>
                <Power voltage power={(vyne.device.power ? vyne.device.power.voltage / 1000 : 0).toFixed(1)} maxPower={16} minPower={8} />
            </div>
            <div key='4'>
                <GatewayActions device={vyne} />
            </div>
            <div key='5'>
                <Version deviceVersion={vyne.device.version} />
            </div>
        </WidgetGrid>
    );

}