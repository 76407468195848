import React, { useEffect } from 'react';

import { WidgetGrid } from '../../WidgetGrid';
import { PeblI2CDetect } from './widgets/PeblI2CDetect';
import { PeblI2CCommand } from './widgets/PeblI2CCommand';
import { PeblI2CSetup } from './widgets/PeblI2CSetup';

const gridLayout = {
	xl: [
        { i: '1', x: 0, y: 0, w: 4, h: 12 },
		{ i: '2', x: 4, y: 0, w: 6, h: 12 },
		{ i: '3', x: 0, y: 12, w: 3, h: 4 },
	],
	lg: [
        { i: '1', x: 0, y: 0, w: 3, h: 12 },
		{ i: '2', x: 4, y: 0, w: 6, h: 12 },
		{ i: '3', x: 0, y: 12, w: 3, h: 4 },
	],
	md: [
		{ i: '1', x: 0, y: 0, w: 6, h: 13 },
		{ i: '2', x: 0, y: 13, w: 6, h: 10 },
		{ i: '3', x: 0, y: 23, w: 6, h: 4 },
	],
	sm: [
		{ i: '1', x: 0, y: 0, w: 6, h: 13 },
		{ i: '2', x: 0, y: 13, w: 6, h: 24 },
		{ i: '3', x: 0, y: 37, w: 3, h: 4 },
	],
};

export const PeblI2C = ({ pebl }) => {

	useEffect(() => {}, [pebl]);

	return (
		<WidgetGrid layouts={gridLayout}>
			<div key='1'>
				<PeblI2CCommand pebl={pebl} />
			</div>
			<div key='2'>
				<PeblI2CDetect busMode={pebl.device.bus.protocol} i2cDetect={pebl.i2c.detect} pebl={pebl} />
			</div>
			<div key='3'>
				<PeblI2CSetup pebl={pebl} />
			</div>
		</WidgetGrid>
	);
};
