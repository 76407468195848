import React, { useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import {
	FingotiButton,
	FingotiModalActions,
	FingotiModalContent,
} from '@fingoti/components';
import { IMaskMixin } from 'react-imask';

import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import { apiService } from '../../services/api.service';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
	},
	content: {
		display: 'flex',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '75%',
		},
		alignItems: 'center',
		flexDirection: 'column',
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
	},
	key: {
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
		marginBottom: theme.spacing(4),
	},
	alert: {
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'space-evenly',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
	},
	button: {
		width: '42%',
	},
}));

const MaskedTextField = IMaskMixin(({ ...props }) => <TextField {...props} />);

const keyTypeEnum = {
	0: 'Money',
	1: 'Ripple',
	2: 'Email',
	3: 'SMS',
};

export const RedeemKey = ({ setOpen }) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [key, setKey] = useState('');
	const [submitting, setSubmitting] = useState(false);
	const [checking, setChecking] = useState(false);
	const [checked, setChecked] = useState(false);
	const [valid, setValid] = useState(false);
	const [keyData, setKeyData] = useState(undefined);
    const regEx = new RegExp('^([A-H]|[J-N]|[P-Z]|[0-9]){4}-([A-H]|[J-N]|[P-Z]|[0-9]){4}-([A-H]|[J-N]|[P-Z]|[0-9]){4}-([A-H]|[J-N]|[P-Z]|[0-9]){4}$')
	let isChecking = false;

	const submitKey = () => {
		setSubmitting(true);
		apiService
			.postData('/store/key', { key: key })
			.then((result) => {
				enqueueSnackbar(result.message, { variant: 'success' });
				setOpen(false);
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
			})
			.finally(() => {
				setSubmitting(false);
			});
	};

	const checkKey = (value) => {
        setValid(false);
        setChecked(false);
		if (regEx.test(value)) {
			setChecking(true);
			isChecking = true;
			let newValue = '';
			value.split('-').forEach((p) => (newValue = newValue + p));
			apiService
				.getData(`/store/key/${newValue}`)
				.then((result) => {
					console.log(result);
					setValid(true);
					setKeyData(result);
				})
				.catch((error) => {
					setKeyData({ message: error });
				})
				.finally(() => {
					setChecking(false);
					setChecked(true);
				});
		} else {
            setKeyData({ message: 'Key format is incorrect' });
            setChecking(false);
            setChecked(true);
        }
	};

	const handleKeyChange = (value) => {
		let newValue = value.toUpperCase();
		setKey(newValue);

		if (value.length === 19 && !isChecking) {
			checkKey(newValue);
		}
	};

	return (
		<>
			<FingotiModalContent>
				<div className={classes.root}>
					<div className={classes.content}>
						<FormControl fullWidth variant='outlined' className={classes.key}>
							<MaskedTextField
								autoFocus
								mask='****-****-****-****'
								fullWidth
								required
								disabled={checking}
								id='storeKey'
								name='storeKey'
								variant='outlined'
								label='Key'
								value={key}
								onAccept={handleKeyChange}
								InputLabelProps={{ shrink: key.length > 0 ? true : false }}
								inputProps={{ maxLength: 19 }}
							/>
						</FormControl>
						{checked && (
							<Alert
								severity={valid ? 'success' : 'error'}
								className={classes.alert}>
								<AlertTitle>{valid ? 'Key Valid' : 'Key Invalid'}</AlertTitle>
								{keyData.message}
								{valid && (
									<>
										<br />
										{`This key ${
											keyData.used ? 'has redeemed ' : 'will redeem '
										}`}
										{keyData.value.toLocaleString()}
										{` ${keyTypeEnum[keyData.type]}${
											keyData.type !== 0 && 's'
										}`}
									</>
								)}
							</Alert>
						)}
					</div>
				</div>
			</FingotiModalContent>
			<FingotiModalActions>
				<div className={classes.buttonGroup}>
					<FingotiButton
						light
						color='primary'
						className={classes.button}
						disabled={Boolean(key.length !== 19 || !valid)}
						onClick={submitKey}
						loading={submitting}>
						REDEEM
					</FingotiButton>
					<FingotiButton
						light
						className={classes.button}
						onClick={() => setOpen(false)}>
						CANCEL
					</FingotiButton>
				</div>
			</FingotiModalActions>
		</>
	);
};
