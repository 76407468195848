import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ProfileProvider } from './context/ProfileContext';
import { DeviceProvider } from './context/DeviceContext';
import { CookiesProvider } from 'react-cookie';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core';

import ErrorIcon from '@material-ui/icons/Error';

import MomentUtils from '@date-io/moment';

import App from './App';
import ErrorBoundary from './ErrorBoundary';

const lightTheme = createMuiTheme({
	palette: {
		primary: {
			dark: '#B26218',
			main: '#FF8C23',
			light: '#FFA34F',
			lighter: '#FFEFE1',
		},
		secondary: {
			dark: '#1862B2',
			main: '#238CFF',
			light: '#4FA3FF',
		},
		greyOne: {
			main: '#404040',
		},
		greyTwo: {
			main: '#808080',
		},
		greyThree: {
			main: '#C0C0C0',
		},
		greyFour: {
			main: '#E0E0E0',
			light: '#F0F0F0',
		},
	},
	typography: {
		fontFamily: ['Work Sans'].join(','),
	},
});

const AppWrapper = () => {
	return (
		<BrowserRouter>
			<ThemeProvider theme={lightTheme}>
				<SnackbarProvider
					maxSnack={3}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
					iconVariant={{
						warning: <ErrorIcon fontSize='small' style={{ marginRight: 8 }} />,
					}}>
					<CookiesProvider>
						<ProfileProvider>
							<DeviceProvider>
								<ErrorBoundary>
									<MuiPickersUtilsProvider utils={MomentUtils}>
										<App />
									</MuiPickersUtilsProvider>
								</ErrorBoundary>
							</DeviceProvider>
						</ProfileProvider>
					</CookiesProvider>
				</SnackbarProvider>
			</ThemeProvider>
		</BrowserRouter>
	);
};

export default AppWrapper;
