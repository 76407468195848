import React, { createRef, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
	FingotiModalContent,
	FingotiModalActions,
	FingotiButton,
} from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Popper from '@material-ui/core/Popper';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import YoutubeSearchedForOutlinedIcon from '@material-ui/icons/YoutubeSearchedForOutlined';

import Validation from '../../../../../services/validation.service';
import { useForceUpdate } from '../../../../utils/ForceUpdate';
import { RequestBuilder } from '../../../../../services/request.service';
import useDeviceErrorHandler from '../../../useDeviceErrorHandler';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	content: {
		display: 'flex',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '75%',
		},
		alignItems: 'center',
		flexDirection: 'column',
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'space-evenly',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
	},
	button: {
		width: '42%',
	},
}));

export const PeblMQTTCredentialChange = ({ setOpen, device }) => {
	const classes = useStyles();
	const errorHandler = useDeviceErrorHandler();
	const { enqueueSnackbar } = useSnackbar();
	const [username, setUsername] = useState(device.mqtt.setup.username);
	const [password, setPassword] = useState('');
	const [submitting, setSubmitting] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const rb = new RequestBuilder(device.id);

	const handleWifiSave = () => {
		setSubmitting(true);
		rb.addRequest('mqttSetup', 'W', {
			username: username,
			password: password,
		});
		rb.send()
			.then(() => {
				enqueueSnackbar('MQTT credentials saved successfully', {
					variant: 'success',
				});
				setSubmitting(false);
				setOpen(false);
			})
			.catch((res) => errorHandler(res))
			.finally(() => setSubmitting(false));
	};

	return (
		<>
			<FingotiModalContent>
				<div className={classes.root}>
					<div className={classes.content}>
						<Grid container spacing={2} alignItems='center'>
							<Grid item xs={12}>
								<FormControl fullWidth variant='outlined'>
									<TextField
										fullWidth
										required
										name='username'
										variant='outlined'
										label='username'
										value={username}
										onChange={(e) => setUsername(e.target.value)}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl
									fullWidth
									variant='outlined'
									className={classes.serial}>
									<TextField
										fullWidth
										required
										name='password'
										variant='outlined'
										label='Password'
										type={showPassword ? 'text' : 'password'}
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										InputProps={{
											endAdornment: (
												<IconButton
													aria-label='toggle password visibility'
													onClick={() => setShowPassword(!showPassword)}
													edge='end'>
													{showPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											),
										}}
									/>
								</FormControl>
							</Grid>
						</Grid>
					</div>
				</div>
			</FingotiModalContent>
			<FingotiModalActions>
				<div className={classes.buttonGroup}>
					<FingotiButton
						light
						color='primary'
						className={classes.button}
						onClick={handleWifiSave}
						loading={submitting}>
						SAVE
					</FingotiButton>
					<FingotiButton
						light
						className={classes.button}
						onClick={() => setOpen(false)}>
						CANCEL
					</FingotiButton>
				</div>
			</FingotiModalActions>
		</>
	);
};
