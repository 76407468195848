import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import { WidgetWrapper } from '../../WidgetWrapper';
import { RequestBuilder } from '../../../../services/request.service';
import useDeviceErrorHandler from '../../useDeviceErrorHandler';

export const ScheduleCron = ({ device, slot }) => {
	const cronArray = () => {
		return device.schedule.setup[slot].cron.split(' ');
	};

	const errorHandler = useDeviceErrorHandler();
	const { enqueueSnackbar } = useSnackbar();
	const [min, setMin] = useState(cronArray()[0]);
	const [hour, setHour] = useState(cronArray()[1]);
	const [dom, setDom] = useState(cronArray()[2]);
	const [month, setMonth] = useState(cronArray()[3]);
	const [dow, setDow] = useState(cronArray()[4]);
	const [saveEnabled, setSaveEnabled] = useState(false);
	const [errors, setErrors] = useState({});
	const rb = new RequestBuilder(device.id);

	useEffect(() => {
		if (!saveEnabled) {
			setMin(cronArray()[0]);
			setHour(cronArray()[1]);
			setDom(cronArray()[2]);
			setMonth(cronArray()[3]);
			setDow(cronArray()[4]);
		}
	}, [device, slot]);

	const handleSave = () => {
		rb.addRequest('scheduleCron', 'W', {
			slot: slot,
			cron: `${min} ${hour} ${dom} ${month} ${dow}`,
		});

		rb.send()
			.then(() => {
				enqueueSnackbar(`Schedule ${slot + 1} cron string successfully saved`, {
					variant: 'success',
				});
				setSaveEnabled(false);
			})
			.catch((res) => errorHandler(res));
	};

	const validateField = (e) => {
		const validationLookup = {
			min: {
				regex:
					/(\*|[0-9]|[12345][0-9]|\*\/[23456]|\*\/1[0]|\*\/1[25]|\*\/2[0]|\*\/3[0])/,
			},
			hour: {
				regex: /(\*|[0-9]|1[0-9]|2[0-3]|\*\/[23468]|\*\/1[2])/,
			},
			dom: {
				regex: /(\*|[1-9]|[12][0-9]|3[01])/,
			},
			month: {
				regex: /(\*|[1-9]|1[0-2])/,
			},
			dow: {
				regex: /(\*|[0-6])/,
			},
		};

		let eCopy = { ...errors };

		if (validationLookup[e.target.name].regex.test(e.target.value)) {
			delete eCopy[e.target.name];
			if (Object.keys(eCopy).length === 0) {
				setSaveEnabled(true);
			}
		} else {
			eCopy[e.target.name] = 'wrong';
			if (Object.keys(eCopy).length > 0) {
				setSaveEnabled(false);
			}
		}

		setErrors(eCopy);
	};

	return (
		<WidgetWrapper title='Cron' saveEnabled={saveEnabled} onSave={handleSave}>
			<Grid container spacing={1} justifyContent='space-around'>
				<Grid item xs={4} md={2}>
					<TextField
						id='min'
						name='min'
						label='min'
						InputProps={{
							inputProps: { min: 0, max: 59, style: { textAlign: 'center' } },
						}}
						value={min}
						onChange={(e) => {
							setMin(e.target.value);
							validateField(e);
						}}
						error={Boolean(errors['min'])}
						helperText={Boolean(errors['min']) ? errors['min'].msg : ''}
						variant='outlined'
					/>
				</Grid>
				<Grid item xs={4} md={2}>
					<TextField
						id='hour'
						name='hour'
						label='hour'
						InputProps={{
							inputProps: { min: 0, max: 23, style: { textAlign: 'center' } },
						}}
						value={hour}
						onChange={(e) => {
							setHour(e.target.value);
							validateField(e);
						}}
						error={Boolean(errors['hour'])}
						helperText={Boolean(errors['hour']) ? errors['hour'].msg : ''}
						variant='outlined'
					/>
				</Grid>
				<Grid item xs={4} md={2}>
					<TextField
						id='dom'
						name='dom'
						label='dom'
						InputProps={{
							inputProps: { min: 0, max: 31, style: { textAlign: 'center' } },
						}}
						value={dom}
						onChange={(e) => {
							setDom(e.target.value);
							validateField(e);
						}}
						error={Boolean(errors['dom'])}
						helperText={Boolean(errors['dom']) ? errors['dom'].msg : ''}
						variant='outlined'
					/>
				</Grid>
				<Grid item xs={4} md={2}>
					<TextField
						id='month'
						name='month'
						label='month'
						InputProps={{
							inputProps: { min: 0, max: 12, style: { textAlign: 'center' } },
						}}
						value={month}
						onChange={(e) => {
							setMonth(e.target.value);
							validateField(e);
						}}
						error={Boolean(errors['month'])}
						helperText={Boolean(errors['month']) ? errors['month'].msg : ''}
						variant='outlined'
					/>
				</Grid>
				<Grid item xs={4} md={2}>
					<TextField
						id='dow'
						name='dow'
						label='dow'
						InputProps={{
							inputProps: { min: 0, max: 12, style: { textAlign: 'center' } },
						}}
						value={dow}
						onChange={(e) => {
							setDow(e.target.value);
							validateField(e);
						}}
						onBlur={validateField}
						error={Boolean(errors['dow'])}
						helperText={Boolean(errors['dow']) ? errors['dow'].msg : ''}
						variant='outlined'
					/>
				</Grid>
			</Grid>
		</WidgetWrapper>
	);
};
