import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FingotiButton, FingotiHeader, FingotiLoading, FingotiModal } from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';

import { TotalUsageChart } from './TotalUsageChart';
import { TimespanSelect } from './TimespanSelect';
import { UsageByModuleChart } from './UsageByPropertyChart';
import { RedeemKey } from './RedeemKey';

import { useProfileState } from '../../context/ProfileContext';
import { apiService } from '../../services/api.service';
import { datetimeService } from '../../services/datetime.service';
import { MyDevices } from './MyDevices';
import { RippleStats } from './RippleStats';
import { useRoleCheck } from '../utils/RoleCheck';


const useStyles = makeStyles(theme => ({

    redeemButton: {
        marginRight: theme.spacing(2)
    },

}))


export const Dashboard = () => {

    const { enqueueSnackbar } = useSnackbar();
    const { roleCheck, RoleCheckWrapper } = useRoleCheck();
    const classes = useStyles();
    const { organisation, loading } = useProfileState();
    const [thisLoading, setThisLoading] = useState(true);
    const [timespan, setTimespan] = useState(7);
    const [dates, setDates] = useState([]);
    const [redeemOpen, setRedeemOpen] = useState(false);
    const [rawUsage, setRawUsage] = useState([]);
    const [usageByDate, setUsageByDate] = useState([]);
    const [usageByProperty, setUsageByProperty] = useState([]);
    const [usageByModule, setUsageByModule] = useState([]);
    const modules = ["device", "gpio", "timer", "wifi", "mqtt", "network", "schedule", "uart", "i2c", "unknown"];

    useEffect(() => {
        setThisLoading(true);
        if (organisation && !loading) {
            generateDateParams(timespan)
                .then(dates => {
                    getUsageData(dates)
                        .then(usage => {
                            processRipples(usage, dates);
                        })
                })
        }
    }, [organisation, loading, timespan])

    const generateDateParams = (noDays) => {
        return new Promise((resolve) => {
            let dateArray = [];
            let now = Date.now();
            let beginDate = new Date(now - (noDays - 1) * 24 * 60 * 60 * 1000);
            let currentDate = beginDate;

            while (currentDate <= now) {
                let ymdString = new Date(currentDate).toISOString().substring(0, 10);
                dateArray.unshift(ymdString);
                currentDate.setDate(currentDate.getDate() + 1);
            }

            setDates(dateArray);
            resolve(dateArray);
        })
    }

    const getUsageData = (dates) => {
        return new Promise((resolve, reject) => {
            apiService.getData(`/report/usage?start=${dates[dates.length - 1]}&end=${dates[0]}`)
                .then(result => {
                    setRawUsage(result.usage);
                    resolve(result.usage);
                })
                .catch(error => {
                    reject(error);
                    enqueueSnackbar(error, { variant: 'error' });
                })
        })
    }

    const processRipples = (rawUsage, dates) => {

        if (rawUsage.length < 1) {
            setThisLoading(false);
            return;
        }

        let properties;
        let totalByDate = [];
        let totalByPropertyByDate = [];
        let totalByProperty = [];
        let totalByModule = [];

        dates.forEach(date => {
            let dayTotal = 0;
            let propertyTotal = {};
            let dayUsage = rawUsage.filter(usage => usage.day.includes(date))

            //Get the properties & cut the crap
            properties = Object.keys(dayUsage[0] || rawUsage[0]);
            let orgIdIndex = properties.indexOf("organisationId");
            properties.splice(orgIdIndex, 1);
            let deviceIdIndex = properties.indexOf("deviceId");
            properties.splice(deviceIdIndex, 1);
            let dayIndex = properties.indexOf("day");
            properties.splice(dayIndex, 1);
            let totalIndex = properties.indexOf("total");
            properties.splice(totalIndex, 1);
            let usageIdIndex = properties.indexOf("usageId");
            properties.splice(usageIdIndex, 1);

            properties.forEach(prop => {
                propertyTotal[prop] = 0;
            })

            dayUsage.forEach(usage => {
                dayTotal += usage.total;

                properties.forEach(prop => {
                    propertyTotal[prop] += usage[prop];
                })
            })

            totalByDate.unshift({
                date: datetimeService.formatDate(new Date(date)),
                total: dayTotal
            });

            totalByPropertyByDate.unshift(propertyTotal);
        })

        properties.forEach(prop => {
            totalByProperty.unshift({
                property: prop,
                value: 0
            })
        })

        totalByPropertyByDate.forEach(total => {
            properties.forEach(prop => {
                let updateIndex = totalByProperty.findIndex(el => el.property === prop);
                totalByProperty[updateIndex].value = totalByProperty[updateIndex].value += total[prop]
            })
        })

        modules.forEach(module => {
            let totalForModule = 0;
            let propertiesForModule = properties.filter(p => p.startsWith(module));

            propertiesForModule.forEach(prop => {
                let index = totalByProperty.findIndex(el => el.property === prop);
                totalForModule = totalForModule += totalByProperty[index].value
            })

            totalByModule.unshift({ module: module, value: totalForModule });
        });


        setUsageByDate(totalByDate);
        setUsageByProperty(totalByProperty);
        setUsageByModule(totalByModule);
        setThisLoading(false);
    }

    const breadcrumbs = [
        { text: 'Dashboard', link: '' }
    ]

    const actionButtons = () => {
        return (
            <React.Fragment>
                <FingotiButton
                    className={classes.redeemButton}
                    light
                    color="primary"
                    onClick={() => setRedeemOpen(true)}
                    disabled={roleCheck("billing", 2)}
                >
                    Redeem Key
                </FingotiButton>
                <TimespanSelect timespan={timespan} onClick={setTimespan} />
            </React.Fragment>
        );
    }



    return (
        <Box p={2}>
            <FingotiModal title="Redeem Key" open={redeemOpen} setOpen={setRedeemOpen}>
                <RedeemKey setOpen={setRedeemOpen} />
            </FingotiModal>
            <FingotiHeader
                sectionIcon={DashboardOutlinedIcon}
                breadcrumbs={breadcrumbs}
                actionButtons={actionButtons()}
            />
            {
                thisLoading ?
                    <FingotiLoading />
                    :
                    <Grid container spacing={6}>
                        <RoleCheckWrapper realm='device' permission={1}>
                            <Grid item xs={12} sm={12} md={3} lg={4}>
                                <MyDevices />
                            </Grid>
                        </RoleCheckWrapper>
                        <RoleCheckWrapper realm='billing' permission={1}>
                            <Grid item xs={12} sm={12} md={7} lg={6}>
                                <RippleStats />
                            </Grid>
                        </RoleCheckWrapper>
                        <Grid item xs={12} sm={12} md={7} lg={6}>
                            <TotalUsageChart
                                insufficientData={Boolean(rawUsage.length < 1)}
                                data={usageByDate}
                                timespan={timespan}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={4}>
                            <UsageByModuleChart
                                insufficientData={Boolean(rawUsage.length < 1)}
                                data={usageByModule.filter(el => el.value !== 0)}
                            />
                        </Grid>
                    </Grid>
            }
        </Box>
    )

}