import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { WidgetWrapper } from '../../../WidgetWrapper';

import { RequestBuilder } from '../../../../../services/request.service';
import useDeviceErrorHandler from '../../../useDeviceErrorHandler';

export const PeblUARTSetup = ({ pebl }) => {
	const errorHandler = useDeviceErrorHandler();
	const { enqueueSnackbar } = useSnackbar();
	const [saveEnabled, setSaveEnabled] = useState(false);
	const [baudrate, setBaudrate] = useState(pebl.uart.setup.baudrate);
	const [databits, setDatabits] = useState(pebl.uart.setup.databits);
	const [parity, setParity] = useState(pebl.uart.setup.databits);
	const [stopbits, setStopbits] = useState(pebl.uart.setup.stopbits);
	const baudrateOptions = [
		300, 600, 1200, 2400, 4800, 9600, 19200, 31250, 38400, 57600, 74880, 115200,
		230400, 256000, 460800, 921600, 1843200, 3686400,
	];
	const databitsOptions = [5, 6, 7, 8];
	const parityOptions = [
		{ label: 'none', value: 0 },
		{ label: 'odd', value: 1 },
		{ label: 'even', value: 2 },
	];
	const stopbitsOptions = [
		{ label: 1, value: 0 },
		{ label: 1.5, value: 1 },
		{ label: 2, value: 2 },
	];
	const rb = new RequestBuilder(pebl.id);

	useEffect(() => {
		if (!saveEnabled) {
			setBaudrate(pebl.uart.setup.baudrate);
			setDatabits(pebl.uart.setup.databits);
			setParity(pebl.uart.setup.parity);
			setStopbits(pebl.uart.setup.stopbits);
		}
	}, [pebl]);

	const enableSave = () => {
		if (saveEnabled) {
			return;
		}

		setSaveEnabled(true);
	};

	const handleSectionSave = () => {
		rb.addRequest('uartSetup', 'W', {
			baudrate: baudrate,
			databits: databits,
			parity: parity,
			stopbits: stopbits,
		});
		rb.send()
			.then(() => {
				enqueueSnackbar(`UART setup successfully`, { variant: 'success' });
			})
			.catch((res) => errorHandler(res));
		setSaveEnabled(false);
	};

	return (
		<WidgetWrapper
			title='Setup'
			saveEnabled={saveEnabled}
			onSave={handleSectionSave}>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6}>
					<FormControl fullWidth variant='outlined'>
						<InputLabel id='baudrate-label'>baudrate</InputLabel>
						<Select
							variant='outlined'
							label='baudrate'
							labelId='baudrate-label'
							value={baudrate}
							onChange={(e) => {
								setBaudrate(e.target.value);
								enableSave();
							}}>
							{baudrateOptions.map((bro, i) => (
								<MenuItem key={i} value={bro}>
									{bro}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl fullWidth variant='outlined'>
						<InputLabel id='databits-label'>databits</InputLabel>
						<Select
							variant='outlined'
							label='databits'
							labelId='databits-label'
							value={databits}
							onChange={(e) => {
								setDatabits(e.target.value);
								enableSave();
							}}>
							{databitsOptions.map((dbo, i) => (
								<MenuItem key={i} value={dbo}>
									{dbo}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl fullWidth variant='outlined'>
						<InputLabel id='parity-label'>parity</InputLabel>
						<Select
							variant='outlined'
							label='parity'
							labelId='parity-label'
							value={parity}
							onChange={(e) => {
								setParity(e.target.value);
								enableSave();
							}}>
							{parityOptions.map((po, i) => (
								<MenuItem key={i} value={po.value}>
									{po.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl fullWidth variant='outlined'>
						<InputLabel id='stopbits-label'>stopbits</InputLabel>
						<Select
							variant='outlined'
							label='stopbits'
							labelId='stopbits-label'
							value={stopbits}
							onChange={(e) => {
								setStopbits(e.target.value);
								enableSave();
							}}>
							{stopbitsOptions.map((sbo, i) => (
								<MenuItem key={i} value={sbo.value}>
									{sbo.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
			</Grid>
		</WidgetWrapper>
	);
};
