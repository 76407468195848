import React, { useState, useEffect } from 'react';
import { FingotiHeader, FingotiTabs } from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Tab from '@material-ui/core/Tab';

import { DeviceIcon } from '../../../../icons/FingotIcons';

import { PeblDevice } from './PeblDevice';
import { PeblNetwork } from './PeblNetwork';
import { PeblGPIO } from './PeblGPIO';
import { PeblI2C } from './PeblI2C';
import { PeblUART } from './PeblUART';
import { PeblMQTT } from './PeblMQTT';
import { PeblWifi } from './PeblWifi';
import { GatewayTimer } from '../GatewayTimer';
import { GatewaySchedule } from '../GatewaySchedule';
import { IncompleteGateway } from '../IncompleteGateway';

import { RequestBuilder } from '../../../../services/request.service';


const useStyles = makeStyles((theme) => ({

    root: {
        width: '100%',
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: '100%'
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '10%',
        [theme.breakpoints.down('lg')]: {
            width: '13%',
        },
        [theme.breakpoints.down('md')]: {
            width: '20%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '25%',
        },
    },
    tab: {
        width: '100%',
    },
    tabRoot: {
        minWidth: 'unset',
    },
    tabLabelRight: {
        alignItems: 'unset',
        textAlign: 'right',
        paddingRight: theme.spacing(1),
    },
    boxPadding: {
        padding: theme.spacing(6),
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
        }
    }

}));

const TabPanel = (props) => {

    const { passClass, children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div className={passClass}>
                    {children}
                </div>
            )}
        </div>
    );

}

export const PeblTabs = ({ pebl }) => {

    const classes = useStyles();
    const [activeMenu, setActiveMenu] = useState(0);
    const reqBuilder = new RequestBuilder(pebl.id);
    const hasBeenSeen = pebl.network.mac.ap !== null ? true : false;

    useEffect(() => {
        return () => {
            localStorage.removeItem(`UART-DATA`);
        }
    }, [])

    const handleChange = (event, newValue) => {
        if (activeMenu === newValue) {
            return;
        }

        setActiveMenu(newValue);
    };

    const tabProps = (index) => {

        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
            disabled: index === 0 ? false : !hasBeenSeen,
            classes: {
                wrapper: classes.tabLabelRight,
                root: classes.tabRoot
            }
        };

    }

    const breadcrumbs = [
        { text: 'Devices', link: '/devices' },
        { text: 'Gateways', link: '/devices/gateways' },
        { text: pebl.device.name, link: '' }
    ]

    return (
        <div className={classes.root}>
            <FingotiTabs
                value={activeMenu}
                onChange={handleChange}
                aria-label="pebl settings tabs"
            >
                <Tab label="device" {...tabProps(0)} />
                <Tab label="I2C" {...tabProps(1)} />
                <Tab label="MQTT" {...tabProps(2)} />
                <Tab label="network" {...tabProps(3)} />
                <Tab label="gpio" {...tabProps(4)} />
                <Tab label="schedule" {...tabProps(5)} />
                <Tab label="timer" {...tabProps(6)} />
                <Tab label="UART" {...tabProps(7)} />
                <Tab label="wifi" {...tabProps(8)} />
            </FingotiTabs>

            <TabPanel passClass={classes.boxPadding} className={classes.tab} value={activeMenu} index={0}>
                <FingotiHeader breadcrumbs={breadcrumbs} sectionIcon={DeviceIcon} badge={{ show: true, online: pebl.device.cloud.connected }} />
                {
                    hasBeenSeen ?
                        <PeblDevice request={reqBuilder} pebl={pebl} />
                        :
                        <IncompleteGateway serial={pebl.id} />
                }
            </TabPanel>

            <TabPanel passClass={classes.boxPadding} className={classes.tab} value={activeMenu} index={1}>
                <FingotiHeader breadcrumbs={breadcrumbs} sectionIcon={DeviceIcon} badge={{ show: true, online: pebl.device.cloud.connected }} />
                <PeblI2C pebl={pebl} />
            </TabPanel>

            <TabPanel passClass={classes.boxPadding} className={classes.tab} value={activeMenu} index={2}>
                <FingotiHeader breadcrumbs={breadcrumbs} sectionIcon={DeviceIcon} badge={{ show: true, online: pebl.device.cloud.connected }} />
                <PeblMQTT pebl={pebl} />
            </TabPanel>

            <TabPanel passClass={classes.boxPadding} className={classes.tab} value={activeMenu} index={3}>
                <FingotiHeader breadcrumbs={breadcrumbs} sectionIcon={DeviceIcon} badge={{ show: true, online: pebl.device.cloud.connected }} />
                <PeblNetwork request={reqBuilder} pebl={pebl} />
            </TabPanel>

            <TabPanel passClass={classes.boxPadding} className={classes.tab} value={activeMenu} index={4}>
                <FingotiHeader breadcrumbs={breadcrumbs} sectionIcon={DeviceIcon} badge={{ show: true, online: pebl.device.cloud.connected }} />
                <PeblGPIO request={reqBuilder} pebl={pebl} />
            </TabPanel>

            <TabPanel passClass={classes.boxPadding} className={classes.tab} value={activeMenu} index={5}>
                <FingotiHeader breadcrumbs={breadcrumbs} sectionIcon={DeviceIcon} badge={{ show: true, online: pebl.device.cloud.connected }} />
                <GatewaySchedule device={pebl} />
            </TabPanel>

            <TabPanel passClass={classes.boxPadding} className={classes.tab} value={activeMenu} index={6}>
                <FingotiHeader breadcrumbs={breadcrumbs} sectionIcon={DeviceIcon} badge={{ show: true, online: pebl.device.cloud.connected }} />
                <GatewayTimer device={pebl} />
            </TabPanel>

            <TabPanel passClass={classes.boxPadding} className={classes.tab} value={activeMenu} index={7}>
                <FingotiHeader breadcrumbs={breadcrumbs} sectionIcon={DeviceIcon} badge={{ show: true, online: pebl.device.cloud.connected }} />
                <PeblUART pebl={pebl} />
            </TabPanel>

            <TabPanel passClass={classes.boxPadding} className={classes.tab} value={activeMenu} index={8}>
                <FingotiHeader breadcrumbs={breadcrumbs} sectionIcon={DeviceIcon} badge={{ show: true, online: pebl.device.cloud.connected }} />
                <PeblWifi pebl={pebl} />
            </TabPanel>
        </div>
    );

}