import React, { useEffect } from 'react';

import { WidgetGrid } from '../../WidgetGrid';
import { VyneAddress } from './widgets/VyneAddress';

const gridLayout = {
    xl: [
        { i: '1', x: 0, y: 0, w: 3, h: 5 },
    ],
    lg: [
        { i: '1', x: 0, y: 0, w: 3, h: 5 },
    ],
    md: [
        { i: '1', x: 0, y: 0, w: 3, h: 5 },
    ],
    sm: [
        { i: '1', x: 0, y: 0, w: 4, h: 5 },
    ],

}

export const VyneSetupLux = ({ node }) => {

    useEffect(() => { }, [node])

    return (
        <>
            <WidgetGrid layouts={gridLayout}>
                <div key='1'>
                    <VyneAddress node={node} />
                </div>
            </WidgetGrid>
        </>
    );

}