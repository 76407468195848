import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { WidgetWrapper } from '../../../WidgetWrapper';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({

    active: {
        color: theme.palette.primary.main
    },
    idle: {
        color: theme.palette.greyThree.main
    },

}))


export const VyneUps = ({ data }) => {

    const classes = useStyles();

    return (
        <WidgetWrapper title="Charging" displaySave={false}>
            <Typography variant="h3" className={data === 1 ? classes.active : classes.idle}>
                {data === 1 ? "TRUE" : "FALSE"}
            </Typography>
        </WidgetWrapper>
    );

}