import React from 'react';

import { FingotiLoading } from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { WidgetWrapper } from '../device/WidgetWrapper';
import { useDeviceState } from '../../context/DeviceContext';

const useStyles = makeStyles(theme => ({

    online: {
        fontSize: '4rem',
        color: theme.palette.primary.main,
    },
    claim: {
        fontSize: '4rem',
        color: theme.palette.secondary.main
    },
    smallText: {
        fontSize: '2.5rem',
        color: theme.palette.greyTwo.main,
    },
    labelText: {
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(1)
    }

}))

export const MyDevices = ({ data }) => {

    const classes = useStyles();
    const { loaded, onlineGateways, ownedDeviceCount, claimPendingCount, count } = useDeviceState();

    return (
        <WidgetWrapper displaySave={false} title='My Devices'>
            {
                !loaded ?
                    <FingotiLoading fullHeight={false} />
                    :
                    <Box>
                        <Box display='flex' alignItems='flex-end'>
                            <Typography className={classes.online} variant='h1'>{onlineGateways.length}<small className={classes.smallText}> / {ownedDeviceCount}</small> </Typography>
                            <Typography className={classes.labelText}>online</Typography>
                        </Box>
                        <Box display='flex' alignItems='flex-end'>
                            <Typography className={classes.claim} variant='h1'>{claimPendingCount}</Typography>
                            <Typography className={classes.labelText}>unclaimed</Typography>
                        </Box>
                    </Box>
            }
        </WidgetWrapper>
    );
}