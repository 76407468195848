import { refreshLock } from './auth.service';

export default class Queue {

    static queue = [];
    static pendingPromise = false;

    static enqueue(req) {
        console.log("Adding request to Q", [ req ])
        return new Promise((resolve, reject) => {
            this.queue.push({
                req: req,
                resolve: resolve,
                reject: reject,
            });
        });
    }

    static dequeue() {
        console.log(this.queue);
        console.log("Running de Q");
        if (this.workingOnPromise || refreshLock) {
            return false;
        }

        const item = this.queue.shift();

        if (!item) {
            return false;
        }

        try {
            this.workingOnPromise = true;
            console.log("Deq item", item);
            item.req()
                .then(value => {
                    this.workingOnPromise = false;
                    item.resolve(value);
                    this.dequeue();
                })
                .catch(err => {
                    this.workingOnPromise = false;
                    item.reject(err);
                    this.dequeue();
                })
        } catch (err) {
            console.error(`Q error ${err}`, err);
            this.workingOnPromise = false;
            item.reject(err);
            this.dequeue();
        }
        return true;
    }
}