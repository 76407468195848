import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import { WidgetWrapper } from '../../WidgetWrapper';

const useStyles = makeStyles((theme) => ({
	unknown: {
		color: theme.palette.greyThree.main,
		fontStyle: 'italic',
	},
}));

export const IPAddresses = ({ networkIp }) => {
	const classes = useStyles();

	return (
		<WidgetWrapper title='IP Address' displaySave={false}>
			<Typography>public</Typography>
			<Typography variant='h4' className={!networkIp.public && classes.unknown}>
				{networkIp.public ? networkIp.public : "unknown"}
			</Typography>
			<Typography>local</Typography>
			<Typography variant='h4'>{networkIp.local}</Typography>
		</WidgetWrapper>
	);
};
