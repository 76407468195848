import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import { WidgetWrapper } from '../../WidgetWrapper';

export const ScheduleSlot = ({ slot, setSelectedSlot }) => {

    return (
        <WidgetWrapper title="Slot" displaySave={false}>
            <FormControl fullWidth variant='outlined'>
                <InputLabel id='slot-label'>slot</InputLabel>
                <Select
                    variant='outlined'
                    label='slot'
                    labelId='slot-label'
                    value={slot}
                    onChange={(e) => {
                        setSelectedSlot(e.target.value);
                    }}
                >
                    {
                        [0, 1, 2, 3].map(s => <MenuItem key={s} value={s}>Schedule {s + 1}</MenuItem>)
                    }
                </Select>
            </FormControl>
        </WidgetWrapper >
    );

}