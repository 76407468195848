import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

import { DeviceGPIO } from '../../shared/DeviceGPIO';

import { datetimeService } from '../../../../services/datetime.service';
import { getWifiSSID } from '../../_deviceFunctions';

const useTableStyles = makeStyles((theme) => ({

    root: {
        paddingLeft: theme.spacing(4)
    },
    noBottomBorder: {
        border: 'none',
    },
    headerCell: {
        border: 'none',
        textAlign: 'right',
        borderRight: '1px solid ' + theme.palette.greyFour.main,
        width: '40%',
        color: theme.palette.greyTwo.main,
    },
	deviceTitle: {
        textAlign: 'left',
        marginLeft: theme.spacing(2),
		paddingBottom: theme.spacing(4),
	},
    pins: {
        display: 'flex',
        alignItems: 'center',
    },
    colour: {
        width: '20%',
        height: theme.spacing(3),
        borderRadius: theme.spacing(1),
    }

}))


export const VyneGatewayDetails = ({ vyne }) => {

    const classes = useTableStyles();
    const [uptime, setUptime] = useState('');

    useEffect(() => {
        getUptime(vyne.device.uptime.boot)
        const uptimeInterval = setInterval(() => getUptime(vyne.device.uptime.boot), 1000);

        return () => clearInterval(uptimeInterval);
    })

    const getUptime = (uptime) => {
        if (uptime) {
            let totalUptime = ((Date.now() - new Date(uptime)) / 1000)
            setUptime(datetimeService.formatUptime(totalUptime));
        }
    }


    return (
		<div className={classes.root}>
			<div className={classes.deviceTitle}>
				<Typography variant='h5'>Device Overview - {vyne.device.name}</Typography>
			</div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell className={classes.headerCell}>
                            serial
                    </TableCell>
                        <TableCell className={classes.noBottomBorder}>
                            {vyne.id}
                        </TableCell>
                    </TableRow>
                    {
                        vyne.claim.complete ?
                            ""
                            :
                            <>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>
                                        claim code
                                </TableCell>
                                    <TableCell className={classes.noBottomBorder}>
                                        {vyne.claim.code}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>
                                        claim expiry
                                </TableCell>
                                    <TableCell className={classes.noBottomBorder}>
                                        {datetimeService.formatDateTime(new Date(vyne.claim.expiry))}
                                    </TableCell>
                                </TableRow>
                            </>
                    }
                    <TableRow>
                        <TableCell className={classes.headerCell}>
                            status
                    </TableCell>
                        <TableCell className={classes.noBottomBorder}>
                            {vyne.device.cloud.connected ? 'Online' : 'Offline'}
                        </TableCell>
                    </TableRow>
                    {vyne.device.cloud.connected ?
                        <TableRow>
                            <TableCell className={classes.headerCell}>
                                uptime
                            </TableCell>
                            <TableCell className={classes.noBottomBorder}>
                                {vyne.device.cloud.connected ? uptime : "-"}
                            </TableCell>
                        </TableRow>
                        :
                        <></>
                    }
                    <TableRow>
                        <TableCell className={classes.headerCell}>
                            wifi ssid
                    </TableCell>
                        <TableCell className={classes.noBottomBorder}>
                            {
                                getWifiSSID(vyne.wifi.status.slot, vyne.wifi.credentials)
                            }
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.headerCell}>
                            ip address
                    </TableCell>
                        <TableCell className={classes.noBottomBorder}>
                            {vyne.network.ip.public} / {vyne.network.ip.local}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.headerCell}>
                            gpio
                    </TableCell>
                        <TableCell className={clsx(classes.noBottomBorder, classes.pins)}>
                            <DeviceGPIO data={vyne.gpio} gpio={0} />
                            <DeviceGPIO data={vyne.gpio} gpio={1} />
                            <DeviceGPIO data={vyne.gpio} gpio={2} />
                            <DeviceGPIO data={vyne.gpio} gpio={3} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.headerCell}>
                            last update
                    </TableCell>
                        <TableCell className={classes.noBottomBorder}>
                            {datetimeService.formatDateTime(new Date(vyne.lastUpdated))}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    )

}