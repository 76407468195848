import React from 'react';
import Grid from '@material-ui/core/Grid';

import { DeviceSort } from './DeviceSort';
import { DeviceCard } from './shared/DeviceCard';

export const DeviceList = (
	devices,
	onCardClick,
	unsetSelectedDeivce,
	selectedDevice,
	applySort,
	setSortDetails
) => {
	const handleCardClick = (pebl) => {
		onCardClick(pebl);
	};

	return (
		<Grid container spacing={4}>
			<DeviceSort
				onSortChange={(sd) => setSortDetails(sd)}
				onApplySort={applySort}
			/>
			{devices.map((device, i) => {
				let selected = selectedDevice
					? device.id === selectedDevice.id
						? true
						: false
					: false;

				return (
					<Grid item lg={6} xs={12} key={device.id}>
						<DeviceCard
							device={device}
							onClick={handleCardClick}
							unsetSelectedDevice={unsetSelectedDeivce}
							selected={selected}
							type={device.type}
						/>
					</Grid>
				);
			})}
		</Grid>
	);
};
