import React from 'react';
import JSONPretty from 'react-json-pretty';
import {
	FingotiButton,
	FingotiModalActions,
	FingotiModalContent,
} from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';


const useStyles = makeStyles((theme) => ({
    json: {
        padding: theme.spacing(2),
    },
	buttonGroup: {
		display: 'flex',
		justifyContent: 'space-evenly',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
	},
	button: {
		width: '42%',
	},
}));

export const ReportLogData = ({ data, setOpen }) => {
	const classes = useStyles();

	return (
		<>
			<FingotiModalContent>
				<div className={classes.json}>
					<JSONPretty data={data} />
				</div>
			</FingotiModalContent>

			<FingotiModalActions>
				<div className={classes.buttonGroup}>
					<FingotiButton
						light
						className={classes.button}
						onClick={() => setOpen(false)}>
						CLOSE
					</FingotiButton>
				</div>
			</FingotiModalActions>
		</>
	);
};
