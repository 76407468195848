import React, { createContext, useContext, useReducer } from 'react';
import { deviceProcessor, deviceUpdater } from './DeviceProcessor';
import _ from 'lodash';
import { PeblAppearance } from './../components/device/gateway/pebl/widgets/PeblAppearance';

const initialState = {
	count: 0,
	claimPendingCount: 0,
	ownedDeviceCount: 0,
	onlineGateways: [],
	peblGateways: [],
	vyneGateways: [],
	vyneNodes: [],
	loaded: false,
	lastUpdated: '',
};

const DeviceStateContext = createContext();
const DeviceDispatchContext = createContext();

const DeviceReducer = (state, action) => {
	let pl = action.payload;
	switch (action.type) {
		case 'LOADED':
			console.log('DEVICE CONTEXT - Loaded');
			return { ...state, loaded: true };
		case 'LOADING':
			console.log('DEVICE CONTEXT - Loading...');
			return { ...state, loaded: false };
		case 'RESET':
			console.log('DEVICE CONTEXT - Resetting...');
			return initialState;
		case 'ADD_DEVICES':
			console.log('DEVICE CONTEXT - Adding devices...');
			return {
				...state,
				peblGateways: pl.peblGateways,
				vyneGateways: pl.vyneGateways,
				vyneNodes: pl.vyneNodes,
				count: pl.count,
				claimPendingCount: pl.claimPendingCount,
				ownedDeviceCount: pl.ownedDeviceCount,
				onlineGateways: pl.onlineGateways,
			};
		case 'UPDATE_DEVICES':
			console.log('DEVICE CONTEXT - Updating devices...');
			let updatePl = _.cloneDeep(deviceUpdater(state, pl));
			return { ...updatePl };
		case 'REMOVE_DEVICES':
			console.log('DEVICE CONTEXT - Removing devices...');

			pl.peblGateways.forEach((rpg) => {
				let removeIndex = state.peblGateways.findIndex((pg) => pg.id === rpg);
				if (removeIndex !== -1) {
					state.peblGateways.splice(removeIndex, 1);
				}
			});

			pl.vyneGateways.forEach((rvg) => {
				let removeIndex = state.vyneGateways.findIndex((vg) => vg.id === rvg);
				if (removeIndex !== -1) {
					state.vyneGateways.splice(removeIndex, 1);
				}
			});

			pl.vyneNodes.forEach((rvn) => {
				let removeIndex = state.vyneNodes.findIndex((vn) => vn.id === rvn);
				if (removeIndex !== -1) {
					state.vyneNodes.splice(removeIndex, 1);
				}
			});

			let stateClone = _.cloneDeep(state);

			return { ...stateClone };
		case 'INIT_LOAD':
			console.log('INITLOAD', pl);
			let processedPl = deviceProcessor(pl);
			return {
				...state,
				loaded: true,
				peblGateways: processedPl.peblGateways,
				vyneGateways: processedPl.vyneGateways,
				vyneNodes: processedPl.vyneNodes,
				count: processedPl.count,
				claimPendingCount: processedPl.claimPendingCount,
				ownedDeviceCount: processedPl.ownedDeviceCount,
				onlineGateways: processedPl.onlineGateways,
			};
		default:
			throw new Error('Invalid action type passed to DeviceReducer');
	}
};

const DeviceProvider = ({ children }) => {
	const [state, dispatch] = useReducer(DeviceReducer, initialState);

	return (
		<DeviceStateContext.Provider value={state}>
			<DeviceDispatchContext.Provider value={dispatch}>
				{children}
			</DeviceDispatchContext.Provider>
		</DeviceStateContext.Provider>
	);
};

const useDeviceState = () => {
	const context = useContext(DeviceStateContext);

	if (context === undefined) {
		throw new Error('useDeviceState must be used within a DeviceProvider');
	}

	return context;
};

const useDeviceList = () => {
	const context = useContext(DeviceStateContext);

	if (context === undefined) {
		throw new Error('useDeviceList must be used within a DeviceProvider');
	}

	const peblSerials = context.peblGateways.map((pg) => ({
		id: pg.id,
		name: pg.name,
	}));
	const vyneSerials = context.vyneGateways.map((vg) => ({
		id: vg.id,
		name: vg.name,
	}));

	const devices = React.useMemo(
		() => [...peblSerials, ...vyneSerials],
		[context.loaded]
	);

	return devices;
};

const useDeviceDispatch = () => {
	const context = useContext(DeviceDispatchContext);

	if (context === undefined) {
		throw new Error('useDeviceDispatch must be used within a DeviceProvider');
	}

	return context;
};

export { DeviceProvider, useDeviceDispatch, useDeviceState, useDeviceList };
