import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import { WidgetWrapper } from '../../../WidgetWrapper';
import { useDeviceState } from '../../../../../context/DeviceContext';

const useStyles = makeStyles(theme => ({

    nodeGrid: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    nodeGridRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    node: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: theme.spacing(1) / 2,
        margin: theme.spacing(1) / 2,
        color: '#FFF',
    },
    invalid: {
        backgroundColor: theme.palette.warning.main,
    },
    device: {
        backgroundColor: theme.palette.success.main,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    disabled: {
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    unused: {
        backgroundColor: theme.palette.greyThree.main,
    },
    reserved: {
        backgroundColor: theme.palette.greyFour.main,
    },
    identifying: {
        animation: `$identifyFade 2s infinite`,
        transition: 'background-color 0.25s ease-in-out',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    "@keyframes identifyFade": {
        "0%": {
            backgroundColor: theme.palette.greyThree.main
        },
        "25%": {
            backgroundColor: theme.palette.info.main
        },
        "75%": {
            backgroundColor: theme.palette.info.main
        },
        "100%": {
            backgroundColor: theme.palette.greyThree.main
        },
    },

}))

export const VyneNodeDetect = ({ nodeDetect, eventHandlers }) => {

    const classes = useStyles();
    const history = useHistory();
    const { vyneNodes } = useDeviceState();

    const getGridSquare = (address) => {
        let foundNode = nodeDetect.find(nd => nd.address === address);

        if (address < 10) {
            address = `0${address}`;
        }

        if (foundNode) {
            let nodeObj = vyneNodes.find(vn => vn.id === foundNode.serial);

            if (nodeObj.partNumber === "000000") {
                return (
                    <div className={clsx(classes.node, classes.invalid)} key={address}>
                        {address}
                    </div>
                );
            } else if (!nodeObj.enable) {
                return (
                    <div onClick={() => history.push(`/devices/nodes/${nodeObj.id}`)} className={clsx(classes.node, classes.disabled)} key={address}>
                        {address}
                    </div>
                );
            } else if (nodeObj.identify) {
                return (
                    <div onClick={() => history.push(`/devices/nodes/${nodeObj.id}`)} className={clsx(classes.node, classes.identifying)} key={address}>
                        {address}
                    </div>
                );
            } else {
                return (
                    <div onClick={() => history.push(`/devices/nodes/${nodeObj.id}`)} className={clsx(classes.node, classes.device)} key={address}>
                        {address}
                    </div>
                );
            }
        } else {
            return (
                <div className={clsx(classes.node, classes.unused)} key={address}>
                    {address}
                </div>
            );
        }
    }

    let i = -1;
    return (
        <WidgetWrapper title="Detection" saveEnabled={false}>
            <Typography>address map</Typography>
            <div className={classes.nodeGrid}>
                {
                    new Array(4).fill(null).map((r, ri) => {
                        return (
                            <div className={classes.nodeGridRow} key={`row-${ri}`}>
                                {
                                    new Array(8).fill(null).map(() => {
                                        i++;
                                        return (
                                            getGridSquare(i + 1)
                                        )
                                    })
                                }
                            </div>
                        );
                    })
                }
            </div>
        </WidgetWrapper>
    );

}