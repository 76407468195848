import React from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { WidgetWrapper } from '../../WidgetWrapper';

export const Version = ({ deviceVersion }) => {

    return (
        <WidgetWrapper title="Version" displaySave={false}>
            <Grid container>
                <Grid item xs={6}>
                    <Typography>hardware</Typography>
                    <Typography variant="h4">{deviceVersion.hardware}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>software</Typography>
                    <Typography variant="h4">{deviceVersion.software}</Typography>
                </Grid>
            </Grid>
        </WidgetWrapper>
    );

}