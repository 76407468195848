import { useState, useEffect } from 'react';
import {
	FingotiButton,
	FingotiHeader,
	FingotiLoading,
	FingotiMarkdown,
	FingotiModal,
	FingotiTable,
} from '@fingoti/components';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

import { datetimeService } from '../../services/datetime.service';
import { apiService } from '../../services/api.service';
import { useDeviceList, useDeviceState } from '../../context/DeviceContext';
import { InputLabel } from '@material-ui/core';
import { ReportLogData } from './ReportLogData';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
	},
	text: {
		marginRight: theme.spacing(2),
	},
	active: {
		borderColor: theme.palette.primary.main,
		backgroundColor: 'rgba(255, 140, 35, 0.2)',
	},
	logTable: {
		marginTop: theme.spacing(6),
	},
	direction: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inbound: {
		color: theme.palette.primary.main,
	},
	outbound: {
		color: theme.palette.secondary.main,
	},
	selectDeviceWrapper: {
		display: 'flex',
		height: '50vh',
		alignItems: 'center',
		justifyContent: 'center',
	},
	selectDeviceIcon: {
		fontSize: '3rem',
		color: theme.palette.greyOne.main,
	},
	selectDevice: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

const CustomPopper = (props) => {
	return <Popper {...props} style={{ ...props.style, zIndex: 1400 }} />;
};

const TimespanSelect = ({ timespan, onClick }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<ButtonGroup disableElevation variant='outlined' color='primary'>
				<Button
					onClick={() => onClick(1)}
					className={clsx({ [classes.active]: timespan === 1 })}>
					1 Hours
				</Button>
				<Button
					onClick={() => onClick(3)}
					className={clsx({ [classes.active]: timespan === 3 })}>
					3 Hours
				</Button>
				<Button
					onClick={() => onClick(6)}
					className={clsx({ [classes.active]: timespan === 6 })}>
					6 Hours
				</Button>
				<Button
					onClick={() => onClick(12)}
					className={clsx({ [classes.active]: timespan === 12 })}>
					12 Hours
				</Button>
				<Button
					onClick={() => onClick(24)}
					className={clsx({ [classes.active]: timespan === 24 })}>
					24 Hours
				</Button>
			</ButtonGroup>
		</div>
	);
};

export const ReportLogs = ({ devices }) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	// const { loading, peblGateways, vyneGateways } = useDeviceState();
	const [timespan, setTimespan] = useState(3);
	// const [devices, setDevices] = useState([]);
	const [device, setDevice] = useState(undefined);
	const [direction, setDirection] = useState('both');
	const [logs, setLogs] = useState([]);
	const [thisLoading, setThisLoading] = useState(false);
	const [infoOpen, setInfoOpen] = useState(false);
	const [infoData, setInfoData] = useState({});

	console.log('running 1');

	useEffect(() => {
		console.log('pebls changed');
	}, [devices]);

	// useEffect(() => {
	// 	console.log("running ue");
	// 	if (!loading) {
	// 		setDevices([...peblGateways, ...vyneGateways]);
	// 		setThisLoading(false);
	// 	}
	// }, [loading]);

	const getMessageLogs = () => {
		console.log('getting messages');
		setThisLoading(true);
		let startTime = new Date();
		startTime.setHours(startTime.getHours() - timespan);

		let dir = direction === 'both' ? null : direction === 'inbound' ? 1 : 0;

		apiService
			.getData(
				`/report/${
					device.id
				}/commands?start=${startTime.toISOString()}&end=${new Date().toISOString()}&direction=${dir}`
			)
			.then((data) => {
				setLogs(data.commands);
			})
			.finally(() => {
				setThisLoading(false);
			});
	};

	const copyData = async (rd) => {
		let type = 'error';

		if (rd.payload) {
			type = 'payload';
		} else if (rd.result) {
			type = 'result';
		}

		try {
			await navigator.clipboard.writeText(JSON.stringify({ [type]: rd[type] }));
			enqueueSnackbar('Message data successfully copied to clipboard', {
				variant: 'success',
			});
		} catch (error) {
			enqueueSnackbar('Failed to copy message data to clipboard', {
				variant: 'error',
			});
		}
	};

	const directionRenderer = (rd) => {
		return (
			<div className={classes.direction}>
				{rd.direction === 0 ? (
					<ArrowBackOutlinedIcon className={classes.outbound} />
				) : (
					<ArrowForwardOutlinedIcon className={classes.inbound} />
				)}
			</div>
		);
	};

	const dataRenderer = (rd) => {
		let type = 'error';

		if (rd.payload) {
			type = 'payload';
		} else if (rd.result) {
			type = 'result';
		}

		return JSON.stringify({ [type]: rd[type] });
	};

	const timestampRenderer = (rd) => {
		return datetimeService.formatDateTime(new Date(rd.timestamp));
	};

	const handleInfoClick = (logId) => {
		let log = logs.find((l) => l.id === logId);

		if (log) {
			let type = 'error';
			if (log.payload) {
				type = 'payload';
			} else if (log.result) {
				type = 'result';
			}

			setInfoData({ [type]: log[type] });
			setInfoOpen(true);
		}
	};

	const breadcrumbs = [
		{ text: 'Reports', link: '/reports' },
		{ text: 'Command Log', link: '' },
	];

	return (
		<>
			<FingotiModal title='Log Data' open={infoOpen} setOpen={setInfoOpen}>
				<ReportLogData data={infoData} setOpen={setInfoOpen} />
			</FingotiModal>
			<FingotiHeader
				breadcrumbs={breadcrumbs}
				sectionIcon={AssignmentOutlinedIcon}
			/>
			<FingotiMarkdown path='/documentation/portal/report/logs.md' />
			<Grid container spacing={4} className={classes.logTable}>
				<Grid item xs={3}>
					<Autocomplete
						options={devices}
						getOptionLabel={(option) => `${option.device.name} - ${option.id}`}
						filterSelectedOptions
						onChange={(e, v) => {
							setDevice(v);
						}}
						renderInput={(params) => (
							<TextField variant='outlined' label='device' {...params} />
						)}
						PopperComponent={CustomPopper}
					/>
				</Grid>
				<Grid item xs={3}>
					<FormControl variant='outlined' fullWidth>
						<InputLabel>direction</InputLabel>
						<Select
							label='direction'
							value={direction}
							onChange={(e) => {
								setDirection(e.target.value);
							}}>
							<MenuItem value='both'>Both</MenuItem>
							<MenuItem value='inbound'>Inbound</MenuItem>
							<MenuItem value='outbound'>Outbound</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={4}>
					<TimespanSelect timespan={timespan} onClick={setTimespan} />
				</Grid>
				<Grid item xs={2}>
					<div className={classes.root}>
						<FingotiButton
							disabled={!device || !device.id}
							light
							color='primary'
							onClick={getMessageLogs}>
							Get logs
						</FingotiButton>
					</div>
				</Grid>
				<Grid item xs={12}>
					{!thisLoading ? (
						device ? (
							logs ? (
								<FingotiTable
									data={logs}
									columns={[
										{ title: 'id', field: 'id', hidden: true },
										{
											title: 'direction',
											render: directionRenderer,
											cellStyle: { width: '6%' },
											sorting: false,
										},
										{
											title: 'timestamp',
											render: timestampRenderer,
											cellStyle: { width: '14%' },
											customSort: (a, b) =>
												new Date(b.timestamp) - new Date(a.timestamp),
										},
										{
											title: 'type',
											field: 'type',
											cellStyle: { width: '10%' },
										},
										{
											title: 'property',
											field: 'property',
											cellStyle: { width: '12%' },
										},
										{
											title: 'data',
											render: dataRenderer,
											cellStyle: {
												maxWidth: 0,
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
											},
											filtering: false,
											sorting: false,
										},
									]}
									options={{
										actionsCellStyle: {
											width: '8%',
										},
										sorting: true,
									}}
									actions={[
										(rowData) => ({
											icon: FileCopyOutlinedIcon,
											tooltip: 'copy message data',
											onClick: () => copyData(rowData),
										}),
										(rowData) => ({
											icon: InfoOutlinedIcon,
											tooltip: 'show full data',
											onClick: () => handleInfoClick(rowData.id),
										}),
									]}
								/>
							) : (
								<FingotiLoading />
							)
						) : (
							<div className={classes.selectDeviceWrapper}>
								<div className={classes.selectDevice}>
									<InfoOutlinedIcon className={classes.selectDeviceIcon} />
									<Typography>
										Please select a device from the dropdown to start seeing
										command log entries
									</Typography>
								</div>
							</div>
						)
					) : (
						<FingotiLoading />
					)}
				</Grid>
			</Grid>
		</>
	);
};
