import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { WidgetWrapper } from '../../WidgetWrapper';
import { DeviceGPIO } from '../DeviceGPIO';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({

    pinsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    pinWrapper: {
        textAlign: 'center',
        margin: theme.spacing(1),
        height: '75%'
    },

}))

export const GPIO = ({ gpio }) => {

    const classes = useStyles();

    return (
        <WidgetWrapper title="Overview" displaySave={false}>
            <div className={classes.pinsWrapper}>
                {
                    gpio.direction.map((g, i) => {
                        return (
                            <div key={`${i}`} className={classes.pinWrapper}>
                                <Typography>{i + 1}</Typography>
                                <DeviceGPIO data={gpio} gpio={i} />
                            </div>
                        );
                    })
                }
            </div>
        </WidgetWrapper>
    );

}