import React, { useState } from 'react';
import { useSnackbar } from 'notistack';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { WidgetWrapper } from '../../WidgetWrapper';
import { apiService } from '../../../../services/api.service';

export const Identification = ({ deviceName, deviceId, eventHandlers }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [saveEnabled, setSaveEnabled] = useState(false);
	const [name, setName] = useState(deviceName);

	const enableSave = () => {
		if (saveEnabled) {
			return;
		}

		setSaveEnabled(true);
	};

	const handleSectionSave = () => {
		apiService
			.patchData(`/device/${deviceId}`, { device: { name: name } })
			.then((result) => {
				enqueueSnackbar(result.message, { variant: 'success' });
				setSaveEnabled(false);
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
			});
	};

	const handleNameChange = (e) => {
		enableSave();
		setName(e.target.value);
	};

	return (
		<WidgetWrapper
			title='Identification'
			saveEnabled={saveEnabled}
			onSave={handleSectionSave}>
			<Typography>serial</Typography>
			<Typography variant='h4'>{deviceId}</Typography>
			<Typography>name</Typography>
			<TextField
				variant='outlined'
				value={name}
				onChange={handleNameChange}
				placeholder='My_New_Device'
			/>
		</WidgetWrapper>
	);
};
