import React, { useEffect, useState } from 'react';
import { FingotiButton } from '@fingoti/components';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

import { WidgetWrapper } from '../../../WidgetWrapper';
import { RequestBuilder } from '../../../../../services/request.service';
import useDeviceErrorHandler from '../../../useDeviceErrorHandler';

const useStyles = makeStyles((theme) => ({
	commandDisplay: {
		minHeight: '5rem',
		maxHeight: '5rem',
		overflowY: 'auto',
		borderRadius: theme.spacing(1) / 2,
		border: '1px solid' + theme.palette.greyThree.main,
		padding: theme.spacing(1) / 2 + 'px',
	},
	commandChip: {
		margin: theme.spacing(1) / 2 + 'px ' + theme.spacing(1) / 2 + 'px',
	},
	json: {
		fontFamily: "'Courier New', monospace",
	},
	send: {
		color: theme.palette.primary.main,
	},
	recv: {
		color: theme.palette.secondary.main,
	},
	writeChip: {
		'backgroundColor': '#96C8FF',
		'&:focus': {
			backgroundColor: '#96C8FF',
		},
	},
	readChip: {
		'backgroundColor': '#FFC896',
		'&:focus': {
			backgroundColor: '#FFC896',
		},
	},
	startChip: {
		'backgroundColor': '#B6E0B6',
		'&:focus': {
			backgroundColor: '#B6E0B6',
		},
	},
	stopChip: {
		'backgroundColor': '#EEA8A8',
		'&:focus': {
			backgroundColor: '#EEA8A8',
		},
	},
	delayChip: {
		'backgroundColor': '#FFE897',
		'&:focus': {
			backgroundColor: '#FFE897',
		},
	},
}));

const CommandsDisplay = ({ commands, removeCommand }) => {
	const classes = useStyles();

	return (
		<div className={classes.commandDisplay}>
			{commands.map((cmd, i) => {
				let label =
					cmd.action === 'S' ||
					cmd.action === 'P' ||
					cmd.action === 'R' ||
					cmd.action === 'W' ||
					cmd.action === 'D'
						? `${cmd.action}${cmd.value ? cmd.value : ''}`
						: cmd.action.trim() === ''
						? '?'
						: `${cmd.action}`;

				return (
					<Chip
						key={`${i}-${cmd.action}`}
						label={label}
						onDelete={() => removeCommand(i)}
						clickable={false}
						className={clsx(classes.commandChip, {
							[classes.startChip]: cmd.action === 'S',
							[classes.stopChip]: cmd.action === 'P',
							[classes.readChip]: cmd.action === 'R',
							[classes.writeChip]: cmd.action === 'W',
							[classes.delayChip]: cmd.action === 'D',
						})}
					/>
				);
			})}
		</div>
	);
};

const CopyAdornment = ({ disabled, onClick }) => {
	return (
		<InputAdornment position='end'>
			<IconButton disabled={disabled} aria-label='copy' onClick={onClick}>
				<FileCopyOutlinedIcon />
			</IconButton>
		</InputAdornment>
	);
};

/*
    This is the new code below

    action
        start - no info req
        setup - addr and mode req
        read - no of bytes
        write - byte
        stop - no info req

*/

export const PeblI2CCommand = ({ pebl }) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const errorHandler = useDeviceErrorHandler();
	const [selectedCommand, setSelectedCommand] = useState('S');
	const [bytes, setBytes] = useState('');
	const [saveEnabled, setSaveEnabled] = useState(true);
	const [commands, setCommands] = useState([]);
	const [commandString, setCommandString] = useState('[]');
	const [commandStringArray, setCommandStringArray] = useState([]);
	const [address, setAddress] = useState('3');
	const [recv, setRecv] = useState(pebl.i2c.data);
	const [hideBytes, setHideBytes] = useState(true);
	const [hasSent, setHasSent] = useState(false);
	const [bytesError, setBytesError] = useState(false);
	const [errors, setErrors] = useState([]);
	const [disableAdd, setDisableAdd] = useState(false);
	const rb = new RequestBuilder(pebl.id);

	useEffect(() => {
		getCommandArray(false);
	}, [commands]);

	useEffect(() => {
		setRecv(pebl.i2c.data);
	}, [pebl, hasSent]);

	const enableSave = () => {
		if (saveEnabled) {
			return;
		}

		setSaveEnabled(true);
	};

	const handleSave = () => {
		setSaveEnabled(false);
		rb.addRequest('i2cData', 'W', {
			address: parseInt(address),
			profile: commandStringArray,
		});
		rb.send()
			.then(() => {
				setHasSent(true);
				enqueueSnackbar(`i2c Data sent`, { variant: 'success' });
				setSaveEnabled(true);
			})
			.catch((res) => errorHandler(res))
			.finally(() => setSaveEnabled(true));
	};

	const handleCommandChange = (event) => {
		enableSave();
		setSelectedCommand(event.target.value);
		if (event.target.value === 'S' || event.target.value === 'P') {
			setHideBytes(true);
			setBytes('');
		} else {
			setHideBytes(false);
			setBytes(`${getMinMaxValues(event.target.value).min}`);
		}
	};

	const handleAddCommand = () => {
		if ((selectedCommand === 'R' || selectedCommand === 'W') && bytes === '') {
			setBytesError(true);
		} else {
			if (commands.length < 32) {
				let cmd = { action: selectedCommand, value: bytes };

				setCommands((prevCommands) => [...prevCommands, cmd]);
			} else {
				setDisableAdd(true);
			}
		}
	};

	const getCommandArray = (shouldReturn, cmds) => {
		let stringCommands = [];

		if (shouldReturn) {
			cmds.forEach((cmd) =>
				stringCommands.push(`${cmd.action}${cmd.value ? cmd.value : ''}`)
			);
		} else {
			commands.forEach((cmd) =>
				stringCommands.push(`${cmd.action}${cmd.value ? cmd.value : ''}`)
			);
		}

		if (shouldReturn) {
			return stringCommands;
		} else {
			setCommandStringArray(stringCommands);
			let stringifyStringCommands = JSON.stringify(stringCommands);
			setCommandString(stringifyStringCommands);
		}
	};

	const createCommandsObject = (event) => {
		let cmds = [];
		let clearErrors = true;
		let regEx = new RegExp(
			'^[Ss]$|^[Pp]$|^[Rr]([1-9]|[12][0-9]|3[0-2])$|^[Ww]([01]?[0-9]?[0-9]|2[0-5][0-5])$|^[Dd]([1-9]|[1-9][0-9]|[1234][0-9][0-9]|500)$'
		);
		try {
			let cmdsArray = JSON.parse(event.target.value);

			cmdsArray.forEach((c, i) => {
				let cParts = c.split('');
				if (regEx.test(c)) {
					if (cParts.length === 1) {
						cmds.push({ action: cParts[0].toUpperCase() });
					} else {
						let value = '';
						for (var i = 1; i < cParts.length; i++) {
							value = value + cParts[i];
						}
						cmds.push({ action: cParts[0].toUpperCase(), value: value });
					}
				} else {
					clearErrors = false;
					cmds.push({ action: c });
					setErrors((oldErrors) => [
						'i2c profile contains invalid commands',
						...oldErrors,
					]);
				}
			});

			if (clearErrors) {
				setErrors([]);
			}

			setCommands(cmds);
		} catch (e) {
			setErrors((prevErrors) => ['i2c profile is invalid', ...prevErrors]);
		}
	};

	const removeCommand = (index) => {
		let cmdsCopy = [...commands];
		cmdsCopy.splice(index, 1);
		createCommandsObject({
			target: { value: JSON.stringify(getCommandArray(true, cmdsCopy)) },
		});
	};

	const clearCommands = () => {
		setSelectedCommand('S');
		setBytes('');
		setSaveEnabled(false);
		setCommands([]);
		setCommandString('[]');
		setCommandStringArray([]);
		setAddress('');
		setRecv('');
		setHideBytes(true);
		setHasSent(false);
		setBytesError(false);
		setErrors([]);
	};

	const copyData = async (data) => {
		try {
			await navigator.clipboard.writeText(data);
			enqueueSnackbar('Successfully copied to clipboard', {
				variant: 'success',
			});
		} catch (error) {
			enqueueSnackbar('Failed to copy to clipboard', { variant: 'error' });
		}
	};

	const getMinMaxValues = (action) => {
		switch (action) {
			case 'R':
				return { min: 1, max: 32 };
			case 'W':
				return { min: 0, max: 255 };
			case 'D':
				return { min: 1, max: 500 };
		}
	};

	return (
		<WidgetWrapper
			title='Profile Builder'
			onSave={handleSave}
			saveEnabled={
				!Boolean(
					pebl.device.bus.protocol !== 1 ||
						address === '' ||
						commandString === '[]' ||
						errors.length > 0
				)
			}
			icons={[
				<IconButton
					onClick={clearCommands}
					disabled={commandString === '[]' && address === ''}>
					<DeleteOutlinedIcon />
				</IconButton>,
			]}>
			<Grid container spacing={2} alignItems='center'>
				<Grid item xs={12} md={5}>
					<FormControl
						variant='outlined'
						className={classes.formControl}
						fullWidth>
						<InputLabel id='command-label'>command</InputLabel>
						<Select
							variant='outlined'
							id='command'
							value={selectedCommand}
							onChange={handleCommandChange}
							labelId='command-label'
							label='command'>
							<MenuItem key='1' value='S'>
								Start
							</MenuItem>
							<MenuItem key='3' value='R'>
								Read
							</MenuItem>
							<MenuItem key='4' value='W'>
								Write
							</MenuItem>
							<MenuItem key='5' value='D'>
								Delay
							</MenuItem>
							<MenuItem key='6' value='P'>
								Stop
							</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				{!hideBytes && (
					<Grid item xs={8} md={4}>
						<TextField
							fullWidth
							variant='outlined'
							label={selectedCommand !== 'D' ? 'bytes' : 'ms'}
							type='number'
							disabled={Boolean(
								selectedCommand === 'S' || selectedCommand === 'P'
							)}
							error={bytesError}
							value={bytes}
							onChange={(e) => {
								if (bytesError) {
									setBytesError(false);
								}

								if (e.target.value > getMinMaxValues(selectedCommand).max) {
									setBytes(getMinMaxValues(selectedCommand).max);
								} else {
									setBytes(e.target.value);
								}
							}}
							inputProps={{
								min: getMinMaxValues(selectedCommand).min,
								max: getMinMaxValues(selectedCommand).max,
							}}
						/>
					</Grid>
				)}
				<Grid item xs={4} md={hideBytes ? 6 : 2} alignItems='center'>
					<FingotiButton
						fullWidth={true}
						light
						color='primary'
						disabled={disableAdd}
						onClick={handleAddCommand}>
						add
					</FingotiButton>
				</Grid>
				<Grid item xs={12}>
					<CommandsDisplay commands={commands} removeCommand={removeCommand} />
				</Grid>
				<Grid item xs={8} md={4}>
					<TextField
						fullWidth
						variant='outlined'
						label='address'
						type='number'
						value={address}
						className={classes.formControl}
						onChange={(e) => {
							if (e.target.value > 127) {
								setAddress('127');
							} else {
								setAddress(e.target.value);
							}
						}}
						inputProps={{
							min: 0,
							max: 127,
						}}
					/>
				</Grid>
				<Grid item xs={4} md={2} />
				<Grid item xs={12}>
					<TextField
						fullWidth
						variant='outlined'
						label='send'
						value={commandString}
						inputProps={{ className: clsx(classes.json, classes.send) }}
						onChange={(e) => setCommandString(e.target.value)}
						onBlur={createCommandsObject}
						error={Boolean(errors.length > 0)}
						helperText={errors.length > 0 ? errors[0] : ''}
						InputProps={{
							endAdornment: (
								<CopyAdornment
									disabled={Boolean(
										commandString === '[]' || commandString === ''
									)}
									onClick={() => copyData(commandString)}
								/>
							),
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						fullWidth
						variant='outlined'
						label='receive'
						disabled
						inputProps={{ className: clsx(classes.json, classes.recv) }}
						value={hasSent ? JSON.stringify(recv) : ''}
						placeholder='received data will appear here...'
						InputLabelProps={{ shrink: true }}
						InputProps={{
							endAdornment: (
								<CopyAdornment
									disabled={Boolean(!hasSent || recv.length === 0)}
									onClick={() => copyData(JSON.stringify(recv))}
								/>
							),
						}}
					/>
				</Grid>
			</Grid>
		</WidgetWrapper>
	);
};
