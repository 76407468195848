import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch, useParams, Link as RouterLink, useLocation } from 'react-router-dom';
import { FingotiLoading, FingotiNoPermission, FingotiTabs } from '@fingoti/components';

import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { WebhookTable } from './WebhookTable';
import { WebhookDetails } from './WebhookDetails';
import { WebhookCall } from './WebhookCall';
import { WebhookLogs } from './WebhookLogs';
import { useRoleCheck } from '../../utils/RoleCheck';
import { useProfileState } from '../../../context/ProfileContext';

const useStyles = makeStyles((theme) => ({

    root: {
        width: '100%',
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: '100%'
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '10%',
    },
    tab: {
        width: '100%',
    },
    tabTitle: {
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    iconSpacing: {
        marginRight: theme.spacing(2),
        fontSize: '3rem'
    },
    tabRoot: {
        minWidth: 'unset',
    },
    tabLabelRight: {
        alignItems: 'unset',
        textAlign: 'right',
        paddingRight: theme.spacing(1),
    },
    boxPadding: {
        padding: theme.spacing(6),
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
        }
    }

}));

const TabPanel = (props) => {

    const { passClass, children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div className={passClass}>
                    {children}
                </div>
            )}
        </div>
    );
}

const tabProps = (index) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const RouterTab = (props) => <Tab component={RouterLink} {...props} />

export const Webhooks = ({ activeMenu }) => {

    const classes = useStyles();
    const match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();
    const { whid } = useParams();
    const { roleCheck } = useRoleCheck();
    const [value, setValue] = useState(activeMenu);

    useEffect(() => {
        setValue(activeMenu);
    });

    const handleChange = (event, newValue) => {

        let isTwoPartPath = match.path.indexOf('/', 1);
        let baseRoute = "/webhooks";

        if (isTwoPartPath !== -1) {
            //Path is /foo/bar, need to get rid of the /bar
            baseRoute = match.path.slice(0, isTwoPartPath)
        }

        switch (newValue) {
            case 0:
                history.push(`${baseRoute}`);
                break;
            case 1:
                history.push(`${baseRoute}/logs`);
                break;
            default:
                console.log("not a valid value");
                break;
        }
    };

    return (
        value === null ?
            <FingotiLoading />
            :
            !roleCheck("webhook", 1) ?
            <div className={classes.root}>
                <FingotiTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="webhook settings tabs"
                >
                    <RouterTab to={'/webhooks'} classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }} label="Manage" {...tabProps(0)} />
                    <RouterTab to={'/webhooks/logs'} classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }} label="Logs" {...tabProps(1)} />
                </FingotiTabs>

                <TabPanel passClass={classes.boxPadding} className={classes.tab} value={value} index={0}>
                    {
                        whid ?
                            <WebhookDetails webhookId={whid} />
                            :
                            <WebhookTable />
                    }
                </TabPanel>

                <TabPanel passClass={classes.boxPadding} className={classes.tab} value={value} index={1}>
                    {
                        location.state ?
                            <WebhookCall />
                            :
                            <WebhookLogs />
                    }
                </TabPanel>

            </div>
            :
            <FingotiNoPermission />
    );

}