import React, { useEffect } from 'react';

import { WidgetGrid } from '../../WidgetGrid';
import { IPAddresses } from '../../shared/widgets/IPAddresses';
import { MACAddresses } from '../../shared/widgets/MACAddresses';
import { IPAddresseSet } from '../../shared/widgets/IPAddressSet';

const gridLayout = {
    xl: [
        { i: '1', x: 0, y: 0, w: 3, h: 7 },
        { i: '2', x: 3, y: 0, w: 3, h: 7 },
        { i: '3', x: 6, y: 0, w: 3, h: 12 },
    ],
    lg:  [
        { i: '1', x: 0, y: 0, w: 3, h: 7 },
        { i: '2', x: 3, y: 0, w: 3, h: 7 },
        { i: '3', x: 6, y: 0, w: 3, h: 12}
    ],
    md:  [
        { i: '1', x: 0, y: 0, w: 3, h: 7 },
        { i: '2', x: 3, y: 0, w: 3, h: 7 },
        { i: '3', x: 0, y: 7, w: 3, h: 12}
    ],
    sm:  [
        { i: '1', x: 0, y: 0, w: 4, h: 7 },
        { i: '2', x: 0, y: 7, w: 4, h: 7 },
        { i: '3', x: 6, y: 14, w: 4, h: 12}
    ]
}

export const PeblNetwork = ({ pebl }) => {

    useEffect(() => {}, [pebl])

    return (
        <WidgetGrid layouts={gridLayout}>
            <div key='1'>
                <IPAddresses networkIp={pebl.network.ip} />
            </div>
            <div key='2'>
                <MACAddresses networkMac={pebl.network.mac} />
            </div>
            <div key='3'>
                <IPAddresseSet device={pebl} />
            </div>
        </WidgetGrid>
    );

}