import React, { useRef, useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import {
	FingotiHeader,
	FingotiDelete,
	FingotiModal,
	FingotiButton,
	FingotiMarkdown,
	FingotiTable,
	URLGenerator,
} from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { ConfigAdd } from './ConfigAdd';
import { ConfigInfo } from './ConfigInfo';
import { ConfigEdit } from './ConfigEdit';

import { apiService } from '../../../services/api.service';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(6),
		width: '100%',
	},
	tableHead: {
		color: theme.palette.primary.main,
	},
	noItemsRow: {
		textAlign: 'center',
		height: theme.spacing(32),
		borderBottom: 'none',
	},
	statusIcon: {
		verticalAlign: 'middle',
	},
	actionIcons: {
		display: 'flex',
		alignItems: 'center',
	},
	actionIcon: {
		padding: 0,
	},
	pins: {
		'display': 'flex',
		'justifyContent': 'center',
		'alignItems': 'center',
		'& :not(:first-child)': {
			marginLeft: theme.spacing(1) / 2,
		},
	},
}));

export const Configs = () => {
	const theme = useTheme();
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [addOpen, setAddOpen] = useState(false);
	const [infoOpen, setInfoOpen] = useState(false);
	const [infoConfig, setInfoConfig] = useState({});
	const [editOpen, setEditOpen] = useState(false);
	const [editConfig, setEditConfig] = useState({});
	const tableRef = useRef();

	const breadcrumbs = [
		{ text: 'Settings', link: '/settings' },
		{ text: 'Device Presets' },
	];

	const actionButtons = () => {
		return (
			<FingotiButton light color='primary' onClick={() => setAddOpen(true)}>
				add preset
			</FingotiButton>
		);
	};

	const handleAddSuccess = () => {
		setAddOpen(false);
		tableRef.current.onQueryChange();
	};

	const handleInfoOpen = (config) => {
		setInfoConfig(config);
		setInfoOpen(true);
	};

	const handleEditOpen = (config) => {
		setEditConfig(config);
		setEditOpen(true);
	};

	const handleEditSuccess = () => {
		setEditOpen(false);
		tableRef.current.onQueryChange();
	};

	const deleteConfig = (configId) => {
		apiService
			.deleteData(`/organisation/presets/${configId}`)
			.then((result) => {
				enqueueSnackbar(result.message, { variant: 'success' });
				tableRef.current.onQueryChange();
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
			});
	};

	const getConfigs = useCallback(
		(query) => {
			return apiService
				.getData(URLGenerator(query, '/organisation/presets'))
				.then((data) => {
					return {
						data: data.presets,
						page: data.pageNumber - 1,
						totalCount: data.count,
					};
				})
				.catch((error) => {
					enqueueSnackbar(error, { variant: 'error' });
				});
		},
		[enqueueSnackbar]
	);

	const enabledRenderer = (rd) => {
		return rd.disabled ? (
			<BlockOutlinedIcon
				style={{ fill: theme.palette.error.main }}
				className={classes.statusIcon}
			/>
		) : (
			<CheckCircleOutlineIcon
				style={{ fill: theme.palette.success.main }}
				className={classes.statusIcon}
			/>
		);
	};

	return (
		<>
			<FingotiModal title='Create Preset' open={addOpen} setOpen={setAddOpen}>
				<ConfigAdd
					setOpen={setAddOpen}
					config={infoConfig}
					onSuccess={handleAddSuccess}
				/>
			</FingotiModal>
			<FingotiModal
				title='Preset Information'
				open={infoOpen}
				setOpen={setInfoOpen}>
				<ConfigInfo setOpen={setInfoOpen} config={infoConfig} />
			</FingotiModal>
			<FingotiModal title='Edit Preset' open={editOpen} setOpen={setEditOpen}>
				<ConfigEdit
					setOpen={setEditOpen}
					config={editConfig}
					onSuccess={handleEditSuccess}
				/>
			</FingotiModal>
			<FingotiHeader
				breadcrumbs={breadcrumbs}
				sectionIcon={SettingsOutlinedIcon}
				actionButtons={actionButtons()}
			/>
			<FingotiMarkdown path='/documentation/portal/settings/presets.md' />
			<FingotiTable
				data={getConfigs}
				tableRef={tableRef}
				columns={[
					{ title: 'id', field: 'id', hidden: true },
					{ title: 'preset number', field: 'presetNumber' },
					{ title: 'preset name', field: 'presetName' },
					{ title: 'enabled', render: enabledRenderer },
					{ title: 'ssid', field: 'ssid', filtering: false },
				]}
				actions={[
					(rowData) => ({
						icon: InfoOutlinedIcon,
						tooltip: 'preset information',
						onClick: () => handleInfoOpen(rowData),
					}),
					(rowData) => ({
						icon: EditOutlinedIcon,
						tooltip: 'edit preset',
						onClick: () => handleEditOpen(rowData),
					}),
					(rowData) => ({
						icon: () => (
							<FingotiDelete
								className={classes.actionIcon}
								tooltipText='delete preset'
								onClick={() => deleteConfig(rowData.id)}
							/>
						),
					}),
				]}
			/>
		</>
	);
};
