import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { WidgetWrapper } from '../../../WidgetWrapper';
import useDeviceErrorHandler from '../../../useDeviceErrorHandler';
import { RequestBuilder } from '../../../../../services/request.service';

const useStyles = makeStyles((theme) => ({
	i2cGrid: {
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	i2cGridRow: {
		display: 'flex',
		flexDirection: 'row',
	},
	i2c: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: theme.spacing(4),
		height: theme.spacing(4),
		borderRadius: theme.spacing(1) / 2,
		margin: theme.spacing(1) / 2,
		color: '#FFF',
	},
	link: {
		textDecoration: 'none',
	},
	broadcast: {
		backgroundColor: theme.palette.secondary.light,
	},
	device: {
		backgroundColor: theme.palette.success.main,
	},
	unused: {
		backgroundColor: theme.palette.greyThree.main,
	},
	reserved: {
		backgroundColor: theme.palette.greyFour.main,
	},
}));

const I2CDetectDisabled = ({ onClick }) => {
	const theme = useTheme();

	return (
		<Box
			display='flex'
			flexDirection='column'
			alignItems='center'
			justifyContent='center'
			width='100%'
			height='100%'>
			<InfoOutlinedIcon
				style={{ fontSize: 48, marginBottom: theme.spacing(2) }}
			/>
			<Typography varient='subtitle1'>
				Bus mode is not set to i2c,{' '}
				<Link
					style={{
						color: theme.palette.secondary.main,
						textDecoration: 'none',
					}}
					onClick={onClick}>
					click here
				</Link>{' '}
				to change that
			</Typography>
		</Box>
	);
};

export const PeblI2CDetect = ({ busMode, i2cDetect, pebl }) => {
	const classes = useStyles();
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
	const { enqueueSnackbar } = useSnackbar();
	const errorHandler = useDeviceErrorHandler();
	const [scanEnable, setScanEnable] = useState(pebl.i2c.setup.scan);
	const rb = new RequestBuilder(pebl.id);

	const handleSetBusMode = () => {
		rb.addRequest('deviceBus', 'W', { protocol: 1 });
		rb.send()
			.then(() => {
				enqueueSnackbar(`Bus mode set to I2C`, {
					variant: 'success',
				});
			})
			.catch((res) => errorHandler(res));
	};

	const handleScanEnableToggle = () => {
		rb.addRequest('i2cSetup', 'W', { scan: !scanEnable });
		rb.send()
			.then(() => {
				enqueueSnackbar(`I2C Scanning ${scanEnable ? 'enabled' : 'disabled'}`, {
					variant: 'success',
				});
			})
			.catch((res) => errorHandler(res));
	};

	const getGridSquare = (address) => {
		let base16Addr = address;
		if (i2cDetect.includes(address)) {
			return (
				<div className={clsx(classes.i2c, classes.device)} key={address}>
					{base16Addr}
				</div>
			);
		} else if (address < 3 || address > 119) {
			return (
				<div className={clsx(classes.i2c, classes.reserved)} key={address}>
					{base16Addr}
				</div>
			);
		} else {
			return (
				<div className={clsx(classes.i2c, classes.unused)} key={address}>
					{base16Addr}
				</div>
			);
		}
	};

	let numAddrPerRow = isDesktop ? 16 : 6;
	let numRows = Math.ceil(127 / numAddrPerRow);

	let i = -1;

	return (
		<WidgetWrapper title='Device Detection' displaySave={false}>
			{busMode !== 1 ? (
				<I2CDetectDisabled onClick={handleSetBusMode} />
			) : (
				<>
					<div className={classes.i2cGrid}>
						<Grid component='label' container alignItems='center' spacing={1}>
							<Grid item>disable</Grid>
							<Grid item>
								<Switch
									checked={scanEnable}
									onChange={() => {
										setScanEnable(!scanEnable);
										handleScanEnableToggle();
									}}
									color='primary'
								/>
							</Grid>
							<Grid item>enable</Grid>
						</Grid>
						{new Array(numRows).fill(null).map((r, ri) => {
							return (
								<div className={classes.i2cGridRow} key={`row-${ri}`}>
									{new Array(numAddrPerRow).fill(null).map(() => {
										i++;
										return getGridSquare(i);
									})}
								</div>
							);
						})}
					</div>
				</>
			)}
		</WidgetWrapper>
	);
};
