import React, { useEffect } from 'react';

import { WidgetGrid } from '../WidgetGrid';
import { TimerActions } from '../shared/widgets/TimerActions';
import { TimerInterval } from '../shared/widgets/TimerInterval';
import { TimerRequest } from '../shared/widgets/TimerRequest';

const gridLayout = {
    xl: [
        { i: '1', x: 0, y: 0, w: 3, h: 5 },
        { i: '2', x: 3, y: 0, w: 4, h: 10 },
        { i: '3', x: 0, y: 5, w: 3, h: 5 },
    ],
    lg:  [
        { i: '1', x: 0, y: 0, w: 3, h: 5 },
        { i: '2', x: 3, y: 0, w: 4, h: 10 },
        { i: '3', x: 0, y: 5, w: 3, h: 5 },
    ],
    md:  [
        { i: '1', x: 0, y: 0, w: 3, h: 5 },
        { i: '2', x: 3, y: 0, w: 3, h: 10 },
        { i: '3', x: 0, y: 5, w: 3, h: 5 },
    ],
    sm:  [
        { i: '1', x: 0, y: 0, w: 4, h: 5 },
        { i: '2', x: 0, y: 15, w: 4, h: 10 },
        { i: '3', x: 0, y: 5, w: 4, h: 5 },
    ],
}

export const GatewayTimer = ({ device }) => {

    useEffect(() => {}, [device])

    return (
        <WidgetGrid layouts={gridLayout}>
            <div key='1'>
                <TimerInterval device={device} />
            </div>
            <div key='2'>
                <TimerRequest device={device} />
            </div>
            <div key='3'>
                <TimerActions device={device} />
            </div>
        </WidgetGrid>
    );

}