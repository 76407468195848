import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import YoutubeSearchedForOutlinedIcon from '@material-ui/icons/YoutubeSearchedForOutlined';
import SignalWifi0BarOutlinedIcon from '@material-ui/icons/SignalWifi0BarOutlined';
import SignalWifi1BarOutlinedIcon from '@material-ui/icons/SignalWifi1BarOutlined';
import SignalWifi2BarOutlinedIcon from '@material-ui/icons/SignalWifi2BarOutlined';
import SignalWifi3BarOutlinedIcon from '@material-ui/icons/SignalWifi3BarOutlined';
import SignalWifi4BarOutlinedIcon from '@material-ui/icons/SignalWifi4BarOutlined';
import { CircularProgress, IconButton, Typography } from '@material-ui/core';

const useTableStyles = makeStyles(theme => ({

    root: {
        width: '100%',
        overflowY: 'auto'
    },
    tableHead: {
        color: theme.palette.primary.main,
        backgroundColor: '#FFF',
    },
    verticalTop: {
        verticalAlign: 'top',
    },
    noItems: {
        textAlign: 'center',
        borderBottom: 'none'
    }

}))

const useRowStyles = makeStyles(theme => ({

    row: {
        '&:hover': {
            cursor: 'pointer',
        }
    },
    iconCell: {
        textAlign: 'center',
    }

}))

const networkSignalIconProps = {
    color: "primary",
}

const NetworkTableRow = ({ network, key, selectedSSID, onClick }) => {

    const classes = useRowStyles();

    let icon;

    switch (true) {
        case (network.signal > 80):
            icon = <SignalWifi4BarOutlinedIcon {...networkSignalIconProps} />
            break;
        case (network.signal > 60):
            icon = <SignalWifi3BarOutlinedIcon {...networkSignalIconProps} />
            break;
        case (network.signal > 40):
            icon = <SignalWifi2BarOutlinedIcon {...networkSignalIconProps} />
            break;
        case (network.signal > 20):
            icon = <SignalWifi1BarOutlinedIcon {...networkSignalIconProps} />
            break;
        default:
            icon = <SignalWifi0BarOutlinedIcon {...networkSignalIconProps} />
            break;
    }

    return (
        <TableRow 
            hover 
            key={`network-${key}`}
            className={classes.row}
            onClick={() => onClick(network.ssid)}
            selected={network.ssid === selectedSSID}
        >
            <TableCell className={classes.iconCell}>{icon}</TableCell>
            <TableCell>{network.ssid}</TableCell>
        </TableRow>
    )

}

export const WifiCredentialChangeDetect = ({ detect, detecting, handleDetectNetworks, selectedSSID, setSSID }) => {

    const classes = useTableStyles();

    return (
        <TableContainer style={{ maxHeight: 180 }}>
            <Table size="small" className={classes.root}>
                <TableBody>
                    {
                        detect.length > 0 ?
                            detect.sort((a, b) => a.signal < b.signal)
                                .map((d, i) =>
                                    <NetworkTableRow
                                        network={d}
                                        key={i}
                                        selectedSSID={selectedSSID}
                                        onClick={setSSID}
                                    />)
                            :
                            <TableRow>
                                <TableCell className={classes.noItems} colSpan={2}>
                                    <IconButton onClick={detecting ? () => { } : handleDetectNetworks}>
                                        {
                                            detecting ?
                                                <CircularProgress size={30} />
                                                :
                                                <YoutubeSearchedForOutlinedIcon fontSize='large' />
                                        }
                                    </IconButton>
                                    <Typography>
                                        Click to see a list of networks your device can connect to
                                    </Typography>
                                </TableCell>
                            </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );

}