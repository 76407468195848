import React from 'react';
import { useCookies } from 'react-cookie';
import { useProfileState } from '../../context/ProfileContext';

//0 - No permission
//1 - Read only
//2 - Read/Write

export const useRoleCheck = () => {

    const { organisation, organisations, roles, loading } = useProfileState();
    const [cookies, setCookie] = useCookies();

    const _roleCheck = (realm, permission) => {

        if (!loading) {

            let findWhere;
            const currentOrganisation = organisations.find(org => org.id === organisation.id);

            if (currentOrganisation) {
                findWhere = currentOrganisation.roleId;
            } else if(cookies.Partner) {
                if(cookies.Partner === "2") {
                    findWhere = organisation.adminRole
                } else if(cookies.Partner === "1") {
                    findWhere = organisation.readRole
                }
            } else {
                return false;
            }

            const currentRole = roles.find(role => role.id === findWhere);

            if (currentRole[realm] >= permission) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    const RoleCheckWrapper = ({ children, realm, permission }) => {

        if (_roleCheck(realm, permission)) {
            return (
                <React.Fragment>
                    {children}
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment />
            );
        }

    };

    const roleCheck = (realm, permission) => {

        //Reversed boolean for button disabling
        if (_roleCheck(realm, permission)) {
            return false;
        } else {
            return true;
        }

    };

    return {
        roleCheck: roleCheck,
        RoleCheckWrapper: RoleCheckWrapper
    }

}