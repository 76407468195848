import React, { useState } from 'react';
import clsx from 'clsx';
import { FingotiModal } from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import SwapVertIcon from '@material-ui/icons/SwapVert';

import { RequestBuilder } from '../../../../../services/request.service';
import { WidgetWrapper } from '../../../WidgetWrapper';
import { WifiCredentialChange } from './WifiCredentialChange';
import useDeviceErrorHandler from '../../../useDeviceErrorHandler';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
	unset: {
		color: theme.palette.greyThree.main,
		fontStyle: 'italic',
	},
	ssid: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	active: {
		color: theme.palette.primary.main,
	},
}));

export const WifiCredentials = ({ device }) => {
	const errorHandler = useDeviceErrorHandler();
	const { enqueueSnackbar } = useSnackbar();
	const [editOpen, setEditOpen] = useState(false);
	const [editSlot, setEditSlot] = useState('');
	const classes = useStyles();
	const rb = new RequestBuilder(device.id);

	const handleEditOpen = (slot) => {
		setEditSlot(slot);
		setEditOpen(true);
	};

	const handleCredentialsClear = () => {
		rb.addRequest('wifiCredentials', 'W', { clear: true });

		rb.send()
			.then(() => {
				enqueueSnackbar('Credentials successfully cleared', {
					variant: 'success',
				});
			})
			.catch((res) => errorHandler(res));
	};

	const handleCredentialsSwap = () => {
		rb.addRequest('wifiCredentials', 'W', { swap: true });

		rb.send()
			.then(() => {
				enqueueSnackbar('Credentials successfully swapped', {
					variant: 'success',
				});
			})
			.catch((res) => errorHandler(res));
	};

	return (
		<>
			<FingotiModal
				title='Change Credentials'
				setOpen={setEditOpen}
				open={editOpen}>
				<WifiCredentialChange
					setOpen={setEditOpen}
					device={device}
					slot={editSlot}
				/>
			</FingotiModal>
			<WidgetWrapper
				title='Credentials'
				displaySave={false}
				icons={[
					<IconButton onClick={handleCredentialsSwap}>
						<SwapVertIcon />
					</IconButton>,
					<IconButton onClick={handleCredentialsClear}>
						<DeleteOutlinedIcon />
					</IconButton>,
				]}>
				<Grid container spacing={2} alignItems='center'>
					<Grid item xs={12}>
						<Typography>primary ssid</Typography>
					</Grid>
					<Grid item xs={10}>
						<Typography
							variant='h4'
							className={clsx(classes.ssid, {
								[classes.unset]: !device.wifi.credentials[0],
								[classes.active]: device.wifi.status.slot === 0,
							})}>
							{device.wifi.credentials[0]
								? device.wifi.credentials[0]
								: 'empty'}
						</Typography>
					</Grid>
					<Grid item xs={2}>
						<IconButton onClick={() => handleEditOpen(0)}>
							<EditOutlinedIcon />
						</IconButton>
					</Grid>
					<Grid item xs={12}>
						<Typography>secondary ssid</Typography>
					</Grid>
					<Grid item xs={10}>
						<Typography
							variant='h4'
							className={clsx(classes.ssid, {
								[classes.unset]: !device.wifi.credentials[1],
								[classes.active]: device.wifi.status.slot === 1,
							})}>
							{device.wifi.credentials[1]
								? device.wifi.credentials[1]
								: 'empty'}
						</Typography>
					</Grid>
					<Grid item xs={2}>
						<IconButton onClick={() => handleEditOpen(1)}>
							<EditOutlinedIcon />
						</IconButton>
					</Grid>
				</Grid>
			</WidgetWrapper>
		</>
	);
};
