import React, { useEffect } from 'react';

import { WidgetGrid } from '../../WidgetGrid';
import { VyneTemp } from './widgets/VyneTemp';
import { VyneHumidity } from './widgets/VyneHumidity';
import { VyneDataTimestamp } from './widgets/VyneDataTimestamp';
import { Typography } from '@material-ui/core';

const gridLayout = {
    xl: [
        { i: '1', x: 0, y: 0, w: 3, h: 6 },
        { i: '2', x: 3, y: 0, w: 3, h: 4 },
        { i: '3', x: 6, y: 0, w: 3, h: 4 }
    ],
    lg:  [
        { i: '1', x: 0, y: 0, w: 3, h: 6 },
        { i: '2', x: 3, y: 0, w: 3, h: 4 },
        { i: '3', x: 6, y: 0, w: 3, h: 4 }
    ],
    md:  [
        { i: '1', x: 0, y: 0, w: 3, h: 6 },
        { i: '2', x: 3, y: 0, w: 3, h: 4 },
        { i: '3', x: 6, y: 0, w: 3, h: 4 }
    ],
    sm:  [
        { i: '1', x: 0, y: 0, w: 4, h: 6 },
        { i: '2', x: 0, y: 6, w: 4, h: 4 },
        { i: '3', x: 0, y: 10, w: 4, h: 4 }
    ],
}

export const VyneDataTempHumid = ({ node }) => {

    useEffect(() => {}, [node])

    const values = node.type.getData(node.data.value);

    return (
        <>
        <WidgetGrid layouts={gridLayout}>
            <div key='1'>
                <VyneTemp data={values} />
            </div>
            <div key='2'>
                <VyneHumidity data={values} />
            </div>
            <div key='3'>
                <VyneDataTimestamp data={node.data.timestamp} />
            </div>
        </WidgetGrid>
        </>
    );

}