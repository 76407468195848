import React, { useEffect, useState } from 'react';
import { FingotiLoading } from '@fingoti/components';
import { MapContainer, TileLayer, Marker } from 'react-leaflet'

import useTheme from '@material-ui/core/styles/useTheme';

import { WidgetWrapper } from '../../WidgetWrapper';


export const Location = ({ device }) => {

    const theme = useTheme();
    const [thisLoading, setThisLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => setThisLoading(false), 2000);
    }, [])

    return (
        <WidgetWrapper title="Location" displaySave={false}>
            {
                thisLoading ?
                    <FingotiLoading fullHeight={false} />
                    :
                    <MapContainer
                        center={[device.device.location.latitude, device.device.location.longitude]}
                        zoom={5}
                        doubleClickZoom={false}
                        scrollWheelZoom={false}
                        attributionControl={false}
                        zoomControl={false}
                        style={{ height: '200px', borderRadius: '8px'}}
                    >
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker
                            position={[device.device.location.latitude, device.device.location.longitude]}
                            style={{
                                fill: device.device.cloud.connected ?
                                    theme.palette.success.main
                                    :
                                    theme.palette.greyFour.main
                            }}
                        >
                        </Marker>

                    </MapContainer>
            }
        </WidgetWrapper>
    );

}