import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// import MessengerCustomerChat from 'react-messenger-customer-chat';
import FingotiNav from '@fingoti/nav';

import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';

import { deviceHub } from '../services/signalr.service';
import { useProfileState } from '../context/ProfileContext';
import { ProfileData } from '../context/ProfileData';
import { apiService } from '../services/api.service';
import { authService } from '../services/auth.service';
//import { apiUrl, authUrl, cdnUrl, webUrl, portalUrl, accountUrl, storeUrl } from '../services/config';
import * as appConfig from '../services/config';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: '100%',
		padding: theme.spacing(1),
		margin: 0,
		[theme.breakpoints.up('md')]: {
			maxWidth: 'calc(100% - 114px);',
			marginLeft: 'auto',
			marginRight: 'auto',
			padding: theme.spacing(3),
		},
	},
	fbChat: {
		position: 'fixed',
		zIndex: 1200,
	},
}));

const Layout = ({ children }) => {
	const history = useHistory();
	const location = useLocation();
	const classes = useStyles();
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
	const profileData = ProfileData();
	const { enqueueSnackbar } = useSnackbar();

	const handleSideNavClick = (link) => {
		history.push(link);
	};

	const handleBackClick = () => {
		history.goBack();
	};

	const handleSwitchOrganisation = (orgId) => {
		localStorage.setItem('switchingOrganisation', true);
		deviceHub.invoke('RemoveFromOrganisation').then((res) => {
			console.log('res from remove from org', res);
			deviceHub.stop().then(() => {
				authService
					.getAppToken(orgId)
					.then(() => {
						//This causes issues needs to go
						// deviceHub.invoke("RemoveFromOrganisation").then(res => {
						//     console.log("res from remove from org", res);
						// })
						profileData.getData();
						//fix webhook showing after switch organisation
						history.replace(location.pathname, null);
						enqueueSnackbar('Successfully switched organisation', {
							variant: 'success',
						});
					})
					.catch(() => {
						enqueueSnackbar('Failed to swtich organisation', {
							variant: 'error',
						});
					});
			});
		});
	};

	const handleLogout = () => {
		window.location.assign(`${appConfig.accountUrl}/logout`);
	};

	return (
		<React.Fragment>
			<FingotiNav
				onSideNavClick={handleSideNavClick}
				onBackClick={handleBackClick}
				currentApp='portal'
				useProfileState={useProfileState}
				handleSwitchOrganisation={handleSwitchOrganisation}
				apiService={apiService}
				handleLogout={handleLogout}
				config={appConfig}
			/>
			<Container className={classes.root}>{children}</Container>
			{/* {isDesktop &&
                <MessengerCustomerChat
                    className={classes.fbChat}
                    pageId="215950192435406"
                    htmlRef={window.location.pathname}
                />
            } */}
		</React.Fragment>
	);
};

export default Layout;
