import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FingotiHeader, FingotiButton, FingotiModal, FingotiModalActions, FingotiModalContent } from '@fingoti/components';
import clsx from 'clsx';
import JSONPretty from 'react-json-pretty';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { WebhookIcon } from '../../../icons/FingotIcons';

import { datetimeService } from '../../../services/datetime.service';
import { useRoleCheck } from '../../utils/RoleCheck';
import { useWebhookFunctions } from './_webhookFunctions';

import './jsonColours.css';

const detailModalStyles = makeStyles((theme) => ({

    accordRoot: {
        '&::before': {
            backgroundColor: '#FFF'
        }
    },
    accord: {
        border: '1px solid' + theme.palette.greyFour.main,
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '50%',
        }
    },
    button: {
        width: '42%',
    },

}))

const detailTableStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    noBottomBorder: {
        border: 'none',
    },
    headerCell: {
        border: 'none',
        textAlign: 'right',
        borderRight: '1px solid ' + theme.palette.greyFour.main,
        width: '30%',
        color: theme.palette.greyTwo.main,
    },
    verticalTop: {
        verticalAlign: 'top',
    },
    enabledIcon: {
        verticalAlign: 'middle',
        fill: theme.palette.success.main,
    },
    disabledIcon: {
        verticalAlign: 'middle',
        fill: theme.palette.error.main,
    },
    headerValue: {
        whiteSpace: 'normal',
        wordBreak: 'break-word',
    },

}))

const standardTableStyles = makeStyles(theme => ({

    root: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        width: '100%',
    },
    tableHead: {
        color: theme.palette.primary.main,
    },
    verticalTop: {
        verticalAlign: 'top',
    },
    noItemsRow: {
        textAlign: 'center',
        height: theme.spacing(16),
        borderBottom: 'none',
    },
    actionIcons: {
        display: 'flex',
        alignItems: 'center',
    },
    actionIcon: {
        padding: 0,
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    allowWrap: {
        wordBreak: 'break-word',
        [theme.breakpoints.up('md')]: {
            wordBreak: 'normal',
        }
    }

}))

const bodyStyles = makeStyles(theme => ({

    root: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(3),
        borderTop: '1px solid' + theme.palette.greyFour.main,
        borderBottom: '1px solid' + theme.palette.greyFour.main,
        padding: theme.spacing(2),
    }

}))

const DetailModal = ({ setOpen, open, data }) => {

    const classes = detailModalStyles();
    const [expanded, setExpanded] = useState('p1');
    const [isJson, setIsJson] = useState(false);

    useEffect(() => {
        if (data) {
            let contentType = data.headers.filter(header => header.key === 'Content-Type')
            if (contentType[0] !== undefined) {
                if (contentType[0].value === "application/json") {
                    setIsJson(true)
                }
            }
        }
    }, [data])

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        data !== null ?
            <FingotiModal title="Response Details" open={open} setOpen={setOpen}>
                <FingotiModalContent>
                    <Accordion square elevation={0} classes={{ root: classes.accordRoot }} className={classes.accord} expanded={expanded === 'p1'} onChange={handleChange('p1')}>
                        <AccordionSummary className={classes.accordHead} expandIcon={<ExpandMoreIcon />}>
                            <Typography>Response Headers</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <HeaderTable headers={data.headers} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion square elevation={0} classes={{ root: classes.accordRoot }} className={classes.accord} expanded={expanded === 'p2'} onChange={handleChange('p2')}>
                        <AccordionSummary className={classes.accordHead} expandIcon={<ExpandMoreIcon />}>
                            <Typography>Response Body</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {
                                isJson ?
                                    <JSONBody border={false} body={data.body} />
                                    :
                                    data.body
                            }
                        </AccordionDetails>
                    </Accordion>
                </FingotiModalContent>
                <FingotiModalActions>
                    <div className={classes.buttonGroup}>
                        <FingotiButton
                            light
                            onClick={() => setOpen(false)}
                            className={classes.button}
                        >
                            close
                        </FingotiButton>
                    </div>
                </FingotiModalActions>
            </FingotiModal>
            :
            ""
    )

};

const CallInfo = ({ call }) => {

    const classes = detailTableStyles();
    const { statusEnum } = useWebhookFunctions();

    return (
        <TableContainer>
            <Table className={classes.root}>
                <TableBody>
                    <TableRow>
                        <TableCell className={classes.headerCell}>attempts</TableCell>
                        <TableCell className={classes.noBottomBorder}>{call.count}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.headerCell}>status</TableCell>
                        <TableCell className={classes.noBottomBorder}>{statusEnum(call.status)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={clsx(classes.headerCell, classes.verticalTop)}>url</TableCell>
                        <TableCell className={classes.noBottomBorder}>{call.requestUrl}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.headerCell}>called at</TableCell>
                        <TableCell className={classes.noBottomBorder}>{datetimeService.formatDateTime(new Date(call.timestamp))}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const HeaderTable = ({ headers }) => {

    const classes = standardTableStyles();

    return (
        <Table className={classes.root}>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.tableHead}>key</TableCell>
                    <TableCell className={classes.tableHead}>value</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    headers.map((header, i) => {
                        return (
                            <TableRow key={i}>
                                <TableCell className={classes.verticalTop}>{header.key}</TableCell>
                                <TableCell className={classes.allowWrap}>{header.value}</TableCell>
                            </TableRow>
                        )
                    })
                }
            </TableBody>
        </Table>

    );

}

const JSONBody = ({ body, border }) => {

    const classes = bodyStyles();

    return (
        border ?
            <div className={classes.root}>
                <JSONPretty data={body} />
            </div>
            :
            <JSONPretty data={body} />
    )

}

JSONBody.defaultProps = {
    border: true
}

const AttemptsTable = ({ attempts, handleViewDetails }) => {

    const classes = standardTableStyles();

    return (
        <TableContainer>
            <Table className={classes.root}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableHead}>attempt</TableCell>
                        <TableCell className={classes.tableHead}>timestamp</TableCell>
                        <TableCell style={{ minWidth: 150 }} className={classes.tableHead}>response code</TableCell>
                        <TableCell className={classes.tableHead}>actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        attempts.length === 0 ?
                            <TableRow key="no-attempt">
                                <TableCell colSpan={4} className={classes.noItemsRow}>
                                    This webhook hasn't been called yet
                                </TableCell>
                            </TableRow>
                            :
                            attempts.map(attempt => {

                                return (
                                    <TableRow key={attempt.attempt}>
                                        <TableCell>{attempt.attempt}</TableCell>
                                        <TableCell style={{ minWidth: 'max-content' }}>{datetimeService.formatDateTime(new Date(attempt.timestamp))}</TableCell>
                                        <TableCell>{attempt.responseCode} {attempt.responseDescription}</TableCell>
                                        <TableCell>
                                            <div className={classes.actionIcons}>
                                                <Tooltip title="response information">
                                                    <IconButton className={classes.actionIcon} onClick={() => handleViewDetails(attempt)}>
                                                        <InfoOutlinedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )
                            })

                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export const WebhookCall = () => {

    const location = useLocation();
    const call = location.state.call;
    const [viewDetials, setViewDetails] = useState(null);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const { roleCheck } = useRoleCheck();
    const { singleRetry } = useWebhookFunctions();

    const breadcrumbs = [
        { text: 'Webhooks', link: '/webhooks' },
        { text: 'Logs', link: '/webhooks/logs' },
        { text: location.state.webhookNumber, link: `/webhooks/${location.state.webhookId}` },
        { text: 'Call', link: '' }
    ]

    const handleViewDetails = (attempt) => {
        let data = { headers: attempt.responseHeaders, body: attempt.responseBody }
        setViewDetails(data);
        setDetailsOpen(true);
    }

    const actionButtons = () => {
        return (
            <React.Fragment>
                <FingotiButton light disabled={roleCheck("webhook", 2)} color="primary" onClick={() => singleRetry(location.state.call.id, location.state.webhookId)}>
                    retry call
                </FingotiButton>
            </React.Fragment>
        )
    }

    return (
        <>
            <DetailModal setOpen={setDetailsOpen} open={detailsOpen} data={viewDetials} />
            <FingotiHeader breadcrumbs={breadcrumbs} sectionIcon={WebhookIcon} actionButtons={actionButtons()} />
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Typography variant='h5'>Details</Typography>
                    <CallInfo call={call} />
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography variant='h5'>Headers</Typography>
                            <HeaderTable headers={call.requestHeaders} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h5'>Body</Typography>
                            <JSONBody body={call.requestBody} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h5'>Attempts</Typography>
                    <AttemptsTable attempts={call.attempts} handleViewDetails={handleViewDetails} />
                </Grid>
            </Grid>
        </>
    );

}