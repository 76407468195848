import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FingotiSwitchButton } from '@fingoti/components';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { WidgetWrapper } from '../../WidgetWrapper';
import { RequestBuilder } from '../../../../services/request.service';
import useDeviceErrorHandler from '../../useDeviceErrorHandler';

export const IPAddresseSet = ({ device }) => {
	const errorHandler = useDeviceErrorHandler();
	const { enqueueSnackbar } = useSnackbar();
	const [saveEnabled, setSaveEnabled] = useState(false);
	const [dhcp, setDhcp] = useState(device.network.ip.dhcp);
	const [local, setLocal] = useState(device.network.ip.local);
	const [mask, setMask] = useState(device.network.ip.mask);
	const [gateway, setGateway] = useState(device.network.ip.gateway);
	const [dns, setDns] = useState(device.network.ip.dns);
	const [errors, setErrors] = useState({});
	const regEx = new RegExp(
		'^((25[0-5]|(2[0-4]|1[0-9]|[1-9]|)[0-9])(.(?!$)|$)){4}$'
	);
	const rb = new RequestBuilder(device.id);

	useEffect(() => {
		if (!saveEnabled) {
			setDhcp(device.network.ip.dhcp);
			setLocal(device.network.ip.local);
			setMask(device.network.ip.mask);
			setGateway(device.network.ip.gateway);
			setDns(device.network.ip.dns);
		}
	}, [device]);

	const enableSave = () => {
		if (saveEnabled) {
			return;
		}

		if (Object.keys(errors).length === 0) {
			setSaveEnabled(true);
		}
	};

	const validateIp = (event) => {
		if (!regEx.test(event.target.value)) {
			setErrors((prevErrors) => ({
				...prevErrors,
				[event.target.name]: 'please enter a valid IPv4 address',
			}));
		} else {
			setErrors((prevErrors) => {
				delete prevErrors[event.target.name];
				return prevErrors;
			});
		}
	};

	const handleSave = () => {
		rb.addRequest('networkIp', 'W', {
			local: local,
			mask: mask,
			gateway: gateway,
			dns: dns,
			dhcp: dhcp,
		});
		rb.send()
			.then(() => {
				enqueueSnackbar('IP settings saved successfully', {
					variant: 'success',
				});
				setSaveEnabled(false);
			})
			.catch((res) => errorHandler(res));
	};

	return (
		<WidgetWrapper title='Setup' saveEnabled={saveEnabled} onSave={handleSave}>
			<FingotiSwitchButton
				leftText='Static'
				rightText='DHCP'
				value={dhcp}
				onClick={() => {
					setDhcp(!dhcp);
					enableSave();
				}}
			/>
			<TextField
				variant='outlined'
				value={local}
				name='ipAddress'
				label='ip address'
				onChange={(e) => {
					validateIp(e);
					setLocal(e.target.value);
					enableSave();
				}}
				error={Boolean(errors['ipAddress'])}
				helperText={errors['ipAddress'] ? errors['ipAddress'] : ''}
				placeholder='192.168.0.100'
				disabled={dhcp}
			/>
			<TextField
				variant='outlined'
				value={mask}
				name='subnetMask'
				label='subnet mask'
				onChange={(e) => {
					validateIp(e);
					setMask(e.target.value);
					enableSave();
				}}
				error={Boolean(errors['subnetMask'])}
				helperText={errors['subnetMask'] ? errors['subnetMask'] : ''}
				placeholder='255.255.255.0'
				disabled={dhcp}
			/>
			<TextField
				variant='outlined'
				value={gateway}
				label='gateway'
				name='gateway'
				onChange={(e) => {
					validateIp(e);
					setGateway(e.target.value);
					enableSave();
				}}
				error={Boolean(errors['gateway'])}
				helperText={errors['gateway'] ? errors['gateway'] : ''}
				placeholder='192.168.0.1'
				disabled={dhcp}
			/>
			<TextField
				variant='outlined'
				value={dns}
				label='dns'
				name='dns'
				onChange={(e) => {
					validateIp(e);
					setDns(e.target.value);
					enableSave();
				}}
				error={Boolean(errors['dns'])}
				helperText={errors['dns'] ? errors['dns'] : ''}
				placeholder='8.8.8.8'
				disabled={dhcp}
			/>
		</WidgetWrapper>
	);
};
