import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';

import { WidgetWrapper } from '../../../WidgetWrapper';

import { RequestBuilder } from '../../../../../services/request.service';
import useDeviceErrorHandler from '../../../useDeviceErrorHandler';

const CustomPopper = (props) => {
	return <Popper {...props} style={{ ...props.style, zIndex: 1400 }} />;
};

const speeds = ['100000', '400000', '1000000'];

export const PeblI2CSetup = ({ pebl }) => {
	const { enqueueSnackbar } = useSnackbar();
	const errorHandler = useDeviceErrorHandler();
	const [saveEnabled, setSaveEnabled] = useState(false);
	const [i2cSpeed, setI2CSpeed] = useState(pebl.i2c.setup.speed);
	const rb = new RequestBuilder(pebl.id);

	useEffect(() => {
		if (!saveEnabled) {
			setI2CSpeed(pebl.i2c.setup.speed);
		}
	}, [pebl]);

	const enableSave = () => {
		if (saveEnabled) {
			return;
		}

		setSaveEnabled(true);
	};

	const handleSectionSave = () => {
		rb.addRequest('i2cSetup', 'W', {
			speed:
				typeof i2cSpeed === 'string'
					? parseInt(i2cSpeed.replace(',', ''))
					: i2cSpeed,
		});
		rb.send()
			.then(() => {
				enqueueSnackbar(`i2c speed was set successfully`, {
					variant: 'success',
				});
			})
			.catch((res) => errorHandler(res));
		setSaveEnabled(false);
	};

	return (
		<WidgetWrapper
			title='Setup'
			saveEnabled={saveEnabled}
			onSave={handleSectionSave}>
			<FormControl fullWidth variant='outlined'>
				<Autocomplete
					freeSolo
					id='i2cSpeed'
					disableClearable
					value={i2cSpeed}
					options={speeds}
					getOptionLabel={(option) =>
						`${parseInt(option).toLocaleString('en-US')}`
					}
					onChange={(e, v) => {
						enableSave();
						setI2CSpeed(v);
					}}
					onInputChange={(e, v) => {
						if (e) {
							enableSave();
						}
						v !== '' && setI2CSpeed(parseInt(v.replaceAll(',', '')));
					}}
					renderInput={(params) => (
						<TextField variant='outlined' label='speed (hz)' {...params} />
					)}
					PopperComponent={CustomPopper}
				/>
			</FormControl>
		</WidgetWrapper>
	);
};
