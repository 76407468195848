import React from 'react';
import ReactDOM from 'react-dom';
import AppWrapper from './AppWrapper';
import registerServiceWorker from './registerServiceWorker';
const rootElement = document.getElementById('root');

let env = process.env.REACT_APP_ENVIROMENT_STRING;

console.info(`Running Fingoti Portal ${env} build`);

if(env === "PROD") {
    console.log = () => {}
    console.info = () => {}
    console.error = () => {}
} 

ReactDOM.render(<AppWrapper />, rootElement);

//registerServiceWorker();

