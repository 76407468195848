import React, { useState } from 'react';
import GaugeChart from 'react-gauge-chart';
import { useSnackbar } from 'notistack';

import RefreshIcon from '@material-ui/icons/Refresh';

import makeStyles from '@material-ui/core/styles/makeStyles';

import IconButton from '@material-ui/core/IconButton';

import { WidgetWrapper } from '../../../WidgetWrapper';
import { RequestBuilder } from '../../../../../services/request.service';
import useDeviceErrorHandler from '../../../useDeviceErrorHandler';

const useStyles = makeStyles(theme => ({

    wifiSignal: {
        display: 'flex',
        justifyContent: 'center'
    }

}))

export const WifiSignal = ({ signalLevel, device }) => {

    const errorHandler = useDeviceErrorHandler();
    const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
    const [refreshDisabled, setRefreshDisabled] = useState(false);
    const rb = new RequestBuilder(device.id);


    const handleRefresh = () => {
        setRefreshDisabled(true);
		rb.addRequest('wifiStatus', 'R');
		rb.send()
			.then(() => {
				enqueueSnackbar(`Refreshing wifi signal`, {
					variant: 'success',
				});
			})
			.catch((res) => errorHandler(res))
            .finally(() => setRefreshDisabled(false));
	};

	const icons = [
		<IconButton disabled={refreshDisabled} onClick={handleRefresh}>
			<RefreshIcon />
		</IconButton>,
	];

    return (
        <WidgetWrapper title="Signal" icons={icons} displaySave={false}>
            <div className={classes.wifiSignal}>
                <GaugeChart
                    style={{ width: 300 }}
                    marginInPercent={0.02}
                    id="wifiSignal"
                    nrOfLevels={10}
                    percent={signalLevel / 100}
                    colors={["#FF0000", "#00FF00"]}
                    needleBaseColor="#C0C0C0"
                    needleColor="#C0C0C0"
                    textColor="#404040"
                    animate={false}
                />
            </div>
        </WidgetWrapper>
    );

}