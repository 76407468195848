import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';

import { datetimeService } from '../../../../services/datetime.service';

const useTableStyles = makeStyles((theme) => ({

    root: {
        paddingLeft: theme.spacing(4)
    },
    noBottomBorder: {
        border: 'none',
    },
    headerCell: {
        border: 'none',
        textAlign: 'right',
        borderRight: '1px solid ' + theme.palette.greyFour.main,
        width: '40%',
        color: theme.palette.greyTwo.main,
    },
    labelTop: {
        verticalAlign: 'top',
    },
	deviceTitle: {
        textAlign: 'left',
        marginLeft: theme.spacing(2),
		paddingBottom: theme.spacing(4),
	},
    gatewayCell: {
        display: 'flex',
        alignItems: 'center',
    },
    linkIcon: {
        marginLeft: theme.spacing(1),
        '& > :hover': {
            cursor: 'pointer',
        }
    }

}))


export const VyneNodeDetails = ({ node }) => {

    const classes = useTableStyles();
    const history = useHistory();

    const values = node.type.getData(node.data.value);

    return (
		<div className={classes.root}>
			<div className={classes.deviceTitle}>
				<Typography variant='h5'>Device Overview - {node.name}</Typography>
			</div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell className={classes.headerCell}>
                            serial
                        </TableCell>
                        <TableCell className={classes.noBottomBorder}>
                            {node.id}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.headerCell}>
                            status
                            </TableCell>
                        <TableCell className={classes.noBottomBorder}>
                            {node.online ? 'Online' : 'Offline'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.headerCell}>
                            gateway
                        </TableCell>
                        <TableCell className={clsx(classes.noBottomBorder, classes.gatewayCell)}>
                            {node.gatewayId}
                            <LaunchOutlinedIcon
                                className={classes.linkIcon}
                                fontSize="small"
                                onClick={() => history.push(`/devices/gateways/${node.gatewayId}`)}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.headerCell}>
                            address
                        </TableCell>
                        <TableCell className={classes.noBottomBorder}>
                            {node.address.value} {node.address.static ? " - static" : " - dynamic"}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={clsx(classes.headerCell, classes.labelTop)}>
                            value
                        </TableCell>
                        <TableCell className={classes.noBottomBorder}>
                            {
                                node.enable ?
                                    values.map((data, i) => {
                                        return <React.Fragment key={`${i}`}>{data.value + data.unit} <br /> </React.Fragment>
                                    })
                                    :
                                    "--"
                            }
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.headerCell}>
                            last update
                        </TableCell>
                        <TableCell className={classes.noBottomBorder}>
                            {datetimeService.formatDateTime(new Date(node.lastUpdated))}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    )

}