import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FingotiActionButton } from '@fingoti/components';

import Grid from '@material-ui/core/Grid';

import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import EjectIcon from '@material-ui/icons/Eject';
import FlareOutlinedIcon from '@material-ui/icons/FlareOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

import { WidgetWrapper } from '../../WidgetWrapper';
import { apiService } from '../../../../services/api.service';
import { RequestBuilder } from '../../../../services/request.service';
import useDeviceErrorHandler from '../../useDeviceErrorHandler';


export const GatewayActions = ({ device }) => {

    const history = useHistory();
    const errorHandler = useDeviceErrorHandler();
    const [unclaimLoading, setUnclaimLoading] = useState(false);
    const [restartLoading, setRestartLoading] = useState(false);
    const [activityLoading, setActivityLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [showUpdateConfirm, setShowUpdateConfirm] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const rb = new RequestBuilder(device.id);
    let timeout;

    useEffect(() => {
        return () => {
            clearTimeout(timeout);
        }
    }, []);

    const handleUnclaim = () => {
        setUnclaimLoading(true);
        apiService.deleteData(`/device/${device.id}`)
            .then(result => {
                enqueueSnackbar(result.message, { variant: 'success' });
                history.goBack();
                setUnclaimLoading(false);
            })
            .catch(error => {
                enqueueSnackbar(error, { variant: 'error' });
                setUnclaimLoading(false);
            })
    }

    const handleRestart = () => {
        setRestartLoading(true);
        rb.addRequest("devicePower", "W", {
            restart: true
        })
        rb.sendSingleSection(["devicePower"])
            .then(() => {
                enqueueSnackbar("Device restarting", { variant: 'success' });
                setRestartLoading(false);
            })
            .catch((res) => errorHandler(res));
    }

    const handleActivityToggle = () => {
        setActivityLoading(true);
        rb.addRequest("deviceActivity", "W", {
            enabled: !device.device.activity
        })
        rb.sendSingleSection(["deviceActivity"])
            .then(() => {
                enqueueSnackbar(`Device activity blink ${device.device.activity ? "disabled" : "enabled"}`, { variant: 'success' });
                setActivityLoading(false);
            })
            .catch(errors => {
                if (errors && Array.isArray(errors)) {
                    errors.forEach(error => {
                        enqueueSnackbar(`Error ${error.result.code} - ${error.result.message}`, { variant: 'error' });
                    })
                } else {
                    enqueueSnackbar(errors, { variant: 'error' });
                }
                setActivityLoading(false);
            });
    }

    const handleUpdate = () => {
        setUpdateLoading(true)
        apiService.postData('/device/update', { devices: [device.id] })
            .then(result => {
                enqueueSnackbar(result.message, { variant: 'success' });
                setUpdateLoading(false);
                setShowUpdateConfirm(false);
            })
            .catch(error => {
                enqueueSnackbar(error, { variant: 'error' });
                setUpdateLoading(false);
                setShowUpdateConfirm(false);
            })
    }

    const handleConfirmUpdate = () => {
        setShowUpdateConfirm(true);
        timeout = setTimeout(() => setShowUpdateConfirm(false), 3000)
    }

    return (
        <WidgetWrapper title="Actions" displaySave={false}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FingotiActionButton
                        Icon={RotateLeftIcon}
                        label="Restart"
                        loading={restartLoading}
                        onClick={handleRestart}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FingotiActionButton
                        Icon={EjectIcon}
                        label="Unclaim"
                        loading={unclaimLoading}
                        onClick={handleUnclaim}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FingotiActionButton
                        Icon={FlareOutlinedIcon}
                        label="Activity"
                        active={device.device.activity}
                        loading={activityLoading}
                        onClick={handleActivityToggle}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FingotiActionButton
                        Icon={showUpdateConfirm ? ErrorOutlineOutlinedIcon : CloudDownloadOutlinedIcon}
                        label="Update"
                        active={device.systemVersion === device.device.version.software ? false : true}
                        disabled={Boolean((device.systemVersion === device.device.version.software ? true : false) || !device.device.cloud.connected)}
                        loading={updateLoading}
                        onClick={showUpdateConfirm ? handleUpdate : handleConfirmUpdate}
                    />
                </Grid>
            </Grid>
        </WidgetWrapper >
    );

}