import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const ResponsiveGridLayout = WidthProvider(Responsive);

export const WidgetGrid = ({ layouts, children }) => {

    return (
        <ResponsiveGridLayout
            style={{ margin: '-10px' }}
            className="layout"
            padding={[0, 0]}
            layouts={layouts}
            rowHeight={32}
            isResizable={false}
            isDraggable={false}
            breakpoints={{ xl: 1485, lg: 1039, md: 663, sm: 419, xs: 0 }}
            cols={{ xl: 12, lg: 9, md: 6, sm: 4, xs: 2 }}
        >
            { children }
        </ResponsiveGridLayout>
    );

}